import { createFileRoute, useNavigate } from "@tanstack/react-router";
import OnboardingHeader from "@/components/onboarding/OnboardingHeader.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { trpc } from "@/utils/trpc.ts";
import { useEffect } from "react";

type IntentType = "sign-in" | "sign-up";

type SearchParams = {
  intent?: IntentType;
};

export const Route = createFileRoute("/auth-success")({
  component: AuthSuccess,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const intent = search?.intent as IntentType;

    return {
      intent,
    };
  },
});

function AuthSuccess() {
  const { intent } = Route.useSearch();
  const navigate = useNavigate();

  const { data: authUser, isLoading } = trpc.me.useQuery(null);

  useEffect(() => {
    if (authUser) {
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("_wos_user", JSON.stringify(authUser));
      }

      setTimeout(() => {
        if (intent && intent === "sign-up") {
          navigate({
            to: "/checkout",
          });
        } else {
          navigate({
            to: "/feeds/templates",
            search: {
              orderFilter: "Recent",
              cacheBuster: Math.random(),
            },
          });
        }
      }, 500);
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={"bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen"}
    >
      <div>
        <OnboardingHeader />
      </div>
      <div
        className={
          "flex-1 flex flex-col justify-center items-center py-[7.75rem]"
        }
      ></div>
    </div>
  );
}
