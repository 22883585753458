import { InferSelectModel } from "drizzle-orm";
import { integer, pgTable, serial, timestamp, varchar } from "drizzle-orm/pg-core";

export const userSavedLandingPageTemplates = pgTable("user_saved_landing_page_templates", {
    id: serial("id").primaryKey().notNull(),
    userId: varchar("user_id", { length: 255 }).notNull(),
    landingPageTemplateId: integer("landing_page_template_id").notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
        .defaultNow()
        .notNull(),
});

export type SelectUserSavedLandingPageTemplate = InferSelectModel<typeof userSavedLandingPageTemplates>