import { FormEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import ChargebeeComponents from "@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup";
import {
  countries,
  getCurrencySymbol,
  PlanDetail,
  PRO_PLAN,
} from "@/utils/data/plans.ts";
import { useNavigate } from "@tanstack/react-router";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { TRPCError } from "@trpc/server";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form.tsx";
import { Input } from "@/components/shadcn/input.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import {
  CreditCard,
  DangerTriangleSolid,
  Lock,
  XCircle,
} from "@mynaui/icons-react";
import {
  CardComponent,
  CardCVV,
  CardExpiry,
  CardNumber,
} from "@chargebee/chargebee-js-react-wrapper";
import { Label } from "@/components/shadcn/label.tsx";
import { cn } from "@/lib/utils.ts";
import { cva } from "class-variance-authority";
import * as React from "react";
import { Circle } from "lucide-react";
import showToastNotification from "@/hooks/useShowToast.tsx";
import { billingAdrress } from "../../../server/types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/select.tsx";

export const nextDueDate = new Date(
  new Date().setDate(new Date().getDate() + 3),
).toLocaleDateString("en-US", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

export const firstChargeDate = new Date(
  new Date().setDate(new Date().getDate()),
).toLocaleDateString("en-US", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

export const capitalizeCardType = (str: string) =>
  str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());

export const inputStyles = cva(
  "mt-1 flex h-9 w-full rounded-md border border-input bg-transparent px-3 pt-2 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
);

export type AppliedPromoDetail = {
  id: string;
  name: string;
  discountType: string;
  discountPercentage?: number;
  status: string;
  period?: number;
  periodUnit?: string;
  discountAmount?: number;
  durationType: string;
  currencyCode?: string;
};

export const CheckoutUpgradeSubscription = ({
  selectedPlan,
  userHasPlan,
}: {
  userHasPlan: boolean;
  selectedPlan: PlanDetail;
}) => {
  const selectedPlanIsPro =
    selectedPlan && selectedPlan.plan_id === PRO_PLAN.plan_id;

  const cardRef = useRef<ChargebeeComponents | null>(null);

  const [errors, setErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [addPromoOpen, setAddPromoOpen] = useState<boolean>(false);

  const [addCardOpen, setAddCardOpen] = useState<boolean>(false);

  const [upgradeSuccessShow, setUpgradeSuccessShow] = useState<boolean>(false);

  const [appliedPromo, setAppliedPromo] = useState<
    AppliedPromoDetail | undefined
  >(undefined);

  const navigate = useNavigate();

  const checkoutFormSchema = z.object({
    chargebeeToken: z.string({ required_error: "Chargebee Token is required" }),
    coupon: z.string(),
    plan: z.string({ required_error: "Plan ID is required" }),
    billingAddress: billingAdrress,
  });

  type CheckoutFormValues = z.infer<typeof checkoutFormSchema>;

  const defaultValues: Partial<CheckoutFormValues> = {
    coupon: "",
  };

  const form = useForm<CheckoutFormValues>({
    resolver: zodResolver(checkoutFormSchema),
    defaultValues,
  });

  const upgradeUserPlan = async () => {
    try {
      const response = await upgradeUserNewPlan({ throwOnError: true });

      if (response && response.data?.success) {
        setUpgradeSuccessShow(true);
        showToastNotification("success", {
          message: "Your subscription has been upgraded successfully!",
        });
      } else {
        setIsSubmitting(false);
      }
    } catch (e) {
      setIsSubmitting(false);
      if (e instanceof Error) {
        if (e.message.includes("already present")) {
          toast.error("This account already exists.");
          navigate({ to: "/feeds/templates" });
        } else {
          toast.error(e.message); // Show the original error for other cases
        }
      } else {
        toast.error("An unknown error occurred");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Initiate upgrade subscription based on availability of card
  const subscribeToPlan = () => {
    if (userCardDetails) {
      upgradeUserPlan();
    } else if (cardRef.current) {
      cardRef.current
        .tokenize({})
        .then((data: { token: string }) => {
          form.setValue("chargebeeToken", data.token);
        })
        .then(() => {
          setTimeout(upgradeUserPlan, 500);
        })
        .catch((e: TRPCError) => {
          setIsSubmitting(false);
          toast.error(e.message);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);

    if (e) e.preventDefault();

    if (form.getValues("coupon").length > 0 && !appliedPromo) {
      try {
        // We test the coupon first to confirm it works before procedding to create subscription
        return refetchCoupon({ throwOnError: true })
          .then(() => {
            subscribeToPlan();
          })
          .catch((e) => {
            if (e instanceof Error) {
              if (e.message === "Sorry, we couldn't find that resource")
                toast.error("This coupon code is invalid!");
              else toast.error(e.message);
            } else {
              toast.error("An unknown error occurred");
            }
          });
      } catch (e) {
        setIsSubmitting(false);
        if (e instanceof Error) {
          if (e.message === "Sorry, we couldn't find that resource")
            toast.error("This coupon code is invalid!");
          else toast.error(e.message);
        } else {
          toast.error("An unknown error occurred");
        }
      } finally {
        setIsSubmitting(false);
      }
    } else {
      subscribeToPlan();
    }
  };

  const applyPromo = async () => {
    try {
      const response = await refetchCoupon({ throwOnError: true });
      if (response && response.data) {
        setAddPromoOpen(false);
        setAppliedPromo(response.data);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "Sorry, we couldn't find that resource") {
          form.setValue("coupon", "");
          toast.error("This coupon code is invalid!");
        } else toast.error(e.message);
      } else {
        toast.error("An unknown error occurred");
      }
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    const updatedErrors = {
      ...errors,
      [name]: value === "" ? "Field is required" : null, // example validation
    };

    setErrors(updatedErrors);
  };

  const onReady = (event: SyntheticEvent) => {
    const element = event.currentTarget as HTMLElement;
    element.focus();
  };

  const token = form.watch("chargebeeToken");

  const queryToCall = userHasPlan
    ? trpc.upgradeUserWithActivePlan
    : trpc.upgradeUserWithNoPlan;

  const {
    isLoading,
    isRefetching,
    refetch: upgradeUserNewPlan,
  } = queryToCall.useQuery(
    {
      plans:
        selectedPlanIsPro && userHasPlan
          ? [PRO_PLAN.plan_id]
          : selectedPlan
            ? [selectedPlan.no_trial_plan_id]
            : [],
      coupons: form.getValues("coupon")
        ? ([form.getValues("coupon")].filter(Boolean) as string[])
        : undefined,
      chargebeeToken: token,
      billingAddress: form.getValues("billingAddress"),
    },
    { enabled: false },
  );

  const { data: standardToProProration } =
    trpc.getStandardToProProration.useQuery(undefined, {
      enabled: userHasPlan,
    });

  const {
    isLoading: isLoadingCoupon,
    isRefetching: isRefetchingCoupon,
    refetch: refetchCoupon,
  } = trpc.getCouponDetails.useQuery(
    {
      couponId: !form.getFieldState("coupon").invalid
        ? form.getValues("coupon")
        : "",
    },
    { enabled: false },
  );

  const { data: userCardDetails, isLoading: isLoadingUserCardDetails } =
    trpc.getUserCardDetails.useQuery(undefined);

  useEffect(() => {
    if (userCardDetails && !isLoadingUserCardDetails) {
      setAddCardOpen(false);
    }
  }, [userCardDetails]);

  if (isLoading || isRefetching || isLoadingUserCardDetails || isSubmitting) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Loader />
      </div>
    );
  }

  if (upgradeSuccessShow) {
    return <UpgradeSuccessModal />;
  }

  return (
    <Form {...form}>
      <form onSubmit={onSubmit} className={""}>
        <div className={"flex flex-col gap-6"}>
          <div className={"flex justify-between items-center gap-2"}>
            <h4
              className={
                "text-xl lg:text-2xl text-left text-themeforeground font-semibold"
              }
            >
              {selectedPlanIsPro
                ? "Upgrade To Creative Pro"
                : "Subscribe to Creative OS"}
            </h4>
          </div>
          <div>
            <div>
              <p className={"font-bold"}>Order Summary</p>
            </div>
            <div className={"flex flex-col"}>
              <div
                className={"flex flex-col gap-3 py-3 border-b border-dashed"}
              >
                <div>
                  <div className={"flex gap-3 justify-between text-sm"}>
                    <span className={"flex gap-1 items-center"}>
                      <img src={"/Icon.png"} alt={""} />
                      <span>Creative OS {selectedPlan.name}</span>
                    </span>
                    <span className={"font-semibold"}>
                      {selectedPlan.price} / month
                    </span>
                  </div>
                  <div
                    className={
                      "flex gap-3 justify-between text-xs font-light text-thememutedforeground"
                    }
                  >
                    <span>Monthly Subscription, cancel anytime.</span>
                  </div>
                </div>

                <div>
                  {addPromoOpen ? (
                    <div className={"flex gap-1.5"}>
                      <FormField
                        control={form.control}
                        name="coupon"
                        render={({ field }) => (
                          <FormItem className={"flex-1"}>
                            <FormControl>
                              <Input
                                type={"text"}
                                placeholder={"Add Promo Code"}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <Button
                        onClick={() => {
                          applyPromo();
                        }}
                        variant={"secondary"}
                        size={"sm"}
                        type={"button"}
                        disabled={
                          isLoadingCoupon ||
                          isRefetchingCoupon ||
                          form.watch("coupon") === ""
                        }
                      >
                        {isLoadingCoupon || isRefetchingCoupon
                          ? "Checking..."
                          : "Apply"}
                      </Button>
                    </div>
                  ) : appliedPromo ? (
                    <div>
                      <div className={"flex gap-3 justify-between text-sm"}>
                        <span className={"flex gap-1 items-center"}>
                          <img src={"/label.png"} alt={""} />
                          <span>{appliedPromo.name}</span>
                          <span
                            onClick={() => {
                              setAddPromoOpen(false);
                              setAppliedPromo(undefined);
                              form.setValue("coupon", "");
                            }}
                            className={
                              "text-thememutedforeground cursor-pointer"
                            }
                          >
                            <XCircle className={"w-4 h-4"} />
                          </span>
                        </span>
                        <span className={"font-semibold"}>
                          -
                          {appliedPromo.discountType === "fixed_amount" &&
                          appliedPromo.currencyCode
                            ? getCurrencySymbol(appliedPromo.currencyCode)
                            : ""}
                          {appliedPromo.discountAmount
                            ? `${(appliedPromo.discountAmount / 100).toFixed(2)}`
                            : `${appliedPromo.discountPercentage}%`}{" "}
                          {appliedPromo.durationType === "limited_period"
                            ? `/ ${appliedPromo.periodUnit}`
                            : appliedPromo.durationType}
                        </span>
                      </div>
                      <div
                        className={
                          "flex gap-3 justify-between text-xs font-light text-thememutedforeground capitalize"
                        }
                      >
                        <span>
                          {appliedPromo.discountType === "fixed_amount" &&
                          appliedPromo.currencyCode
                            ? getCurrencySymbol(appliedPromo.currencyCode)
                            : ""}
                          {appliedPromo.discountAmount
                            ? `${(appliedPromo.discountAmount / 100).toFixed(2)}`
                            : `${appliedPromo.discountPercentage}%`}
                          {" OFF"}{" "}
                          {appliedPromo?.periodUnit === "month"
                            ? "monthly"
                            : appliedPromo?.periodUnit === "year"
                              ? "yearly"
                              : ""}{" "}
                          {" Price "}
                          {appliedPromo.periodUnit
                            ? `for ${appliedPromo.period} ${appliedPromo.periodUnit}`
                            : appliedPromo.durationType}
                        </span>
                        <span>
                          {appliedPromo.durationType === "limited_period" &&
                            `For ${appliedPromo.period} ${appliedPromo.periodUnit}`}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <span
                      onClick={() => setAddPromoOpen(true)}
                      className={
                        "text-sm underline text-themedestructive font-medium cursor-pointer"
                      }
                    >
                      Add Promo Code
                    </span>
                  )}
                </div>

                {selectedPlanIsPro && standardToProProration ? (
                  <div className={"flex flex-col gap-4"}>
                    <div>
                      <div className={"flex justify-between gap-3"}>
                        <p className={"text-sm"}>Credits</p>
                        <p className={"text-sm font-semibold text-right"}>
                          -${standardToProProration.credits}
                        </p>
                      </div>
                    </div>
                    <div className={"flex justify-between gap-3"}>
                      <p className={"text-sm"}>Subtotal</p>
                      <p className={"text-sm font-semibold text-right"}>
                        ${standardToProProration.proratedProPlan}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={"flex justify-between gap-3"}>
                    <p className={"text-sm"}>First Charge Date</p>
                    <p className={"text-sm font-semibold text-right"}>
                      {firstChargeDate}
                    </p>
                  </div>
                )}
              </div>

              <div className={"flex justify-between gap-3 py-2"}>
                <p className={"text-sm"}>Due Today</p>
                <p className={"text-sm font-semibold text-right"}>
                  {standardToProProration &&
                  standardToProProration.proratedProPlan
                    ? `$${standardToProProration.proratedProPlan}`
                    : selectedPlan.price}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className={"flex flex-col gap-6"}>
              <div className={"flex justify-between gap-3 items-center"}>
                <p className={"font-bold"}>Payment Details</p>
                {addCardOpen ? (
                  <span
                    className={"underline font-medium text-sm cursor-pointer"}
                    onClick={() => setAddCardOpen(false)}
                  >
                    Cancel
                  </span>
                ) : (
                  <span
                    className={"underline font-medium text-sm cursor-pointer"}
                    onClick={() => setAddCardOpen(true)}
                  >
                    Add Card
                  </span>
                )}
              </div>
              {!addCardOpen && userCardDetails ? (
                <div>
                  <div
                    className={`flex items-center justify-between rounded-lg border-2 ${userCardDetails.status === "expired" ? "border-maroon-500 text-thememutedforeground" : "border-themedestructive"} p-4`}
                  >
                    <div className="flex items-center space-x-2">
                      {userCardDetails.status === "expired" ? (
                        <span>
                          <DangerTriangleSolid />
                        </span>
                      ) : (
                        <span
                          className={cn(
                            "flex items-center justify-center aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                            "border-themedestructive text-themedestructive",
                          )}
                        >
                          <Circle className="h-2.5 w-2.5 fill-current text-current" />
                        </span>
                      )}
                      <span className={"font-medium"}>
                        {userCardDetails.card_type &&
                          capitalizeCardType(userCardDetails.card_type)}{" "}
                        ending in {userCardDetails.last4}
                      </span>
                    </div>
                    <span>
                      <CreditCard />
                    </span>
                  </div>
                  <div>
                    {userCardDetails.status === "expired" && (
                      <span className={"text-xs font-light"}>
                        This card is expired. Please update this cards details
                        or add another
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {" "}
                  <CardComponent
                    className="field"
                    ref={cardRef}
                    // showTestCards={true}
                    onReady={onReady}
                  >
                    <div>
                      <Label>Card Number</Label>
                      <CardNumber
                        placeholder="4111 1111 1111 1111"
                        className={cn(inputStyles())}
                        onChange={onChange}
                      />
                    </div>
                    <div className={"grid lg:grid-cols-2 gap-3 mt-4"}>
                      <div>
                        <Label>Expiration Date</Label>
                        <CardExpiry
                          placeholder="MM / YY"
                          className={cn(inputStyles())}
                          onChange={onChange}
                        />
                      </div>
                      <div>
                        <Label>CVV</Label>
                        <CardCVV
                          placeholder="CVV"
                          className={cn(inputStyles())}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </CardComponent>
                  <div>
                    <p className={"font-medium"}>Billing Details</p>
                  </div>
                  <div className={"flex flex-col gap-3"}>
                    <FormField
                      control={form.control}
                      name="billingAddress.country"
                      render={({ field }) => (
                        <FormItem>
                          <Label>Country</Label>
                          <FormControl>
                            <Select
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select country" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {countries &&
                                  countries.map((item) => (
                                    <SelectItem
                                      key={item.name}
                                      value={item.code}
                                    >
                                      {item.name}
                                    </SelectItem>
                                  ))}
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="billingAddress.line1"
                      render={({ field }) => (
                        <FormItem>
                          <Label>Address</Label>
                          <FormControl>
                            <Input placeholder="" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={"mt-6"}>
              <Button
                disabled={
                  isSubmitting ||
                  isLoading ||
                  isRefetching ||
                  (!addCardOpen && userCardDetails?.status === "expired")
                }
                type={"submit"}
                className="w-full"
              >
                {isSubmitting || isLoading || isRefetching
                  ? "Processing..."
                  : "Pay Now"}
              </Button>
              <div>
                <span
                  className={
                    "flex justify-center items-center gap-2 text-center mt-1.5 text-sm"
                  }
                >
                  <Lock className={"w-5"} />
                  <span>Secure Checkout by Chargebee</span>
                </span>
              </div>
            </div>
            {selectedPlanIsPro && standardToProProration && (
              <div className={"mt-6"}>
                <p className={"text-xs font-light text-thememutedforeground"}>
                  {`This month includes credits because you have an existing Creative OS membership. You will be charged $${standardToProProration.proratedProPlan} per month starting ${firstChargeDate}. You can cancel your subscription at any time.`}
                </p>
              </div>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

export function UpgradeSuccessModal() {
  const [enableClose, setEnableClose] = useState<boolean>(false);

  useEffect(() => {
    const enableCloseHandler = () => {
      setTimeout(() => {
        setEnableClose(true);
      }, 5000);
    };
    enableCloseHandler();
  }, []);

  return (
    <div>
      <div className={"flex flex-col items-center gap-2"}>
        <img alt={"Creative OS"} src={"/cos-logo.png"} className={"w-8 h-8"} />
        <h4
          className={"text-2xl text-center text-themeforeground font-semibold "}
        >
          You’re all set!
        </h4>
        <p className={"text-thememutedforeground text-lg text-center"}>
          We’ve received your payment, go start creating!
        </p>
      </div>
      <div className={"mt-6"}>
        <Button
          onClick={() => {
            window.location.href = "/feeds/templates";
          }}
          type={"button"}
          className="w-full"
          disabled={!enableClose}
        >
          {"Start Creating"}
        </Button>
      </div>
    </div>
  );
}
