"use client";

import { useTheme } from "next-themes";
import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      toastOptions={{
        classNames: {
          success:"group-[.toaster]:!text-colorgreen500 group-[.toaster]:text-sm group-[.toaster]:font-bold group-[.toaster]:border-1 group-[.toaster]:border-themeprimary ",
          error:"group-[.toaster]:!bg-colormaroon500 group-[.toaster]:!text-themebackground group-[.toaster]:text-sm group-[.toaster]:font-bold group-[.toaster]:border-1 group-[.toaster]:!border-colormaroon500 ",
          content:"group-[.toaster]:text-themebackground",
          toast:
            "group toast group-[.toaster]:bg-themeprimary group-[.toaster]:border-themeprimary group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted",
          actionButton:
            "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
