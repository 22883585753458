import { createFileRoute } from "@tanstack/react-router";
import { Ad } from "@/components/templates/DiscoverGridView";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { Stack, Text } from "@/components/custom-components";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/select";
import { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { AdCard, DiscoverAdCard } from "@/components/templates/AdCard";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions.tsx";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/saved/ads")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [selectValue, setSelectValue] = useState<
    "All Saved" | "Only Templates"
  >("All Saved");

  const {
    data: allSavedAds,
    isLoading,
    isError,
    isRefetching,
  } = trpc.getAllSavedAds.useQuery(undefined, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const {
    data: savedAdTemplates,
    isLoading: loadingAdTemplates,
    isError: adTemplatesError,
    isRefetching: refetchingAdTemplates,
  } = trpc.getAllSavedAdTemplates.useQuery(undefined, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { squareRef, desiredCardWidth, columns, gutterWidth, addPostToBatch } =
    useCardLayoutWithImpressions();

  if (isError || adTemplatesError) {
    return (
      <div className={"px-0"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (
    !allSavedAds ||
    isLoading ||
    isRefetching ||
    loadingAdTemplates ||
    refetchingAdTemplates
  ) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-8">
      <Stack className="gap-3 lg:gap-8">
        <Text weight="semibold" size={"xxl"}>
          All Saved Ad Inspiration
        </Text>
        <div className="flex items-center gap-8">
          <FeatureTabs
            showPro={false}
            tabItems={[
              {
                name: "Ads",
                link: "/feeds/saved/ads",
              },
              {
                name: "Landers",
                link: "/feeds/saved/ad-landers",
              },
              {
                name: "Emails",
                link: "/feeds/saved/emails",
              },
            ]}
          />
          <Select
            value={selectValue}
            onValueChange={(value: "All Saved" | "Only Templates") =>
              setSelectValue(value)
            }
          >
            <SelectTrigger className="w-33 h-8 max-h-8">
              <SelectValue placeholder="Ad Type" className="rounded-md" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {["All Saved", "Only Templates"].map((item) => (
                  <SelectItem value={item} className="py-1.5">
                    {item}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          {/* )} */}
        </div>
      </Stack>

      {/* {tabValue == "Ads" ? ( */}
      <div className={""}>
        {allSavedAds && (
          <>
            {(selectValue == "All Saved"
              ? (allSavedAds ?? []).length == 0
              : (allSavedAds ?? []).filter((data) => data.adTemplate).length ==
                0) && (savedAdTemplates ?? []).length == 0 ? (
              <div className={"lg:h-96 flex justify-center items-center"}>
                <p>No ad added to this board</p>
              </div>
            ) : (
              <div ref={squareRef} className={"relative w-full pb-10"}>
                <div>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                  >
                    <Masonry gutter={gutterWidth / 16 + "rem"}>
                      {savedAdTemplates &&
                        savedAdTemplates.map((ad) => (
                          <AdCard
                            key={ad?.atID}
                            showTemplateButton
                            typeof=""
                            adData={{
                              Ad: { ...ad, Liked: true },
                              IsLocked: false,
                              IsPublic: false,
                              brandName: undefined,
                              shouldInvalidateCache: false,
                              DesiredWidth: desiredCardWidth,
                            }}
                          />
                        ))}
                      {(allSavedAds ?? []).map((ad) => {
                        return selectValue == "All Saved" && !ad.adTemplate ? (
                          <DiscoverAdCard
                            key={ad.id}
                            DesiredWidth={desiredCardWidth}
                            adData={{ ...ad, isSaved: true } as Ad}
                            IsPublic={false}
                            IsLocked={false}
                            onView={addPostToBatch}
                          />
                        ) : ad.adTemplate ? (
                          <AdCard
                            key={ad.adTemplate?.atID}
                            showTemplateButton
                            typeof=""
                            adData={{
                              Ad: { ...ad.adTemplate!, AdDbId: ad.id },
                              IsLocked: false,
                              IsPublic: false,
                              brandName: undefined,
                              shouldInvalidateCache: false,
                              DesiredWidth: desiredCardWidth,
                            }}
                          />
                        ) : (
                          <></>
                        );
                      })}
                    </Masonry>
                  </ResponsiveMasonry>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Stack>
  );
}
