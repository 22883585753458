import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { trpc } from "../utils/trpc.ts";
import { AdGridView } from "@/components/templates/AdGridView";
import { AccordionData, Stack, Text } from "@/components/custom-components";
import { useCallback, useEffect, useState } from "react";
import {
  AirTableAdRecord,
  orderFilter as OrderFilterType,
} from "../../../shared/airtableGet.ts";
import { Loader } from "@/components/custom-components/Loader";
import { ErrorDisplay } from "@/components/error.tsx";
import { useInView } from "react-intersection-observer";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { AdCard } from "@/components/templates/AdCard";
import { z } from "zod";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { TemplatesFilterPopover } from "@/components/custom-components/TemplateFilterPopover/index.tsx";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import FirstTimeWelcomeDialog from "@/components/onboarding/FirstTimeWelcomeDialog.tsx";
import { TrendingUp } from "@mynaui/icons-react";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions.tsx";

export const showTemplateRecentButton = (createdAtString: string) => {
  const createdAt = new Date(createdAtString).getTime();
  const now = Date.now();
  const daysDifference = Math.floor((now - createdAt) / (1000 * 60 * 60 * 24));
  return daysDifference <= 30;
};

export type SelectedTemplateFilters = {
  collections?: string[];
};

type SearchParams = {
  collections?: string;
  getStarted?: string;
  sideBarOpen?: boolean;
  orderFilter?: z.infer<typeof OrderFilterType>;
};

export type FilterOption = {
  title: string;
  counter: number;
  optionItems: { label: string; value: boolean }[];
};

export const Route = createFileRoute("/feeds/templates/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const collections = search?.collections as string | undefined;
    const getStarted = search?.getStarted as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      collections,
      getStarted,
      sideBarOpen,
      orderFilter,
    };
  },
});

function All() {
  const {
    collections: queryCollections,
    getStarted,
    orderFilter,
  } = Route.useSearch();
  const navigate = useNavigate();

  const [getStartedDialogOpen, setGetStartedDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (getStarted && getStarted === "ready") {
      setGetStartedDialogOpen(true);
    }
  }, []);

  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined,
  );
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [cursor, setCursor] = useState(1);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<string[]>(
    [],
  );

  const [selectedFilters, setSelectedFilters] =
    useState<SelectedTemplateFilters>({
      collections: queryCollections ? queryCollections.split(",") : undefined,
    });

  // Get the template collections to be passed to the filter
  const { data: templateCollections } = trpc.getAllCollections.useQuery(
    {},
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
  const { data: newlyAddedTemplateCount } =
    trpc.getNewlyAddedTemplatesCount.useQuery(
      { templatesType: "record", daysToCheck: 30 },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    );

  // Update the filters after fetching the templates
  useEffect(() => {
    if (templateCollections) {
      setFilterOptions([
        {
          title: "Collection",
          counter: 0,
          optionItems: templateCollections.map((i) => ({
            label: i.Title,
            value: false,
          })),
        },
      ]);
    }
  }, [templateCollections]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => {
      const updatedOptionItems = option.optionItems.map((item) => ({
        ...item,
        value:
          option.title === "Collection" &&
          selectedFilters.collections?.includes(item.label),
      }));

      return {
        ...option,
        optionItems: updatedOptionItems,
      };
    });

    setFilterOptions(updatedOptions as FilterOption[]);
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: { collections?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.collections)
        searchParams.set("collections", params.collections);

      navigate({
        to: "/feeds/templates",
        replace: true,
        search: (old) => {
          return { ...old, ...params, orderFilter };
        },
      });
    },
    [navigate],
  );

  const handleOptionsChange = useCallback(
    (options: AccordionData[]) => {
      const selectedCollections: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Collection")
              selectedCollections.push(item.label);
          }
        });
      });

      setSelectedFilters({
        collections:
          selectedCollections.length > 0 ? selectedCollections : undefined,
      });

      updateQueryString({
        collections:
          selectedCollections.length > 0
            ? selectedCollections.join(",")
            : undefined,
      });
    },
    [updateQueryString],
  );

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.collections)
      params.collections = selectedFilters.collections.join(",");
  }, [selectedFilters]);

  // check for if there is at least one option selected
  const isAnyFilterSelected = (filters: SelectedTemplateFilters) => {
    return filters.collections && filters.collections.length > 0;
  };

  // update cursor whenever selectedFilters change
  useEffect(() => {
    setCursor(1); // Reset cursor to 1 whenever filters change

    // Get the atID of the collection when selected in the filter
    if (
      selectedFilters.collections &&
      selectedFilters.collections.length > 0 &&
      templateCollections
    ) {
      const collectionIds: string[] = selectedFilters.collections
        .map((selectedTitle) => {
          const selectedCollection = templateCollections.find(
            (collection) => collection.Title === selectedTitle,
          );
          return selectedCollection?.atID?.toString() ?? null;
        })
        .filter((id): id is string => id !== null); // Filter out null values

      setSelectedCollectionIds(collectionIds);
    } else {
      setSelectedCollectionIds([]); // Clear if no collections are selected
    }
  }, [selectedFilters, templateCollections]);

  const {
    data: filteredTemplatesByCollections,
    fetchNextPage,
    isLoading,
    isError,
    isRefetching,
  } = trpc.filterTemplatesByCollections.useInfiniteQuery(
    {
      collectionIds: selectedCollectionIds,
      sortingOptions: orderFilter,
      Tags: undefined,
      limit: 20,
      Ready: true,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled:
        Boolean(isAnyFilterSelected(selectedFilters)) &&
        selectedCollectionIds.length > 0,
      initialCursor: cursor,
    },
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (
      inView &&
      filteredTemplatesByCollections &&
      allData &&
      allData.length &&
      !isLoading &&
      !isRefetching &&
      filteredTemplatesByCollections.pages[0].TotalRecords > allData.length
    ) {
      fetchNextPage();
    }
  }, [
    inView,
    filteredTemplatesByCollections,
    allData,
    fetchNextPage,
    isLoading,
    isRefetching,
  ]);

  useEffect(() => {
    if (!filteredTemplatesByCollections) return;

    setAllData(() => undefined);

    const records = [] as AirTableAdRecord[];
    for (const page of filteredTemplatesByCollections.pages) {
      records.push(...page.ATRecords);
    }
    setAllData(() =>
      records.filter(
        (item, index) =>
          index === records.findIndex((obj) => obj.atID === item.atID),
      ),
    );
  }, [filteredTemplatesByCollections]);

  const { squareRef, desiredCardWidth, columns, gutterWidth } =
    useCardLayoutWithImpressions();

  return (
    <>
      {getStartedDialogOpen && (
        <FirstTimeWelcomeDialog
          open={getStartedDialogOpen}
          onOpenChange={() => setGetStartedDialogOpen(false)}
        />
      )}
      <Stack className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <FeatureTabs
            tabItems={[
              {
                name: "Ads",
                link: "/feeds/templates",
              },
              {
                name: "Emails",
                link: "/feeds/templates/emails",
                isPremiumFeature: true,
              },
              {
                name: "Landers",
                link: "/feeds/templates/landing-pages",
                isPremiumFeature: true,
              },
            ]}
          />
          <Stack className="gap-3 lg:gap-6">
            <div className={"flex justify-between gap-5 items-center"}>
              <Text weight="semibold" size={"xxl"} className="w-fit">
                Ad Templates
              </Text>
              <div className={"flex gap-2 items-center flex-wrap justify-end"}>
                {newlyAddedTemplateCount && newlyAddedTemplateCount > 0 && (
                  <div className="flex gap-2 w-fit items-center">
                    <TrendingUp className="text-themedestructive" />
                    <Text
                      size={"sm"}
                      weight={"medium"}
                      className="text-thememutedforeground "
                    >
                      {newlyAddedTemplateCount} New{" "}
                      {newlyAddedTemplateCount == 1 ? "Template" : "Templates"}
                    </Text>
                  </div>
                )}
                <div className="lg:hidden">
                  <OrderFilterSelect
                    defaultFilter={orderFilter || "Random"}
                    options={["Random", "Recent", "Popular", "Oldest"]}
                  />
                </div>
              </div>
            </div>
            <div
              className={`flex ${filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center`}
            >
              <div>
                {filterOptions && filterOptions.length > 0 && (
                  <TemplatesFilterPopover
                    initialOptions={filterOptions}
                    onOptionsChange={handleOptionsChange}
                    placeholder={"Filter Ad Templates"}
                  />
                )}
              </div>

              <div className={"hidden lg:flex"}>
                <OrderFilterSelect
                  defaultFilter={orderFilter || "Random"}
                  options={["Random", "Recent", "Popular", "Oldest"]}
                />
              </div>
            </div>
          </Stack>
        </Stack>

        {isLoading ? (
          <div className="flex justify-center items-center w-full h-[70vh]">
            <Loader />
          </div>
        ) : isError ? (
          <ErrorDisplay />
        ) : filteredTemplatesByCollections ? (
          <div>
            {allData && allData.length === 0 ? (
              <div className="flex flex-col justify-center items-center">
                <p className="text-center w-4/5 lg:w-1/2 mb-5">
                  Looks like you've gone down a path with no inspiration...this
                  is your fault! All we do is win...but really, maybe try a
                  different configuration of filters - we got you!
                </p>
                <img src="/giphy.webp" width="480" height="270" alt="" />
              </div>
            ) : (
              <div ref={squareRef} className={"relative w-full lg:px-0 pb-10"}>
                {filteredTemplatesByCollections.pages[0].TotalRecords === 0 ? (
                  <div className={"lg:h-96 flex justify-center items-center"}>
                    <p>No templates added to this collection</p>
                  </div>
                ) : (
                  allData && (
                    <div>
                      <ResponsiveMasonry
                        columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                      >
                        <Masonry gutter={gutterWidth / 16 + "rem"}>
                          {allData.map((ad) => {
                            return (
                              <AdCard
                                key={ad.atID}
                                adData={{
                                  Ad: ad,
                                  IsLocked: false,
                                  IsPublic: false,
                                  brandName: undefined,
                                  shouldInvalidateCache: false,
                                  DesiredWidth: desiredCardWidth,
                                }}
                                showRecentButton={showTemplateRecentButton(
                                  ad.Created,
                                )}
                              />
                            );
                          })}
                        </Masonry>
                      </ResponsiveMasonry>
                    </div>
                  )
                )}
                <div className={"relative"}>
                  <div
                    className={
                      "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                    }
                    ref={scrollRef}
                  ></div>
                </div>{" "}
                <div className={"relative"}>
                  <div
                    className={
                      "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                    }
                    ref={scrollRef}
                  ></div>
                </div>
              </div>
            )}
          </div>
        ) : (
          !isAnyFilterSelected(selectedFilters) && (
            <AdGridView
              Filter={{
                Expert: undefined,
                Tags: undefined,
                loadAdsCreatedAfter: undefined,
                Ready: true,
                cursor: undefined,
                limit: 20,
                sortingOptions: orderFilter,
              }}
            />
          )
        )}
      </Stack>
    </>
  );
}
