type MediaItem = {
  url: string;
  isImage: boolean;
};

type CollectionCardProps = {
  imageUrls: Array<string | undefined | null>;
  videoUrls: Array<string | undefined | null>;
};

const CollectionCard = ({ imageUrls, videoUrls }: CollectionCardProps) => {
  
  const validMediaItems: MediaItem[] = [
    ...imageUrls
      .filter((url): url is string => url !== undefined && url !== null)
      .map((url) => ({ url, isImage: true })),
    ...videoUrls
      .filter((url): url is string => url !== undefined && url !== null)
      .map((url) => ({ url, isImage: false })),
  ];

  const renderMedia = (mediaItem: MediaItem, index: number) => {
    const { url, isImage } = mediaItem;

    if (isImage) {
      return (
        <img
          key={`image-${index}`}
          src={url}
          alt=""
          className="w-full h-full object-cover rounded bg-thememuted"
        />
      );
    } else {
      return (
        <video
          key={`video-${index}`}
          className="w-full h-full object-cover bg-thememuted"
          muted
          loop
          autoPlay
          playsInline
          src={url}
          onMouseEnter={(e) => {
            (e.currentTarget as HTMLVideoElement).pause();
          }}
          onMouseLeave={(e) => {
            (e.currentTarget as HTMLVideoElement).play();
          }}
        />
      );
    }
  };

  return (
    <div
      className={
        "rounded-lg h-96 border-[0.25rem] border-thememuted bg-thememuted hover:bg-themeforeground hover:border-themeforeground"
      }
    >
      <div
        className={`grid grid-cols-[calc(60%)_calc(40%-5px)] grid-rows-[calc(50%-5px)_50%] gap-1 h-full`}
      >
        {validMediaItems.length > 0 && (
          <div className="row-span-2 rounded overflow-hidden">
            {renderMedia(validMediaItems[0], 0)}
          </div>
        )}
        {validMediaItems.length > 1 && (
          <div className="overflow-hidden rounded">
            {renderMedia(validMediaItems[1], 1)}
          </div>
        )}
        {validMediaItems.length > 2 && (
          <div className="overflow-hidden rounded">
            {renderMedia(validMediaItems[2], 2)}
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;
