import { FigmaLogo } from "@/assets/template-logo.tsx";
import { copyToClipboard } from "@/components/copyToClipboard.tsx";
import { AddToBoard } from "@/components/custom-components/AddToBoard";
import { AdHeader } from "@/components/custom-components/AdHeader";
import { DotsLoader } from "@/components/custom-components/Loader";
import { GenericCardProps } from "@/components/templates/LandingAdGridView";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { cn } from "@/lib/utils.ts";
import { trpc } from "@/utils/trpc.ts";
import { cva, VariantProps } from "class-variance-authority";
import { ComponentPropsWithoutRef, forwardRef, useState } from "react";
import showToastNotification from "@/hooks/useShowToast.tsx";
import { Download, Layout, Link } from "@mynaui/icons-react";
import { Button } from "@/components/shadcn/button";
import { TemplatesFooter } from "@/components/custom-components/TemplatesHeader";
import TemplateDetails from "@/components/TemplateDetails";
import LanderAdInspirationDetails from "@/components/LanderAdInspirationDetails.tsx";
import FreeAccessPrompt from "@/components/FreeAccessPrompt.tsx";
import { handleDownloadImage } from "@/utils/templates.ts";
import { Badge } from "@/components/shadcn/badge";
import { BlurredOverlay } from "../AdCard";

const adCardStyles = cva("w-full relative rounded bg-[#F5F5F5]");

export interface LandingAdDataProps {
  Screenshot: string;
  ID: string;
  created: string;
  category?: string;
  isSaved?: boolean;
}

export type LandingFeedProps = {
  landerId: number;
  landerUrl: string;
  brandName: string | null;
  brandImage: string | null;
  desktopScreenshot: string | null;
  mobileScreenshot: string | null;
  brandId: string | null;
  isSaved?: boolean;
  createdAt?: string;
};

export interface AdCardProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adCardStyles> {
  adData: LandingAdDataProps;
  type: "email" | "landing-page";
  showTemplateButton?: boolean;
  showRecentButton?: boolean;
  IsLocked?: boolean;
}

export interface LandingAdCardProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adCardStyles> {
  adData: LandingFeedProps;
  IsLocked?: boolean;
  IsPublic?: boolean;
  IsRequested?: boolean;
}

export const LandingAdCard = forwardRef<HTMLDivElement, AdCardProps>(
  (
    {
      className,
      showTemplateButton = false,
      showRecentButton = false,
      IsLocked = false,
      adData,
      type,
      ...props
    },
    ref
  ) => {
    const [, copyToClipboard] = useCopyToClipboard();
    const [detailsShow, setDetailsShow] = useState<boolean>(false);
    const [showTemplate, setShowTemplate] = useState<boolean>(true);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);

    return (
      <div
        onMouseEnter={
          showTemplateButton || showRecentButton
            ? () => {
                setShowTemplate(false);
              }
            : () => {}
        }
        onMouseLeave={
          showTemplateButton || showRecentButton
            ? () => {
                setShowTemplate(true);
              }
            : () => {}
        }
        ref={ref}
        className={cn(adCardStyles({ className:`${className} ${IsLocked && "pointer-events-none"}` }))}
        {...props}
      >

        {detailsShow && adData && (
          <TemplateDetails
            landingData={adData}
            type={type}
            open={detailsShow}
            onCloseDrawer={() => setDetailsShow(false)}
          />
        )}

        <div className={"rounded "}>
          <ScrollArea className={"w-full h-[35.4375rem]"}>
            <img
              alt={
                type === "email"
                  ? "screenshot of a email template"
                  : "screenshot of a landing page template"
              }
              loading={"lazy"}
              className={"w-full rounded-md"}
              src={adData.Screenshot}
            />
          </ScrollArea>
        </div>
        <div
          className={
            "absolute bg-opacity-40 inset-x-0 inset-y-0 bg-slate-900 rounded flex justify-between opacity-0 hover:opacity-100 flex-col items-stretch transition-opacity duration-300 cursor-pointer"
          }
        >
          <div className="flex justify-between items-center p-3">
            <GetLandingAdTemplate type={type} Ad={adData} />
            <div className="flex gap-2">
              {adData && (
                <ManuallySaveLandingAdToBoard
                  open={saveOpen}
                  onOpenChange={setSaveOpen}
                  data={adData}
                  variant={"secondary"}
                  type={type}
                />
              )}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      variant={"secondary"}
                      size={"icon"}
                      onClick={() =>
                        handleDownloadImage({
                          imageUrl: adData.Screenshot,
                          fileName: "creative-os-template",
                        })
                      }
                    >
                      <Download className="w-5 h-5" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Download as image</p>
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      onClick={() => {
                        copyToClipboard(
                          `${window.location.origin}/share/templates/${location.pathname.includes("feeds/templates/landing-pages") ? "landing-pages" : "emails"}/${adData.ID}`
                        );
                        showToastNotification("success", {
                          message: "Copied!",
                        });
                      }}
                      size={"icon"}
                      variant={"secondary"}
                    >
                      <Link className="w-5 h-5 " />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Share a public link</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
          <div
            className="h-full w-full bg-slate-900 bg-opacity-0"
            onClick={() => setDetailsShow(true)}
          />
          <div className="flex justify-between p-3">
            <TemplatesFooter
              footerTitle={
                location.pathname.includes("feeds/templates/landing-pages")
                  ? "Landing Page Template"
                  : "Email Template"
              }
              footerType="Template"
              titleColor="text-popover"
            />
          </div>
        </div>
        {showTemplateButton && showTemplate && (
          <div
            className={
              "absolute inset-x-0 inset-y-0 rounded justify-between flex flex-col items-stretch transition-opacity duration-300"
            }
          >
            <div className="flex justify-between p-3">
              {showTemplateButton ? (
                <Badge variant={"destructivePurple"} className="gap-1">
                  <Layout className="w-4 h-4" />
                  Template
                </Badge>
              ) : showRecentButton ? (
                <Badge
                  variant={"destructivePurple"}
                  className="gap-1 bg-[#0F172A66]"
                >
                  <div className="w-[0.333rem] h-[0.333rem] rounded-full bg-green-400" />
                  Just Added
                </Badge>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        {IsLocked && <BlurredOverlay />}
      </div>
    );
  }
);

type templateProps = {
  Ad: GenericCardProps;
  IsPublic?: boolean;
  type: "email" | "landing-page";
  buttonVersion?: boolean;
};

export const GetLandingAdTemplate = (props: templateProps) => {
  const [accessPromptDialogOpen, setAccessPromptDialogOpen] =
    useState<boolean>(false);

  const { isLoading, refetch } = trpc.getFigmaCopyPasteCode.useQuery(
    {
      id: props.Ad.ID.toString(),
      type: props.type,
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleCopyClick = async () => {
    if (props.IsPublic) {
      setAccessPromptDialogOpen(true);
      return;
    }

    // Attempt to copy immediately after fetching
    const success = await copyToClipboard(refetch);
    if (success) {
      showToastNotification("success", {
        message: "Successfully copied template. CMD + V in Figma",
      });
    }
  };

  return (
    <>
      {accessPromptDialogOpen && (
        <FreeAccessPrompt
          open={accessPromptDialogOpen}
          onOpenChange={() => setAccessPromptDialogOpen(false)}
        />
      )}

      {props.buttonVersion ? (
        <div className="flex gap-3">
          <Button
            variant="secondary"
            className="w-full"
            onClick={handleCopyClick}
          >
            {isLoading ? (
              <div>
                <DotsLoader />
              </div>
            ) : (
              <div className="flex gap-2">
                <span>Copy to Figma</span> <FigmaLogo height="20" />
              </div>
            )}
          </Button>
        </div>
      ) : (
        <div className={"text-nowrap"}>
          <div className={"flex items-center gap-3"}>
            <div className={"flex gap-3 items-center"}>
              {isLoading ? (
                <div>
                  <DotsLoader />
                </div>
              ) : (
                <div
                  className="w-9 h-9 rounded-full bg-black flex items-center justify-center cursor-pointer"
                  onClick={handleCopyClick}
                >
                  <FigmaLogo />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const LandingFeedAdCard = forwardRef<HTMLDivElement, LandingAdCardProps>(
  ({ adData, IsPublic, IsRequested, IsLocked = false, ...props }, ref) => {
    const [detailsShow, setDetailsShow] = useState<boolean>(false);
    const [adRequested, setAdRequested] = useState<boolean>(false);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);

    const { mutateAsync: requestAdTemplate } =
      trpc.requestLanderTemplate.useMutation();

    const [, copyToClipboard] = useCopyToClipboard();

    return (
      <div
        ref={ref}
        className={`h-[41.25rem] cursor-pointer relative rounded-md bg-[#F5F5F5] break-inside-avoid-column
        ${IsLocked && "filter blur-md pointer-events-none"}`}
        {...props}
      >
        {detailsShow && (
          <LanderAdInspirationDetails
            open={detailsShow}
            onOpenChange={() => setDetailsShow(false)}
            data={adData}
            IsPublic={false}
          />
        )}
        <div className="h-[41.25rem] relative w-full">
          {adData.desktopScreenshot && (
            <img
              alt={"screenshot of a landing page"}
              loading={"lazy"}
              className={
                "h-[41.25rem] w-full rounded-md hidden lg:flex object-cover object-top"
              }
              src={adData.desktopScreenshot}
            />
          )}
          {adData.mobileScreenshot && (
            <img
              alt={"screenshot of a landing page"}
              loading={"lazy"}
              className={
                "h-[41.25rem] w-full rounded-md flex lg:hidden object-cover object-top"
              }
              src={adData.mobileScreenshot}
            />
          )}
          <div
            className={`rounded-md p-3 absolute top-0 left-0 w-full h-[41.25rem] flex flex-col justify-between bg-slate-900 bg-opacity-50 opacity-0 transition-opacity duration-300 hover:opacity-100`}
          >
            <div className={"flex justify-between gap-5"}>
              <div>
                {!IsRequested && (
                  <Button
                    onClick={() => {
                      setAdRequested(true);
                      requestAdTemplate({
                        landerID: adData.landerId,
                      }).then(() => {
                        showToastNotification("success", {
                          message: "Landing Template has been requested!",
                        });

                        setTimeout(() => {
                          setAdRequested(false);
                        }, 5000);
                      });
                    }}
                    variant={"secondary"}
                  >
                    {adRequested ? "Requested!" : "Request"}
                  </Button>
                )}
              </div>
              <div className={"flex gap-2"}>
                {!IsPublic && adData && (
                  <ManuallySaveFeedAdToBoard
                    open={saveOpen}
                    onOpenChange={setSaveOpen}
                    data={adData}
                    variant={"secondary"}
                  />
                )}

                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Button
                        onClick={() => {
                          copyToClipboard(
                            `${window.location.origin}/share/inspiration/landing-pages/${adData.landerId}`
                          );
                          showToastNotification("success", {
                            message: "Copied!",
                          });
                        }}
                        variant={"secondary"}
                        size={"icon"}
                      >
                        <Link />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Share a public link</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
            <div
              className={"flex flex-col flex-1"}
              onClick={() => setDetailsShow(true)}
            ></div>
            <div className="text-white flex justify-between">
              {adData.brandId && (
                <AdHeader
                  brandName={adData.brandName}
                  brandId={adData.brandId}
                  Created={undefined}
                  avatar={adData.brandImage}
                  adRunningDays={undefined}
                  status={undefined}
                />
              )}
              <div>
                <span className={"text-xs text-nowrap text-themeinput"}>
                  Published{" "}
                  {adData.createdAt &&
                    new Date(adData.createdAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                </span>
              </div>
            </div>
          </div>
        </div>
        {IsLocked && <BlurredOverlay />}
      </div>
    );
  }
);

export function ManuallySaveFeedAdToBoard({
  open,
  onOpenChange,
  data,
  variant,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: LandingFeedProps;
  variant: "outline" | "secondary";
}) {
  return (
    <AddToBoard
      itemType="lander"
      landerData={{ landerId: data.landerId }}
      initialSavedState={data.isSaved || false}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}

export function ManuallySaveLandingAdToBoard({
  open,
  onOpenChange,
  data,
  variant,
  type,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: LandingAdDataProps;
  variant: "outline" | "secondary";
  type: "email" | "landing-page";
}) {
  return (
    <AddToBoard
      itemType={type == "email" ? "email-template" : "landing-page-template"}
      emailLanderTemplateData={data}
      initialSavedState={data.isSaved || false}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}
