export const INSPIRATION_TABS = [
  {
    name: "Ads",
    link: "/feeds/inspiration",
  },
  {
    name: "Landers",
    link: "/feeds/inspiration/landing-pages",
  },
];
