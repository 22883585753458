import { forwardRef } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils.ts";
import { AdPublishStatus } from "@/components/custom-components/AdPublishStatus";
import { Link } from "@tanstack/react-router";

const adHeaderStyles = cva("w-full");

export interface AdHeaderProps
  extends React.ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adHeaderStyles> {
  brandName?: string | null;
  brandId?: string;
  status?: string;
  Created?: string;
  avatar?: string | null;
  adRunningDays?: number;
}

export const AdHeader = forwardRef<HTMLDivElement, AdHeaderProps>(
  (
    {
      className,
      brandName,
      brandId,
      status,
      Created,
      avatar,
      adRunningDays,
      ...props
    },
    ref,
  ) => {
    return (
      <div ref={ref} className={cn(adHeaderStyles({ className }))} {...props}>
        <div className={`flex gap-1 items-center`}>
          {avatar && brandId && (
            <Link to={`/feeds/brands/$brandID`} params={{ brandID: brandId }}>
              <img
                src={avatar}
                alt={brandName || "Brand name"}
                className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
              ></img>
            </Link>
          )}
          <div>
            {brandName && brandId && (
              <Link to={`/feeds/brands/$brandID`} params={{ brandID: brandId }}>
                <h4 className={`truncate font-semibold text-sm`}>
                  {brandName}
                </h4>
              </Link>
            )}
            {Created && status && brandName && (
              <AdPublishStatus
                status={status}
                date={Created}
                adRunningDays={adRunningDays}
              />
            )}
          </div>
        </div>
      </div>
    );
  },
);
