import { z } from "zod";
import {
  boards,
  category,
  createOnboardingSchema,
} from "../database_data/drizzle/schema";
import {
  AirTableAdRecord,
  AirtableLandingPageRecord,
} from "../../shared/airtableGet";

export interface BoardDetails {
  brandImage: string | null;
  brandName: string | null;
  imageUrl: string | null;
  videoUrl: string | null;
  id: number | null;
  reach: number;
  brandId: string | null;
  isActiveSinceLastScrape: boolean;
  adTemplate?: AirTableAdRecord | null;
}

export interface AdFeed {
  id: number;
  reach: number | null;
  earliestView: string;
  latestLaunch: string;
  adRunningDays: number;
  imageUrl: string | null;
  videoUrl: string | null;
  // hasUserSeenAd: boolean;
  isSaved: boolean;
  isActiveSinceLastScrape: boolean;
  inactiveTime: number;
  template?: AirTableAdRecord | null;
}

export interface PreviewAd extends AdFeed {
  brandId: string | null;
  brandName: string | null;
  brandImage: string | null;
}

/**
 * Interface exposes all fields for ads
 */
export interface Ad extends PreviewAd {
  text: string | null;
  headline: string | null;
  description: string | null;
  publishedDate: string | null;
  landingPageUrl: string | null;
  rating: number;
  hasUserRequestedTemplate: boolean;
  numberOfRequests: number;
}

export interface PreviewAdWithDimensions extends PreviewAd {
  imageHeight: number | null;
  imageWidth: number | null;
}

export interface Template extends AirTableAdRecord {
  isTemplateInBoard: boolean;
}

export type PlatformFilter =
  | "facebook"
  | "instagram"
  | "messenger"
  | "audience_network";
export type FormatFilter = "image" | "video";
export type CategoryFilter = string;

export const timeLiveFilters = z.enum([
  "under_7_days",
  "over_7_days",
  "over_30_days",
  "over_3_months",
  "over_6_months",
  "over_12_months",
  "evergreen",
  "hidden_gems",
  "testing_lab",
]);
export const scoreFilters = z.enum([
  "winners",
  "great",
  "good",
  "average",
  "unknown",
]);

export const statusFilters = z.enum(["live", "not_live"]);

export type TimeLiveFilters = z.infer<typeof timeLiveFilters>;
export type ScoreFilters = z.infer<typeof scoreFilters>;
export type StatusFilters = z.infer<typeof statusFilters>;

export const filters = z.object({
  brands: z.array(z.string()).default([]),
  categories: z
    .array(z.string())
    .default([])
    .refine((items) => new Set(items).size === items.length, {
      message: "Must be an array with unique strings",
    }),
  platforms: z
    .array(z.enum(["facebook", "instagram", "messenger", "audience_network"]))
    .default([])
    .refine((items) => new Set(items).size === items.length, {
      message: "Must be an array with unique strings",
    }),
  formats: z
    .array(z.enum(["image", "video"]))
    .default([])
    .refine((items) => new Set(items).size === items.length, {
      message: "Must be an array with unique strings",
    }),
  timeLive: z
    .array(timeLiveFilters)
    .default([])
    .refine((items) => new Set(items).size === items.length, {
      message: "Must be an array with unique strings",
    }),
  scores: z
    .array(scoreFilters)
    .default([])
    .refine((items) => new Set(items).size === items.length, {
      message: "Must be an array with unique strings",
    }),
  statuses: z
    .array(statusFilters)
    .default([])
    .refine((items) => new Set(items).size === items.length, {
      message: "Must be an array with unique strings",
    }),
});
export const landersFilters = z.object({
  categories: z
    .array(z.string())
    .default([])
    .refine((items) => new Set(items).size === items.length, {
      message: "Must be an array with unique strings",
    }),
  sort: z.enum(["Random", "Newest", "Popular"]).optional(),
  brands: z
    .array(z.string())
    .default([])
    .refine((items) => new Set(items).size === items.length, {
      message: "Must be an array with unique strings",
    })
    .describe("List of brands to filter for"),
});
export type LandersFilters = z.infer<typeof landersFilters>;

export type Filters = z.infer<typeof filters>;
export type Category = typeof category.$inferSelect;
export type Board = Omit<typeof boards.$inferSelect, "userId" | "createdAt">;

export const OnboardingDto = createOnboardingSchema.omit({
  id: true,
  hasCompleted: true,
  userId: true,
  createdAt: true,
});

export type OnboardingParams = z.infer<typeof OnboardingDto>;

export const postType = z.enum(["record", "landing-page", "email"]);
export type PostType = z.infer<typeof postType>;

export const supportedBoardPostType = z.enum([
  "record",
  "inspiration-ad",
  "lander",
  "email-template",
  "landing-page-template",
]);
export type SupportedBoardPostType = z.infer<typeof supportedBoardPostType>;

export interface Landers {
  landerId: number;
  landerUrl: string;
  brandName: string | null;
  brandImage: string | null;
  desktopScreenshot: string | null;
  mobileScreenshot: string | null;
  brandId: string | null;
  isSaved: boolean;
  isReady?: boolean;
  template?: AirtableLandingPageRecord | null;
}

export interface LanderWithExtension extends Landers {
  extension: string;
}

export interface LandersFeed {
  results: Landers[];
  nextCursor: number;
}

export interface Token {
  name: "refreshToken" | "accessToken";
  value: string;
}

export interface Coupon {
  id: string;
  name: string;
  discountType: string;
  discountPercentage?: number;
  status: string;
  period?: number;
  periodUnit?: string;
  discountAmount?: number;
  durationType: string;
  currencyCode?: string;
}

export interface Card {
  status: string;
  iin: string;
  last4: string;
  card_type: "visa" | "mastercard" | "amex" | "discover" | string;
  funding_type: "credit" | "debit" | "prepaid" | "not_applicable" | string;
  expiry_month: number;
  expiry_year: number;
  powered_by: string;
  masked_number: string;
}
/**
 * chargebee payment source
 * documentation: https://apidocs.chargebee.com/docs/api/payment_sources#payment_source
 */
export interface PaymentSource {
  // payment source id
  id: string;
  // payment source updated at
  updated_at: number;

  // payment source resource version
  resource_version: number;

  // payment source deleted
  deleted: boolean;
  object: "payment_source";

  // chargebee customer id
  customer_id: string;

  // payment source type
  type: "card" | "bank_account" | string;

  // reference id
  reference_id: string;
  status: "valid" | "invalid" | "pending" | string;
  // oayment gateway
  gateway: string;

  // payment gateway account id
  gateway_account_id: string;
  created_at: number;

  // user card details
  card: Card;
}

export const billingAdrress = z
  .object({
    country: z
      .string()
      .optional()
      .describe(
        "country in ISO Code https://www.iso.org/iso-3166-country-codes.html"
      ),
    zip: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    line1: z.string().optional(),
    line2: z.string().optional(),
  })
  .describe("billing addreess");

export type BillingAddress = z.infer<typeof billingAdrress>;
