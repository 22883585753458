export const LockTagSmall = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10"
          cy="10"
          r="7.5"
          fill="#020617"
          stroke="#020617"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6702 9.12208V7.43758C11.6702 5.23655 8.32976 5.36156 8.32976 7.43758V9.12208M7.50173 9.12208H12.4982C12.9594 9.12208 13.3333 9.49917 13.3333 9.96433V12.4911C13.3333 12.9563 12.9594 13.3333 12.4982 13.3333H7.50173C7.04051 13.3333 6.66663 12.9563 6.66663 12.4911V9.96433C6.66663 9.49917 7.04051 9.12208 7.50173 9.12208Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export const LockTagLarge = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="16"
          cy="16"
          r="12"
          fill="#020617"
          stroke="#020617"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6722 14.5955V11.9003C18.6722 8.37863 13.3275 8.57864 13.3275 11.9003V14.5955M12.0027 14.5955H19.997C20.735 14.5955 21.3332 15.1988 21.3332 15.9431V19.9859C21.3332 20.7302 20.735 21.3335 19.997 21.3335H12.0027C11.2647 21.3335 10.6665 20.7302 10.6665 19.9859V15.9431C10.6665 15.1988 11.2647 14.5955 12.0027 14.5955Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
