import { createFileRoute } from "@tanstack/react-router";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { Stack, Text } from "@/components/custom-components";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/select";
import { useState } from "react";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs";
import {
  LandingAdCard,
  LandingFeedAdCard,
} from "@/components/templates/LandingAdCard";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/saved/ad-landers")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [selectValue, setSelectValue] = useState<
    "All Saved" | "Only Templates"
  >("All Saved");
  const {
    data: savedAdLanders,
    isLoading,
    isError,
    isRefetching,
  } = trpc.getAllSavedLanders.useQuery(undefined, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const {
    data: savedAdLanderTemplates,
    isLoading: loadingLanderTemplates,
    isError: landerTemplatesError,
    isRefetching: refetchingLanderTemplates,
  } = trpc.getAllSavedLandingPageTemplates.useQuery(undefined, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  if (isError || landerTemplatesError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (
    !savedAdLanders ||
    isLoading ||
    loadingLanderTemplates ||
    isRefetching ||
    refetchingLanderTemplates
  ) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-8">
      <Stack className="gap-3 lg:gap-8">
        <Text weight="semibold" size={"xxl"}>
          All Saved Ad Inspiration
        </Text>
        <div className="flex items-center gap-8">
          <FeatureTabs
            showPro={false}
            tabItems={[
              {
                name: "Ads",
                link: "/feeds/saved/ads",
              },
              {
                name: "Landers",
                link: "/feeds/saved/ad-landers",
              },
              {
                name: "Emails",
                link: "/feeds/saved/emails",
              },
            ]}
          />
          <Select
            value={selectValue}
            onValueChange={(value: "All Saved" | "Only Templates") =>
              setSelectValue(value)
            }
          >
            <SelectTrigger className="w-33 h-8 max-h-8">
              <SelectValue placeholder="Ad Type" className="rounded-md" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {["All Saved", "Only Templates"].map((item) => (
                  <SelectItem value={item} className="py-1.5">
                    {item}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          {/* )} */}
        </div>
      </Stack>

      <div className={""}>
        {
          (selectValue == "All Saved"
            ? (savedAdLanders ?? []).length == 0
            : (savedAdLanders ?? []).filter((data) => data.template).length ==
              0) && (savedAdLanderTemplates ?? []).length == 0 ? (
            <div className={"h-[80vh] flex justify-center items-center"}>
              <p className={"text-center"}>
                You do not have any ad lander inspiration saved
              </p>
            </div>
          ) : (
            <div
              className={
                "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2.5"
              }
            >
              {savedAdLanderTemplates &&
                savedAdLanderTemplates.map((card) => (
                  <LandingAdCard
                    key={card["LP ID"]}
                    adData={{
                      Screenshot: card["Landing Page Screenshot"],
                      ID: card["LP ID"],
                      created: card.Created,
                      category: card.Category,
                      isSaved: card.isSaved,
                    }}
                    showTemplateButton
                    type={"landing-page"}
                  />
                ))}
              {savedAdLanders &&
                savedAdLanders.map((card) =>
                  selectValue == "All Saved" && !card.template ? (
                    <LandingFeedAdCard key={card.landerId} adData={card} />
                  ) : card.template ? (
                    <LandingAdCard
                      key={card.template["LP ID"]}
                      adData={{
                        Screenshot: card.template["Landing Page Screenshot"],
                        ID: card.template["LP ID"],
                        created: card.template.Created,
                        category: card.template.Category,
                        isSaved: card.template.isSaved,
                      }}
                      showTemplateButton
                      type={"landing-page"}
                    />
                  ) : (
                    <></>
                  )
                )}
            </div>
          )
        }
      </div>
    </Stack>
  );
}
