export default function OnboardingHeader() {
  return (
    <div
      className={
        "bg-transparent px-5 pt-5 lg:px-10 lg:pt-10 lg:pb-5 flex gap-5"
      }
    >
      <div>
        <img
          alt={"Creative OS"}
          src={"/logo-full.png"}
          className={"max-w-[146px]"}
        />
      </div>
    </div>
  );
}
