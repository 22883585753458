import { forwardRef, useState, FormEvent, useEffect, Fragment } from "react";
import {  buttonVariants } from "@/components/shadcn/button.tsx";
import {
  BrandFacebook,
  CalendarUp,
  CircleDashed,
  Fire,
  Image,
  Store,
  BrandChrome,
  Trash,
  Check,
} from "@mynaui/icons-react";
import { cn } from "@/lib/utils.ts";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/shadcn/dropdown-menu.tsx";
import { ScrollArea, ScrollBar } from "@/components/shadcn/scroll-area";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/shadcn/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/shadcn/popover";

type OptionItem = {
  id?: string|number;
  label: string;
  value: boolean;
  logo?: string;
};

export type AccordionData = {
  title: string;
  counter: number;
  optionItems: OptionItem[];
};

type AdFilterProps = {
  className?: string;
  initialOptions: AccordionData[];
  onOptionsChange?: (options: AccordionData[]) => void;
};

export const AdFilter = forwardRef<HTMLButtonElement, AdFilterProps>(
  ({ initialOptions, onOptionsChange, ...props }, ref) => {
    const [options, setOptions] = useState<AccordionData[]>(initialOptions);

    useEffect(() => {
      if (onOptionsChange) onOptionsChange(options);
    }, [options, onOptionsChange]);

    const handleCheckboxChange = (
      accordionIndex: number,
      optionIndex: number,
      checked: boolean | FormEvent<HTMLButtonElement>
    ) => {
      let isChecked: boolean;

      if (typeof checked === "boolean") {
        isChecked = checked;
      } else {
        isChecked =
          checked.currentTarget.getAttribute("aria-checked") === "true";
        isChecked = !isChecked;
      }

      setOptions((prevOptions) =>
        prevOptions.map((group, index) => {
          if (index === accordionIndex) {
            const newOptionItems = group.optionItems.map((item, idx) => {
              if (idx === optionIndex) {
                return { ...item, value: isChecked };
              }
              return item;
            });
            return { ...group, optionItems: newOptionItems };
          }
          return group;
        })
      );
    };

    const handleClearPopoverFilters = (accordionIndex: number) => {
      setOptions((prevOptions) =>
        prevOptions.map((group, index) => {
          if (index === accordionIndex) {
            return {
              ...group,
              optionItems: group.optionItems.map((item) => ({
                ...item,
                value: false,
              })),
            };
          }
          return group;
        })
      );
    };

    // Check if any option in a group is checked
    const isAnyOptionChecked = (group: AccordionData): boolean => {
      return group.optionItems.some((item) => item.value === true);
    };

    // State to track which popover is open (null if none are open)
    const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(
      null
    );

    const handlePopoverToggle = (index: number) => {
      // Toggle popover: if the clicked popover is already open, close it
      setOpenPopoverIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    // Function to get the summary of selected filters
    const selectedFiltersSummary = options.reduce((summary, group) => {
      const selectedItems = group.optionItems.filter((item) => item.value);

      if (selectedItems.length > 0) {
        const formattedTitle = group.title
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

        const formattedValues = selectedItems
          .map((item) =>
            item.label
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
          )
          .join(", ");

        summary.push(`${formattedTitle} is ${formattedValues}`);
      }

      return summary;
    }, [] as string[]);

    // Join the summary items into a single string
    const filtersSummary =
      selectedFiltersSummary.length > 0 ? (
        <p className="text-sm mt-6">
          Showing results where{" "}
          {selectedFiltersSummary.map((filter, index) => (
            <Fragment key={index}>
              <strong>{filter}</strong>
              {index < selectedFiltersSummary.length - 2
                ? ", "
                : index === selectedFiltersSummary.length - 2
                  ? " and "
                  : ""}
            </Fragment>
          ))}
        </p>
      ) : null;

    return (
      <div className={"gap-3"} {...props}>
        <ScrollArea
          type="always"
          className="flex  w-[calc(100vw-24px)] lg:w-auto flex-1"
        >
          <div className={"flex gap-3 pr-3"} {...props}>
            {options &&
              options.length > 0 &&
              options
                .filter((opt) => opt.title.toLowerCase() !== "brand")
                .map((item, index) => {
                  const isChecked = isAnyOptionChecked(item);

                  return (
                    <div key={item.title}>
                      <DropdownMenu
                        open={openPopoverIndex === index}
                        onOpenChange={() => handlePopoverToggle(index)}
                      >
                        <DropdownMenuTrigger
                          ref={ref}
                          className={cn(
                            buttonVariants({
                              variant: isChecked
                                ? "secondary-destructive"
                                : "outline",
                              size: "sm",
                              className: "max-h-8",
                            })
                          )}
                        >
                          <div className={"flex items-center gap-1.5"}>
                            <span>
                              {item.title.toLowerCase() === "brand" ? (
                                <BrandChrome className={"w-5 h-5"} />
                              ) : item.title.toLowerCase() === "industry" ? (
                                <Store className={"w-5 h-5"} />
                              ) : item.title.toLowerCase() === "platform" ? (
                                <BrandFacebook className={"w-5 h-5"} />
                              ) : item.title.toLowerCase() === "time live" ? (
                                <CalendarUp className={"w-5 h-5"} />
                              ) : item.title.toLowerCase() === "status" ? (
                                <CircleDashed className={"w-5 h-5"} />
                              ) : item.title.toLowerCase() === "score" ? (
                                <Fire className={"w-5 h-5"} />
                              ) : item.title.toLowerCase() === "format" ? (
                                <Image className={"w-5 h-5"} />
                              ) : null}
                            </span>
                            <span>{item.title}</span>
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          align={"start"}
                          className={cn(
                            item.title.toLowerCase() === "industry"
                              ? "grid grid-cols-2 gap-2"
                              : ""
                          )}
                        >
                          <DropdownMenuLabel
                            className={
                              "flex gap-3 items-center justify-between"
                            }
                          >
                            <span className={"text-sm font-semibold"}>
                              {item.title}
                            </span>
                            <span
                              onClick={() => handleClearPopoverFilters(index)}
                            >
                              <Trash
                                className={"h-5 w-5 text-thememutedforeground"}
                              />
                            </span>
                          </DropdownMenuLabel>
                          <DropdownMenuSeparator />
                          {item.optionItems.map((option, idx) => (
                            <DropdownMenuCheckboxItem
                              id={option.label}
                              checked={option.value}
                              onCheckedChange={(checked) =>
                                handleCheckboxChange(index, idx, checked)
                              }
                              key={idx}
                            >
                              {option.label
                                .split("_")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </DropdownMenuCheckboxItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  );
                })}
            {options &&
              options.length > 0 &&
              options
                .filter((opt) => opt.title.toLowerCase() === "brand")
                .map((item) => {
                  const isChecked = isAnyOptionChecked(item);

                  return (
                    <div key={item.title}>
                      <Popover 
                      open={openPopoverIndex === options.indexOf(item)}
                      onOpenChange={() => handlePopoverToggle(options.indexOf(item))}
                      >
                        <PopoverTrigger asChild className={cn(
                            buttonVariants({
                              variant: isChecked
                                ? "secondary-destructive"
                                : "outline",
                              size: "sm",
                              className: "max-h-8 cursor-pointer",
                            })
                          )}>
                          <div className={"flex items-center gap-1.5"}>
                            <span>
                              {item.title.toLowerCase() === "brand" ? (
                                <BrandChrome className={"w-5 h-5"} />
                              )  : null}
                            </span>
                            <span>{item.title}</span>
                          </div>
                        </PopoverTrigger>
                        <PopoverContent className="w-[200px] p-0">
                          <Command>
                            <CommandInput
                              placeholder="Search Brands..."
                              className="h-9"
                            />
                            <CommandList>
                              <CommandEmpty>No brand found.</CommandEmpty>
                              <CommandGroup>
                                {item.optionItems.map((brand, idx) => (
                                  <CommandItem
                                    key={idx}
                                    value={brand.label}
                                    onSelect={() => {
                                      handleCheckboxChange(options.indexOf(item), idx, !brand.value);
                                    }}
                                  >
                                    <img
                                    src={brand.logo ?? "/bg_gradient.jpg"}
                                    className="min-w-6 w-6 h-6 rounded-full"
                                    />
                                    {brand.label}
                                    <Check
                                      className={cn(
                                        "ml-auto h-4 w-4",
                                        brand.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </div>
                  );
                })}
          </div>
          <ScrollBar orientation="horizontal" className="w-full hidden" />
        </ScrollArea>
        <div>{filtersSummary}</div>
      </div>
    );
  }
);

export default AdFilter;
