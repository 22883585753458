import { pgTable, varchar, text, timestamp } from "drizzle-orm/pg-core";
import { sql } from "drizzle-orm";

export const boards = pgTable("boards", {
    id: varchar("id", { length: 255 })
        .primaryKey()
        .notNull()
        .default(sql`nanoid()`),
    userId: varchar("user_id").notNull(),
    name: varchar("name", { length: 255 }).notNull(),
    description: text("description"),
    createdAt: timestamp("created_at", { mode: "string" }).defaultNow().notNull(),
});
