export default function OnboardingSteppers({
  stepIndex,
}: {
  stepIndex: number;
}) {
  return (
    <div className={"w-full flex justify-end py-[0.8125rem]"}>
      <div className={"flex gap-1"}>
        {[...Array(3)].map((_, index: number) => (
          <span
            key={index}
            className={`rounded-full ${index === stepIndex ? "bg-themedestructive" : "bg-themeinput"} w-8 h-1.5`}
          ></span>
        ))}
      </div>
    </div>
  );
}
