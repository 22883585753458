import { createFileRoute } from "@tanstack/react-router";
import { Ad, DiscoverGridView } from "@/components/templates/DiscoverGridView";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Stack, Text } from "@/components/custom-components";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import showToastNotification from "@/hooks/useShowToast";
import { Link as LinkIcon } from "@mynaui/icons-react";
import { Button } from "@/components/shadcn/button";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "@/components/shadcn/breadcrumb";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/evergreen/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [allData, setAllData] = useState<Ad[] | undefined>(undefined);
  const [, copyToClipboard] = useCopyToClipboard();

  const {
    data: evergreenAds,
    fetchNextPage,
    isLoading,
    isError,
    isRefetching,
  } = trpc.filterAdFeed.useInfiniteQuery(
    { timeLive: ["evergreen"] },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (!inView || !evergreenAds || !allData || !allData.length) return;
    if (evergreenAds.pages[evergreenAds.pages.length - 1].ads.length === 0) {
      return;
    }
    fetchNextPage();
  }, [inView, evergreenAds, allData, fetchNextPage]);

  useEffect(() => {
    if (!evergreenAds) return;

    setAllData(() => undefined);
    const records = [] as Ad[];
    for (const page of evergreenAds.pages) {
      records.push(...page.ads);
    }
    setAllData(() => records);
  }, [evergreenAds]);

  useEffect(() => {
    if (!isLoadingPermission && !permissionData?.userCanAccessEverything) {
      setUpgradeDialogOpen(true);
    }
  }, [permissionData?.userCanAccessEverything, isLoadingPermission]);

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }

  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-2">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href={"/feeds/live-trends"}>
                  Collections
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Live Trends</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <div className={"flex justify-between gap-5 items-center"}>
            <div className="flex gap-2 items-center">
              <img
                className={"rounded-full w-9 h-9 bg-themeaccent"}
                src={"/bg_gradient.jpg"}
                alt={"CreativeOs"}
              />
              <Text weight="semibold" size={"xxl"}>
                Evergreen Ads
              </Text>
            </div>

            <Button
              onClick={() => {
                copyToClipboard(`${window.location.origin}/feeds/evergreen`);
                showToastNotification("success", { message: "Copied!" });
              }}
              className={"flex"}
              variant={"outline"}
              size={"iconSm"}
            >
              <LinkIcon />
            </Button>
          </div>
          <Text
            weight="normal"
            size={"lg"}
            className="hidden lg:flex text-thememutedforeground"
          >
            {
              "Brands have been running these ads for over 90 days. These ads are curated using real-time data."
            }
          </Text>
        </Stack>
        {!allData || isLoading || isRefetching ? (
          <div className="flex justify-center items-center w-full h-screen">
            <Loader />
          </div>
        ) : (
          <div>
            {allData.length === 0 ? (
              <div className={"flex justify-center items-center"}>
                <p className={"text-center w-4/5 lg:w-1/2"}>
                  Oops! It looks like there are no ad inspirations matching your
                  filtered options right now. Please check back later for
                  updates.
                </p>
              </div>
            ) : (
              !isLoadingPermission &&
              permissionData?.userCanAccessEverything && (
                <DiscoverGridView data={allData} scrollRef={scrollRef} />
              )
            )}
          </div>
        )}
      </Stack>
    </>
  );
}

export default All;
