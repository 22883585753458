export default function BrowserFrame() {
  return (
    <div className="flex h-5 px-2 py-1.5 items-center gap-5 flex-shrink-0 self-stretch rounded-tl-lg rounded-tr-lg rounded-bl-none rounded-br-none bg-white">
      <div className={"flex items-center gap-1.5"}>
        {[...Array(3)].map((item) => (
          <svg
            key={item}
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            className="w-2 h-2 fill-slate-300"
          >
            <circle cx="4" cy="4" r="4" />
          </svg>
        ))}
      </div>
      <div className="h-1.5 flex-grow rounded-sm bg-slate-300"></div>
      <div className={"flex items-center gap-1.5"}>
        {[...Array(3)].map((item) => (
          <svg
            key={item}
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            className="w-2 h-2 fill-white"
          >
            <circle cx="4" cy="4" r="4" />
          </svg>
        ))}
      </div>
    </div>
  );
}
