import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/shadcn/button.tsx";

export default function FreeAccessPrompt({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-2xl">
            Want the copy-and-paste template for this ad?
          </DialogTitle>
          <DialogDescription className={"text-lg mt-4"}>
            Sign up for a free trial and get access to this ad and hundreds more
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
            <Button type="submit">Get Free Access</Button>
          </a>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
