import { Text } from "@/components/custom-components";
import { Box, Stack } from "@/components/custom-components/Layout";
import { Link, useNavigate, useRouterState } from "@tanstack/react-router";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/dialog.tsx";
import { Input } from "@/components/shadcn/input.tsx";
import { MouseEvent, useState } from "react";
import { trpc } from "@/utils/trpc.ts";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loading } from "@/components/loading.tsx";
import { CheckCircle2 } from "lucide-react";
import { ManageAccountV2 } from "@/components/templates/AppHeader";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import { Button, buttonVariants } from "@/components/shadcn/button";
import {
  PanelRightOpen,
  PanelLeftOpen,
  Layout,
  Target,
  Store,
  GridOne,
  Lightning,
  Star,
  MynaIconsProps,
  Plus,
  BrandSlack,
  QuestionCircle,
  CogThree,
  Tree,
} from "@mynaui/icons-react";
import { Badge } from "@/components/shadcn/badge";
import showToastNotification from "@/hooks/useShowToast";
import { cn } from "@/lib/utils";
import { PRO_PLAN } from "@/utils/data/plans";
import { LockTagSmall } from "@/components/custom-components/LockTag";
import { CustomTestTubeIcon } from "@/components/custom-components/TestTubeIcon";
import { CustomDiamondIcon } from "@/components/custom-components/DiamondIcon";
import { ScrollArea } from "@/components/shadcn/scroll-area";

type SidebarProps = {
  isPublicView: boolean;
  sidebarCollapsed: boolean | undefined;
};

const isPartialPathMatch = (
  pathname?: string,
  targetPath?: string,
  depth = 2
) => {
  /**
   * Extract and join segments from the current path and target path up to specified depth
   *
   * All this is being done so sub-links like /feeds/boards/$boardID/ads
   * and all other child pages can still be linked to the parent sub-link
   */
  const pathSegments = pathname
    ?.split("/")
    .filter(Boolean)
    .slice(0, depth)
    .join("/");
  const targetSegments = targetPath
    ?.split("/")
    .filter(Boolean)
    .slice(0, depth)
    .join("/");

  return pathSegments?.startsWith(targetSegments ?? "");
};

const StyledNavButton = (
  key: number,
  navItem: {
    link?: string;
    name: string;
    icon: (props: MynaIconsProps) => JSX.Element;
    exact?: boolean;
    subLinks?: Array<{
      name: string;
      link?: string;
      first2Links?: string;
      first3Links?: string;
      isAddCollection?: boolean;
      icon?: JSX.Element;
      openCreateNewBoard?: () => void;
    }>;
  },
  otherValues: {
    isPublicView: boolean;
    userPlans: string[];
    setPublicDialogOpen: (x: boolean) => void;
    setUpgradeDialogOpen: (x: boolean) => void;
    isLoading: boolean;
    userCanAccessEverything?: boolean;
    isMyCollections?: boolean;
    isSidebarExpanded?: boolean;
    smallerPadding?: boolean;
    isProFeature?: boolean;
  }
) => {
  const router = useRouterState();
  const {
    isLoading,
    isMyCollections = false,
    isPublicView,
    isSidebarExpanded = true,
    setPublicDialogOpen,
    setUpgradeDialogOpen,
    userCanAccessEverything,
    isProFeature,
    userPlans,
  } = otherValues;

  const isActiveLink = navItem.exact
    ? navItem.link && location.pathname === navItem.link
    : navItem.link && isPartialPathMatch(location.pathname, navItem.link, 2);

  const handleSublinkClick = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    if (isPublicView) {
      setPublicDialogOpen(true);
      e.preventDefault();
    }

    if (!isLoading && !userCanAccessEverything) {
      setUpgradeDialogOpen(true);
      e.preventDefault();
    }
  };

  return (
    <Stack key={key}>
      <Link
        to={navItem.link}
        search={(d) => ({
          ...d,
          cacheBuster: Math.random(),
          orderFilter: "Recent",
          sideBarOpen: false,
        })}
      >
        <Button
          variant={isActiveLink ? "default" : "ghost"}
          size={isSidebarExpanded ? "sm" : "iconSm"}
          className={isSidebarExpanded ? "w-full justify-start px-2" : ""}
          key={navItem.name}
        >
          {isSidebarExpanded ? (
            <navItem.icon className="w-5 h-5 min-w-5" />
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <navItem.icon className="w-5 h-5 min-w-5" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>{navItem.name}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          {isSidebarExpanded && (
            <div className="flex justify-between gap-3 items-center w-full">
              {navItem.name}
              {isProFeature &&
                !(
                  userPlans.includes(PRO_PLAN.plan_id) ||
                  userPlans.includes(PRO_PLAN.no_trial_plan_id)
                ) && (
                  <Badge className="font-medium size-sx hover:bg-primary">
                    Pro
                  </Badge>
                )}
            </div>
          )}
        </Button>
      </Link>
      {isMyCollections ? (
        navItem?.subLinks && (
          <Stack>
            {/* First item */}
            {navItem.subLinks.length >= 1 && (
              <Link
                key="first-link"
                onClick={
                  !isMyCollections
                    ? handleSublinkClick
                    : navItem.subLinks[0].openCreateNewBoard || undefined
                }
                to={navItem.subLinks[0].link}
                search={() => ({
                  sideBarOpen: false,
                })}
                className={
                  (
                    navItem.subLinks[0].first2Links || navItem.subLinks[0].first3Links
                      ? isPartialPathMatch(
                          location.pathname,
                          navItem.subLinks[0].first2Links ||
                            navItem.subLinks[0].first3Links,
                          navItem.subLinks[0].first2Links ? 2 : 3
                        )
                      : navItem.subLinks[0].link &&
                        router.location.pathname.startsWith(navItem.subLinks[0].link)
                  )
                    ? cn(
                        buttonVariants({
                          variant: "default",
                          size: "sm",
                          className: "!text-slate-50 gap-1.5 justify-between px-2",
                        })
                      )
                    : cn(
                        buttonVariants({
                          variant: "ghost",
                          size: "sm",
                          className: `text-slate-600 font-normal justify-between px-2 ${
                            !isLoading && !userCanAccessEverything && isProFeature
                              ? "opacity-50"
                              : ""
                          }`,
                        })
                      )
                }
                activeOptions={{
                  includeSearch: false,
                  exact: true,
                }}
              >
                <div className="flex items-center gap-1.5">
                  <div className={`w-5 h-5`}>
                    {navItem.subLinks[0].link &&
                      router?.location.pathname.startsWith(navItem.subLinks[0].link) &&
                      navItem.subLinks[0]?.icon}
                  </div>
                  <span className={`w-[calc(100vw-86px)] lg:w-[140px] truncate`}>
                    {navItem.subLinks[0].name}
                  </span>
                </div>
                {!isLoading && !userCanAccessEverything && isProFeature && (
                  <LockTagSmall />
                )}
                {navItem.subLinks[0].isAddCollection && (
                  <Plus className="w-5 h-5 hover:cursor-pointer" />
                )}
              </Link>
            )}
        
            {/* Middle items in scroll area */}
            {navItem.subLinks.length > 3 && (
              <ScrollArea className="min-h-18 max-h-[calc(100vh-45rem)] w-full">
                {navItem.subLinks.slice(1, -2).map((subItem, index) => (
                  <Link
                    key={`middle-link-${index}`}
                    onClick={
                      !isMyCollections
                        ? handleSublinkClick
                        : subItem.openCreateNewBoard || undefined
                    }
                    to={subItem.link}
                    search={() => ({
                      sideBarOpen: false,
                    })}
                    className={
                      (
                        subItem.first2Links || subItem.first3Links
                          ? isPartialPathMatch(
                              location.pathname,
                              subItem.first2Links || subItem.first3Links,
                              subItem.first2Links ? 2 : 3
                            )
                          : subItem.link &&
                            router.location.pathname.startsWith(subItem.link)
                      )
                        ? cn(
                            buttonVariants({
                              variant: "default",
                              size: "sm",
                              className: "!text-slate-50 gap-1.5 justify-between px-2  w-full",
                            })
                          )
                        : cn(
                            buttonVariants({
                              variant: "ghost",
                              size: "sm",
                              className: `text-slate-600 font-normal justify-between px-2  w-full${
                                !isLoading && !userCanAccessEverything && isProFeature
                                  ? "opacity-50"
                                  : ""
                              }`,
                            })
                          )
                    }
                    activeOptions={{
                      includeSearch: false,
                      exact: true,
                    }}
                  >
                    <div className="flex items-center gap-1.5">
                      <div className={`w-5 h-5`}>
                        {subItem.link &&
                          router?.location.pathname.startsWith(subItem.link) &&
                          subItem?.icon}
                      </div>
                      <span className={`w-[calc(100vw-86px)] lg:w-[140px] truncate`}>
                        {subItem.name}
                      </span>
                    </div>
                    {!isLoading && !userCanAccessEverything && isProFeature && (
                      <LockTagSmall />
                    )}
                    {subItem.isAddCollection && (
                      <Plus className="w-5 h-5 hover:cursor-pointer" />
                    )}
                  </Link>
                ))}
              </ScrollArea>
            )}
        
            {/* Last two items */}
            {navItem.subLinks.length >= 3 && navItem.subLinks.slice(-2).map((subItem, index) => (
              <Link
                key={`last-link-${index}`}
                onClick={
                  !isMyCollections
                    ? handleSublinkClick
                    : subItem.openCreateNewBoard || undefined
                }
                to={subItem.link}
                search={() => ({
                  sideBarOpen: false,
                })}
                className={
                  (
                    subItem.first2Links || subItem.first3Links
                      ? isPartialPathMatch(
                          location.pathname,
                          subItem.first2Links || subItem.first3Links,
                          subItem.first2Links ? 2 : 3
                        )
                      : subItem.link &&
                        router.location.pathname.startsWith(subItem.link)
                  )
                    ? cn(
                        buttonVariants({
                          variant: "default",
                          size: "sm",
                          className: "!text-slate-50 gap-1.5 justify-between px-2",
                        })
                      )
                    : cn(
                        buttonVariants({
                          variant: "ghost",
                          size: "sm",
                          className: `text-slate-600 font-normal justify-between px-2 ${
                            !isLoading && !userCanAccessEverything && isProFeature
                              ? "opacity-50"
                              : ""
                          }`,
                        })
                      )
                }
                activeOptions={{
                  includeSearch: false,
                  exact: true,
                }}
              >
                <div className="flex items-center gap-1.5">
                  <div className={`w-5 h-5`}>
                    {subItem.link &&
                      router?.location.pathname.startsWith(subItem.link) &&
                      subItem?.icon}
                  </div>
                  <span className={`w-[calc(100vw-86px)] lg:w-[140px] truncate`}>
                    {subItem.name}
                  </span>
                </div>
                {!isLoading && !userCanAccessEverything && isProFeature && (
                  <LockTagSmall />
                )}
                {subItem.isAddCollection && (
                  <Plus className="w-5 h-5 hover:cursor-pointer" />
                )}
              </Link>
            ))}
          </Stack>
        )
        
      ) : (
        <>
          {navItem?.subLinks && (
            <Stack>
              {navItem.subLinks.map((subItem, index) => (
                <Link
                  key={index}
                  onClick={
                    !isMyCollections
                      ? handleSublinkClick
                      : subItem.openCreateNewBoard || undefined
                  }
                  to={subItem.link}
                  search={() => ({
                    sideBarOpen: false,
                  })}
                  className={
                    (
                      subItem.first2Links || subItem.first3Links
                        ? isPartialPathMatch(
                            location.pathname,
                            subItem.first2Links || subItem.first3Links,
                            subItem.first2Links ? 2 : 3
                          )
                        : subItem.link &&
                          router.location.pathname.startsWith(subItem.link)
                    )
                      ? cn(
                          buttonVariants({
                            variant: "default",
                            size: "sm",
                            className:
                              "!text-slate-50 gap-1.5 justify-between px-2",
                          })
                        )
                      : cn(
                          buttonVariants({
                            variant: "ghost",
                            size: "sm",
                            className: `text-slate-600 font-normal justify-between px-2 ${!isLoading && !userCanAccessEverything && isProFeature ? "opacity-50" : ""}`,
                          })
                        )
                  }
                  activeOptions={{
                    includeSearch: false,
                    exact: true,
                  }}
                >
                  <div className="flex items-center gap-1.5">
                    {
                      <div className={`w-5 h-5`}>
                        {subItem.link &&
                          router?.location.pathname.startsWith(subItem.link) &&
                          subItem?.icon}
                      </div>
                    }
                    <span
                      className={`w-[calc(100vw-86px)] lg:w-[140px] truncate`}
                    >
                      {subItem.name}
                    </span>
                  </div>
                  {!isLoading && !userCanAccessEverything && isProFeature && (
                    <LockTagSmall />
                  )}
                  {subItem.isAddCollection && (
                    <Plus className="w-5 h-5 hover:cursor-pointer" />
                  )}
                </Link>
              ))}
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export const Sidebar = (props: SidebarProps) => {
  const [publicDialogOpen, setPublicDialogOpen] = useState<boolean>(false);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);

  const { data: permissions, isLoading } = trpc.permissions.useQuery(null, {});
  const { data: allBoardsData, isLoading: boardsIsLoading } =
    trpc.getBoards.useQuery(
      { postType: "record" },
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  const collapsedSideNavigationList: Array<{
    name: string;
    link: string;
    icon: (props: MynaIconsProps) => JSX.Element;
    exact?: boolean;
  }> = [
    {
      name: "Templates",
      link: "/feeds/templates",
      icon: Layout,
    },
    {
      name: "Inspiration",
      link: "/feeds/inspiration",
      icon: Target,
    },
    {
      name: "Brands",
      link: "/feeds/brands",
      icon: Store,
    },
    {
      name: "Curated Collections",
      link: !(permissions?.userCanAccessEverything ?? false)
        ? "/feeds/collections/creativeos"
        : "/feeds/collections",
      icon: GridOne,
    },
    {
      name: "Live Collections",
      icon: Lightning,
      link: "/feeds/live-trends",
    },
    {
      name: "My Collections",
      icon: Star,
      link: "/feeds/boards",
      exact: true,
    },
  ];
  const expandedSideNavigationList: Array<{
    name: string;
    link: string;
    icon: (props: MynaIconsProps) => JSX.Element;
  }> = [
    {
      name: "Templates",
      link: "/feeds/templates",
      icon: Layout,
    },
    {
      name: "Inspiration",
      link: "/feeds/inspiration",
      icon: Target,
    },
    {
      name: "Brands",
      link: "/feeds/brands",
      icon: Store,
    },
  ];
  const collectionsNavigationList: Array<{
    name: string;
    icon: (props: MynaIconsProps) => JSX.Element;
    link?: string;
    isProFeature?: boolean;
    exact?: boolean;
    isMyCollections?: boolean;
    subLinks?: Array<{
      name: string;
      link?: string;
      first2Links?: string;
      first3Links?: string;
      isAddCollection?: boolean;
      icon?: JSX.Element;
      openCreateNewBoard?: () => void;
    }>;
  }> = [
    {
      name: "Curated Collections",
      link: !(permissions?.userCanAccessEverything ?? false)
        ? "/feeds/collections/creativeos"
        : "/feeds/collections",
      icon: GridOne,
    },
    {
      name: "Live Collections",
      icon: Lightning,
      isProFeature: true,
      link: "/feeds/live-trends",
      subLinks: [
        {
          name: "Evergreen Ads",
          link: "/feeds/evergreen",
          icon: <Tree className="w-5 h-5" />,
        },
        {
          name: "Testing Lab",
          link: "/feeds/testing-labs",
          icon: <CustomTestTubeIcon width="20" height="20" color="#fff" />,
        },
        {
          name: "Hidden Gems",
          link: "/feeds/hidden-gems",
          icon: <CustomDiamondIcon width="20" height="20" color="#fff" />,
        },
      ],
    },
    {
      name: "My Collections",
      icon: Star,
      isMyCollections: true,
      link: "/feeds/boards",
      exact: true,
      subLinks: boardsIsLoading
        ? [{ name: "Loading boards..." }]
        : [
            {
              name: "All Saved",
              link: "/feeds/saved/ads",
              first2Links: "/feeds/saved",
            },
            ...(allBoardsData?.map((item: { id: string; name: string }) => ({
              name: item.name,
              link: `/feeds/boards/${item.id}/ads`,
              first3Links: `/feeds/boards/${item.id}`,
            })) || []),
            {
              name: "Requested Templates",
              link: "/feeds/inspiration/requested",
            },
            {
              name: "New Collection",
              isAddCollection: true,
              openCreateNewBoard: () => setCreateBoardDialogOpen(true),
            },
          ],
    },
  ];

  return (
    <>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
        />
      )}
      <Dialog open={publicDialogOpen} onOpenChange={setPublicDialogOpen}>
        <DialogContent className="sm:max-w-[500px] py-6">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Want the copy-and-paste templates for high-performing ads?
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Sign up for a free trial and get access
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Get Free Access</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {upgradeDialogOpen && permissions && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissions}
        />
      )}

      <Box
        className={`w-full ${props.sidebarCollapsed ? "lg:w-[3rem]" : "lg:w-56"} flex-1 h-[96svh] lg:h-screen flex flex-col bg-white mt-12 lg:mt-0`}
      >
        <div
          id={"new-sidebar"}
          className={
            "overflow-y-auto bg-themeprimaryforeground border-r border-t border-themeinput flex flex-col justify-between flex-1"
          }
        >
          {!props.sidebarCollapsed ? (
            <div
              className={`h-full flex flex-col justify-between gap-5 w-full bg-red`}
            >
              <Stack>
                <div className={"px-2 pt-4 pb-4 hidden lg:flex w-full"}>
                  <div className="flex justify-between items-center gap-1 px-1 w-full">
                    <ManageAccountV2
                      closeModal={(val: boolean) => setProfileModalOpen(val)}
                      showModal={profileModalOpen}
                    />
                    <Link
                      search={(old) => ({
                        ...old,
                        sideBarOpen: false,
                        sidebarCollapsed: true,
                      })}
                      replace={true}
                      params={(old) => ({
                        ...old,
                      })}
                    >
                      <PanelRightOpen className="text-thememutedforeground  w-4 h-4" />
                    </Link>
                  </div>
                </div>
                <div className={"flex flex-col p-2 gap-1"}>
                  {expandedSideNavigationList.slice(0, 3).map((item, index) =>
                    StyledNavButton(index, item, {
                      isPublicView: props.isPublicView,
                      setPublicDialogOpen,
                      setUpgradeDialogOpen,
                      isLoading,
                      userCanAccessEverything:
                        permissions?.userCanAccessEverything,
                      userPlans: permissions?.usersPlans ?? [],
                    })
                  )}
                </div>
                <hr className={"border-themeinput"} />
                <Stack className="p-2 gap-1">
                  <Text
                    weight="normal"
                    size="xs"
                    className="text-thememutedforeground px-3 py-1.5"
                  >
                    Collections
                  </Text>
                  {collectionsNavigationList.map((item, index) =>
                    StyledNavButton(index, item, {
                      isPublicView: props.isPublicView,
                      setPublicDialogOpen,
                      setUpgradeDialogOpen,
                      isLoading,
                      userCanAccessEverything:
                        permissions?.userCanAccessEverything,
                      isMyCollections: item.isMyCollections,
                      isSidebarExpanded: true,
                      smallerPadding: true,
                      isProFeature: item?.isProFeature,
                      userPlans: permissions?.usersPlans ?? [],
                    })
                  )}
                </Stack>
              </Stack>
              <Stack
                className={"px-2 pt-2 pb-8 gap-3 text-thememutedforeground"}
              >
                <div
                  className="flex gap-1.5 text-nowrap items-center cursor-pointer"
                  onClick={() => setProfileModalOpen(true)}
                >
                  <CogThree className="w-5 h-5 min-w-5" />

                  <Text weight={"medium"} size={"sm"}>
                    Settings
                  </Text>
                </div>
                <a
                  href="https://www.creativeos.com/faqs"
                  className="flex gap-1.5 text-nowrap items-center"
                >
                  <QuestionCircle className="w-5 h-5 min-w-5" />

                  <Text weight={"medium"} size={"sm"}>
                    Help & Feedback
                  </Text>
                </a>
                <a
                  href="https://join.slack.com/t/creativeos/shared_invite/zt-24n0h6fh0-JslQdJaA6HNVdeyG~0lJHw"
                  target="_blank"
                  className="flex gap-1.5 text-nowrap items-center"
                >
                  <BrandSlack className="w-4 h-4 min-w-5" />
                  <Text weight={"normal"} size={"xs"}>
                    Join Creative OS Slack!
                  </Text>
                </a>
              </Stack>
            </div>
          ) : (
            <div className={"h-full flex flex-col justify-between gap-5"}>
              <Stack>
                <div className={"px-2 pt-4 pb-4"}>
                  <Link
                    search={(old) => ({
                      ...old,
                      sideBarOpen: false,
                      sidebarCollapsed: false,
                    })}
                    replace={true}
                    params={(old) => ({
                      ...old,
                    })}
                    className={"hidden lg:flex justify-center"}
                  >
                    <PanelLeftOpen className="text-thememutedforeground w-4 h-4" />
                  </Link>
                </div>
                <hr className={"border-themeinput"} />

                <div className={"flex flex-col p-2 px-0 gap-1"}>
                  {collapsedSideNavigationList.map((item, index) => (
                    <Stack>
                      {index == collapsedSideNavigationList.length - 3 && (
                        <hr className={"border-themeinput my-2"} />
                      )}

                      <div className="px-2">
                        {StyledNavButton(index, item, {
                          isPublicView: props.isPublicView,
                          setPublicDialogOpen,
                          setUpgradeDialogOpen,
                          isLoading,
                          userCanAccessEverything:
                            permissions?.userCanAccessEverything,
                          isSidebarExpanded: false,
                          userPlans: permissions?.usersPlans ?? [],
                        })}
                      </div>
                    </Stack>
                  ))}
                </div>
              </Stack>

              <Stack
                className={"px-2 pt-2 pb-8 gap-3 text-thememutedforeground"}
              >
                <div
                  className="flex justify-center gap-1.5 cursor-pointer"
                  onClick={() => setProfileModalOpen(true)}
                >
                  <CogThree className="w-5 h-5 min-w-5" />
                </div>
                <a
                  href="https://www.creativeos.com/faqs"
                  className="flex justify-center gap-1.5 cursor-pointer"
                >
                  <QuestionCircle className="w-5 h-5 min-w-5" />
                </a>
              </Stack>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export type BoardProp = {
  description?: string | null;
  name: string;
  id: string;
  isInBoard?: boolean;
};

export const BoardDialog = ({
  data,
  open,
  onOpenChange,
  adId,
  inspirationLanderId,
  templateId,
  emailTemplateId,
  landerTemplateId,
}: {
  data?: BoardProp;
  open: boolean;
  onOpenChange: () => void;
  adId?: number;
  inspirationLanderId?: number;
  templateId?: string;
  emailTemplateId?: string;
  landerTemplateId?: string;
}) => {
  const utils = trpc.useUtils();
  const navigate = useNavigate();

  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { mutateAsync: addAdToBoard } = trpc.addAdToBoard.useMutation();
  const { mutateAsync: addLanderToBoard } = trpc.addLanderToBoard.useMutation();
  const { mutateAsync: addEmailTemplateToBoard } =
    trpc.addEmailTemplateToBoard.useMutation();
  const { mutateAsync: addLandingPageTemplateToBoard } =
    trpc.addLandingPageTemplateToBoard.useMutation();

  const { mutateAsync: addTemplateToBoard } =
    trpc.addTemplateToBoard.useMutation();

  const { mutate: createBoard, isPending: createIsPending } =
    trpc.createBoard.useMutation({
      onSuccess: async (data) => {
        setMessage("Collection created successfully");

        await utils.getBoards
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            if (adId) {
              // we directly add the ad to the collection if created from the inspiration card
              setMessage(null);
              setLoading(true);
              addAdToBoard({
                boardId: data.id,
                adId,
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} collection!`);
                utils.getBoards.invalidate();
                showToastNotification("success", {
                  message: `Added to ${data.name} collection!`,
                });
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else if (templateId) {
              // we directly add the template to the collection if created from the ad template card
              setMessage(null);
              setLoading(true);
              addTemplateToBoard({
                boardId: data.id,
                templateId,
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} collection!`);
                utils.getBoards.invalidate();
                showToastNotification("success", {
                  message: `Added to ${data.name} collection!`,
                });
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else if (inspirationLanderId) {
              // we directly add the inspirationLander to the collection if created from the inspiration card
              setMessage(null);
              setLoading(true);
              addLanderToBoard({
                boardId: data.id,
                landerId: inspirationLanderId,
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} collection!`);
                utils.getBoards.invalidate();
                showToastNotification("success", {
                  message: `Added to ${data.name} collection!`,
                });
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else if (emailTemplateId) {
              // we directly add the emailTemplate to the collection if created from the inspiration card
              setMessage(null);
              setLoading(true);
              addEmailTemplateToBoard({
                boardId: data.id,
                templateId: parseInt(emailTemplateId),
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} collection!`);
                utils.getBoards.invalidate();
                showToastNotification("success", {
                  message: `Added to ${data.name} collection!`,
                });
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else if (landerTemplateId) {
              // we directly add the landerTemplate to the collection if created from the inspiration card
              setMessage(null);
              setLoading(true);
              addLandingPageTemplateToBoard({
                boardId: data.id,
                templateId: parseInt(landerTemplateId),
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} collection!`);
                utils.getBoards.invalidate();
                showToastNotification("success", {
                  message: `Added to ${data.name} collection!`,
                });
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else {
              // we navigate to the collection's page
              setTimeout(() => {
                onOpenChange();
                navigate({
                  to: `/feeds/boards/$boardID/ads`,
                  params: {
                    boardID: data.id,
                  },
                });
              }, 1000);
            }
          });
      },
    });

  const { mutate: editBoard, isPending: editIsPending } =
    trpc.editBoard.useMutation({
      onSuccess: async () => {
        setMessage("Collection updated successfully");
        await utils.getBoardDetails
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            setTimeout(() => {
              onOpenChange();
            }, 2000);
          });
        await utils.getBoards
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            setTimeout(() => {
              onOpenChange();
            }, 2000);
          });
      },
    });

  const formSchema = z.object({
    name: z.string().min(1, "Collection name is required"),
    description: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: data?.name || "",
      description: data?.description || "",
    },
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (data) {
        editBoard({ ...values, boardId: data.id });
      } else createBoard(values);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      open={open || editIsPending || createIsPending || loading}
      onOpenChange={onOpenChange}
    >
      <DialogContent
        className={"gap-6"}
        // onInteractOutside={(e) => {
        //   e.preventDefault();
        // }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            // Additional logic for Enter key
          }
        }}
      >
        <DialogHeader>
          <DialogTitle>
            <Text weight={"semibold"} size={"lg"} className="leading-7">
              {data
                ? "Edit Collections"
                : loading
                  ? "Adding ad to collection"
                  : "Create Collection"}
            </Text>
          </DialogTitle>
        </DialogHeader>
        {message ? (
          <div
            className={
              "flex flex-col items-center text-center gap-2 w-full h-full m-auto"
            }
          >
            <CheckCircle2 size={"3rem"} strokeWidth={"1.2"} color={"green"} />
            <p>{message}</p>
          </div>
        ) : editIsPending || createIsPending || loading ? (
          <div className={"flex flex-col gap-3 w-full h-full m-auto"}>
            <Loading />
          </div>
        ) : (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className={""}>
              <div className={"flex flex-col gap-6"}>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium leading-5">
                        Collection Name
                      </FormLabel>
                      <FormControl>
                        <Input
                          className="shadow-none"
                          placeholder={"Name"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Collection Description</FormLabel>
                      <FormControl>
                        <Input
                          className="shadow-none"
                          placeholder={"Description"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className={"flex justify-end gap-2"}>
                  <Button
                    className={"w-15 px-3 h-8 text-sm"}
                    variant={"outline"}
                    onClick={() => onOpenChange()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    className={"w-15 px-3 h-8 text-sm"}
                    variant={"default"}
                  >
                    {createIsPending || editIsPending
                      ? "Submitting..."
                      : "Save"}
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export const DeleteBoardDialog = ({
  data,
  open,
  onOpenChange,
}: {
  data?: BoardProp;
  open: boolean;
  onOpenChange: () => void;
}) => {
  const utils = trpc.useUtils();

  const [message, setMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const { mutateAsync: deleteBoard, isPending: deleteIsPending } =
    trpc.deleteBoard.useMutation({
      onSuccess: async () => {
        setMessage("Collection deleted successfully");
        await utils.getBoards
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            setTimeout(() => {
              onOpenChange();
              navigate({
                to: `/feeds/boards`,
              });
            }, 2000);
          });
      },
    });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={"gap-1.5"}>
        <DialogHeader>
          <DialogTitle>
            <Text weight={"semibold"} size={"lg"}>
              Delete Collection
            </Text>
          </DialogTitle>
        </DialogHeader>
        {message ? (
          <div
            className={
              "flex flex-col items-center text-center gap-2 w-full h-full m-auto"
            }
          >
            <CheckCircle2 size={"3rem"} strokeWidth={"1.2"} color={"green"} />
            <p>{message}</p>
          </div>
        ) : deleteIsPending ? (
          <div className={"flex w-full h-full m-auto"}>
            <Loading />
          </div>
        ) : (
          data && (
            <Stack className={"gap-4"}>
              <Text
                align={"left"}
                weight={"normal"}
                size={"sm"}
                className="leading-5 text-thememutedforeground"
              >
                Are you sure you want to delete the collection {data?.name}
              </Text>
              <div className={"flex justify-end gap-2"}>
                <Button
                  disabled={deleteIsPending}
                  className={"w-15 px-3 h-8 text-sm"}
                  variant={"outline"}
                  onClick={onOpenChange}
                >
                  No
                </Button>
                <Button
                  disabled={deleteIsPending}
                  onClick={() => deleteBoard({ boardId: data.id })}
                  className={"w-15 px-3 h-8 text-sm"}
                  variant={"default"}
                >
                  Yes
                </Button>
              </div>
            </Stack>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

export const SidebarButton = (props: { sideBarOpen?: boolean }) => {
  return (
    <Link
      search={(old) => ({
        ...old,
        sideBarOpen: !props.sideBarOpen,
      })}
      params={(old) => ({
        ...old,
      })}
      className={
        "flex lg:hidden items-center justify-center w-6 h-6 rounded-full"
      }
    >
      {props.sideBarOpen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-x"
        >
          <path d="M18 6 6 18" />
          <path d="m6 6 12 12" />
        </svg>
      ) : (
        <svg
          className={"fill-slate-700"}
          xmlns="http://www.w3.org/2000/svg"
          height="36"
          viewBox="0 -960 960 960"
          width="36"
        >
          <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
        </svg>
      )}
    </Link>
  );
};
