import { forwardRef } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils.ts";

const templatesHeaderStyles = cva("w-full");

export interface TemplatesFooterProps
  extends React.ComponentPropsWithoutRef<"div">,
    VariantProps<typeof templatesHeaderStyles> {
  footerTitle: string;
  titleColor: string;
  footerType?: string;
  creativeOsTextColor?: string;
  rhsComponents?: JSX.Element;
}

export const TemplatesFooter = forwardRef<HTMLDivElement, TemplatesFooterProps>(
  (
    {
      className,
      footerTitle,
      titleColor,
      creativeOsTextColor,
      footerType,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(templatesHeaderStyles({ className }))}
        {...props}
      >
        <div className="flex justify-between">
          <div className={`flex gap-[0.625rem] items-center`}>
            <img
              src={"/images/emblam.png"}
              alt={"CreativeOs"}
              className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
            />
            <div>
              {/**This check is being used here for the text color because this component is being used on pages that will be designed later. When the pages are being designed, we will take out the condition for the popover color */}
              <h4
                className={`truncate font-semibold text-sm leading-5 ${titleColor}`}
              >
                {footerTitle}
              </h4>

              <span
                className={`font-normal text-xs leading-4 text-${creativeOsTextColor ?? "themeinput"}`}
              >
                by Creative OS
              </span>
            </div>
          </div>
          {props.rhsComponents ?? (
            <span className="font-normal text-xs leading-4 text-themeinput">
              {footerType}
            </span>
          )}
        </div>
      </div>
    );
  },
);
