import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form";
import { PasswordInput } from "@/components/shadcn/password-input";
import { Input } from "@/components/shadcn/input";
import { Button } from "@/components/shadcn/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Label } from "@/components/shadcn/label.tsx";
import OnboardingHeader from "@/components/onboarding/OnboardingHeader.tsx";
import { trpc } from "@/utils/trpc.ts";
import showToastNotification from "@/hooks/useShowToast";
import { memberstack } from "@/App.tsx";
import {
  landerFeedOrderFilter,
  orderFilter as adOrderFilter,
} from "../../../shared/airtableGet.ts";
import { useEffect } from "react";

export type WorkOSUser = {
  object: string;
  id: string;
  email: string;
  emailVerified: boolean;
  firstName: string | null;
  profilePictureUrl: string | null;
  lastName: string | null;
  createdAt: string;
  updatedAt: string;
  avatar?: string | null;
};

type SearchParams = {
  status?: string;
};

export const Route = createFileRoute("/login")({
  component: Login,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const status = search?.status as string;

    return {
      status,
    };
  },
});

function Login() {
  const { status: authStatus } = Route.useSearch();

  const navigate = useNavigate();

  const { mutate: signInWithWorkos, isPending } = trpc.signIn.useMutation();

  const { mutate: signUpWithGoogle, isPending: isPendingGoogleUrl } =
    trpc.generateGoogleAuthorizationUrl.useMutation();

  const {
    isLoading: authIsLoading,
    isRefetching,
    refetch: refetchUserDetails,
  } = trpc.me.useQuery(null, { enabled: false });

  const loginFormSchema = z.object({
    email: z.string({ required_error: "Email address is required" }).email({
      message: "Please enter a valid email address.",
    }),
    password: z.string({ required_error: "Password is required" }).min(8, {
      message: "Password must be at least 8 characters.",
    }),
  });

  const { mutateAsync: logoutUser } = trpc.logoutUser.useMutation();

  type LoginFormValues = z.infer<typeof loginFormSchema>;

  const defaultValues: Partial<LoginFormValues> = {
    email: "",
    password: "",
  };

  const form = useForm<LoginFormValues>({
    resolver: zodResolver(loginFormSchema),
    defaultValues,
  });

  type MemberstackAuthUser = {
    email: string;
    hasPassword: boolean;
    providers: { provider: string }[];
  };

  // Type guard to check if user is WorkOSUser
  const isWorkOSUser = (
    user: WorkOSUser | MemberstackAuthUser,
  ): user is WorkOSUser => {
    return (user as WorkOSUser).object !== undefined;
    // Replace `someWorkOSUserProperty` with a unique property of WorkOSUser
  };

  const handleLoginSuccess = (
    user: WorkOSUser | MemberstackAuthUser,
    route: string,
    orderFilter:
      | z.infer<typeof adOrderFilter>
      | z.infer<typeof landerFeedOrderFilter>
      | undefined,
  ) => {
    if (isWorkOSUser(user))
      refetchUserDetails().then((authUser) => {
        if (
          typeof localStorage !== "undefined" &&
          !authIsLoading &&
          !isRefetching
        ) {
          localStorage.setItem("_wos_user", JSON.stringify(authUser.data));
        }

        showToastNotification("success", {
          message: "Login successful!",
        });

        setTimeout(() => {
          navigate({
            to: route,
            search: {
              orderFilter,
            },
          });
        }, 2000);
      });
    else {
      showToastNotification("success", {
        message: "Login successful!",
      });

      setTimeout(() => {
        navigate({
          to: route,
          search: {
            orderFilter,
          },
        });
      }, 2000);
    }
  };

  type ErrorWithMessage = {
    message: string;
  };

  // Type guard to check if error has a message property
  function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
    return (
      typeof error === "object" &&
      error !== null &&
      "message" in error &&
      typeof (error as Record<string, unknown>).message === "string"
    );
  }

  const handleLoginError = (error: unknown) => {
    if (isErrorWithMessage(error)) {
      showToastNotification("error", {
        message: error.message,
        description:
          "Please check your email and password and try again or log in with your existing account.",
      });
    } else {
      showToastNotification("error", {
        message: "An unknown error occurred.",
        description: "Please try again.",
      });
    }
  };

  const loginWithMemberstack = async ({ email, password }: LoginFormValues) => {
    try {
      const loginResult = await memberstack.loginMemberEmailPassword({
        email,
        password,
      });

      if (loginResult) {
        handleLoginSuccess(
          loginResult.data.member.auth,
          `/reset-password?accessToken=${loginResult.data.tokens.accessToken}&email=${loginResult.data.member.auth.email}`,
          undefined,
        );
      }
    } catch (error) {
      handleLoginError(error);
    }
  };

  const onSubmit = async ({ email, password }: LoginFormValues) => {
    const signInWithWorkosOpts = {
      onSuccess: (data: WorkOSUser) => {
        if (data) {
          handleLoginSuccess(data, "/feeds/templates", "Recent");
        } else {
          loginWithMemberstack({ email, password });
        }
      },
      onError: (error: { message: string }) => {
        if (
          error.message ===
          "Email ownership must be verified before authentication."
        ) {
          showToastNotification("error", {
            message: error.message,
            description:
              "Please check the 6-digit code and try again. If you didn’t receive the code, request a new one.",
          });
          navigate({ to: "/verify-email" });
        } else if (error.message === "Invalid credentials") {
          loginWithMemberstack({ email, password });
        } else {
          showToastNotification("error", {
            message: "Invalid Credentials",
            description: "Check your email and password and try again.",
          });
        }
      },
    };
    logoutUser(undefined, {
      onSuccess: () => {
        signInWithWorkos({ email, password }, signInWithWorkosOpts);
      },
      onError: () => {
        signInWithWorkos({ email, password }, signInWithWorkosOpts);
      },
    });
  };

  const signUpWithGoogleAuth = async () => {
    const signUpWithGoogleOpts = {
      onSuccess: (data: string) => {
        if (data) {
          window.location.href = data;
        }
      },
      onError: (error: { message: string }) => {
        showToastNotification("error", {
          message: error.message,
        });
      },
    };
    logoutUser(undefined, {
      onSuccess: () => {
        signUpWithGoogle(undefined, signUpWithGoogleOpts);
      },
      onError: () => {
        signUpWithGoogle(undefined, signUpWithGoogleOpts);
      },
    });
  };

  useEffect(() => {
    if (authStatus && authStatus === "error") {
      setTimeout(() => {
        showToastNotification("error", {
          message: "An error occurred",
          description: "We encountered an error. Please try again.",
        });
      }, 1000);
    }
  }, []);

  return (
    <div
      className={"bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen"}
    >
      <div>
        <OnboardingHeader />
      </div>
      <div
        className={
          "flex-1 flex flex-col justify-center items-center py-[7.75rem]"
        }
      >
        <div
          className={
            "rounded-lg bg-white p-5 w-5/6 lg:w-[32rem] flex flex-col gap-6 border"
          }
        >
          <div className={"flex flex-col items-center gap-2"}>
            <img
              alt={"Creative OS"}
              src={"cos-logo.png"}
              className={"w-8 h-8"}
            />
            <h4
              className={
                "text-2xl text-center text-themeforeground font-semibold "
              }
            >
              Login to your account
            </h4>
          </div>
          <div>
            <Button
              variant={"outline"}
              type={"submit"}
              disabled={isPendingGoogleUrl}
              onClick={signUpWithGoogleAuth}
              className={"flex gap-2 items-center justify-center w-full"}
            >
              <img alt={""} src={"/images/google-icon.png"} />
              <span>Sign in with Google</span>
            </Button>
            <div className={"flex items-center self-stretch gap-3 mt-6"}>
              <span className={"border border-themeborder w-full"} />
              <span>OR</span>
              <span className={"border border-themeborder w-full"} />
            </div>
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className={"flex flex-col gap-6"}>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Email Address</Label>
                      <FormControl>
                        <Input
                          placeholder="name@example.com"
                          type={"email"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <div className={"flex gap-5 justify-between"}>
                        <Label>Password</Label>{" "}
                        <Link
                          to={"/password-reset"}
                          className={
                            "text-thememutedforeground text-xs hover:underline"
                          }
                        >
                          Forgot Password
                        </Link>
                      </div>
                      <FormControl>
                        <PasswordInput placeholder="Password" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <Button disabled={isPending} type={"submit"} className="w-full">
                  {isPending ? "Logging in..." : "Log In"}
                </Button>
                <div>
                  <Link
                    to="/sign-up"
                    className={"block text-center mt-1.5 text-sm"}
                  >
                    Don't have an account?{" "}
                    <span className={"underline font-medium"}>Sign up</span>
                  </Link>
                </div>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
