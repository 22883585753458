import { trpc } from "@/utils/trpc";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/dialog";
import { Button } from "@/components/shadcn/button";
import { useNavigate } from "@tanstack/react-router";
import { PlanDetail, PRO_PLAN, STANDARD_PLAN } from "@/utils/data/plans.ts";
import { useEffect, useState } from "react";
import { Loader } from "@/components/custom-components/Loader";
import { Badge } from "@/components/shadcn/badge";
import { Input } from "@/components/shadcn/input.tsx";
import { CheckCircle } from "@mynaui/icons-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form.tsx";
import showToastNotification from "@/hooks/useShowToast";
import { CheckoutUpgradeSubscription } from "@/components/CheckoutSubscriptionPlan.tsx";
import { WorkOSUser } from "@/routes/login.tsx";

export const UserProfileDialog = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) => {
  const navigate = useNavigate();

  const { data: userData, isLoading: fetchingUserData } =
    trpc.me.useQuery(null);

  const { data: permissionData, isLoading: fetchingPermissions } =
    trpc.permissions.useQuery(null, {});

  const { mutateAsync: logoutUser, isPending: loggingOut } =
    trpc.logoutUser.useMutation();

  const { mutate: generateCancelUrl, isPending: fetchingCancelUrl } =
    trpc.generatePreCancelUrl.useMutation({
      onSuccess: async (data) => {
        if (data) {
          window.location.href = data;
        }
      },
    });

  const [edit, setEdit] = useState<boolean>(false);
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<PlanDetail | null>(null);

  const { mutate: resetUserPassword, isPending: sendingPasswordResetEmail } =
    trpc.sendResetPasswordEmail.useMutation();

  async function onResetPassword({ email }: { email: string }) {
    if (email) {
      try {
        resetUserPassword(
          { email },
          {
            onSuccess: () => {
              // let's keep the email in
              showToastNotification("success", {
                message: "Request sent successfully!",
                description:
                  "We have sent you an email to complete the password reset",
              });
            },
            onError: (error) => {
              showToastNotification("error", {
                message: error.message,
              });
            },
          },
        );
      } catch (e) {
        console.log(e);
      }
    }
  }

  const { data: chargebeeSyncStatus } = trpc.getIsUserSynced.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  );

  const { mutate: openPortalSessionUrl, isPending: fetchingPortalSessionUrl } =
    trpc.getPortalSessionUrl.useMutation({
      onSuccess: async (data) => {
        if (data) {
          window.location.href = data;
        }
      },
    });

  return (
    <Dialog
      open={open}
      onOpenChange={edit ? () => setEdit(false) : onOpenChange}
    >
      {fetchingPermissions || fetchingUserData ? (
        <DialogContent
          className={"border-0 rounded-lg max-w-lg px-6"}
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          <div className="flex justify-center items-center w-full py-6 h-14">
            <Loader />
          </div>
        </DialogContent>
      ) : upgradeOpen && selected ? (
        <DialogContent>
          <CheckoutUpgradeSubscription
            userHasPlan={true}
            selectedPlan={selected}
          />
        </DialogContent>
      ) : (
        <DialogContent
          className={`border-0 lg:rounded-lg h-full lg:h-auto max-w-full lg:max-w-lg px-6 py-6 pt-4`}
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          {edit && userData ? (
            <EditProfile userData={userData} setEdit={setEdit} />
          ) : (
            <div
              className={`flex flex-col gap-6 ${!(edit || upgradeOpen) ? "" : "hidden"}`}
            >
              <div className="gap-3 flex flex-col">
                <div className={"flex justify-start items-start gap-1.5 mt-10"}>
                  <img
                    alt={""}
                    src={userData?.avatar || "/bg_gradient.jpg"}
                    className={
                      "rounded-full cursor-pointer shrink-0 bg-[#A259FF] w-9 h-9"
                    }
                  />
                  <span className="font-semibold text-2xl leading-8 text-themeforeground">
                    {`${
                      userData?.firstName || userData?.lastName
                        ? `${userData.firstName ?? ""} ${userData.lastName ?? ""}`
                        : ""
                    }`.trim()}
                  </span>
                </div>
                <div
                  className={"flex justify-between items-center gap-1.5 h-9"}
                >
                  <span className="font-semibold text-base leading-6 text-themeforeground">
                    Account
                  </span>
                  <span
                    onClick={() => setEdit(true)}
                    className="font-medium text-sm leading-5 text-themeforeground underline underline-offset-1 cursor-pointer tracking-wide	"
                  >
                    Edit
                  </span>
                </div>
                <div className={"flex justify-start items-start gap-1.5"}>
                  <span className="font-normal text-base leading-6 text-thememutedforeground w-20 min-w-20">
                    Name
                  </span>
                  <span className="font-medium text-base leading-5 text-themeforeground">
                    {`${
                      userData?.firstName || userData?.lastName
                        ? `${userData.firstName ?? ""} ${userData.lastName ?? ""}`
                        : ""
                    }`.trim()}
                  </span>
                </div>
                <div className={"flex justify-start items-start gap-1.5"}>
                  <span className="font-normal text-base leading-6 text-thememutedforeground w-20 min-w-20">
                    Email
                  </span>
                  <span className="font-medium text-base leading-5 text-themeforeground">
                    {`${userData?.email ?? ""}`.trim()}
                  </span>
                </div>
              </div>
              <div className="gap-3 flex flex-col">
                {chargebeeSyncStatus && (
                  <div
                    className={"flex justify-between items-center gap-1.5 h-9"}
                  >
                    <span className="font-semibold text-base leading-6 text-themeforeground">
                      Plan
                    </span>
                    {fetchingPortalSessionUrl || fetchingCancelUrl ? (
                      <span className="font-medium text-sm leading-5 text-thememutedforeground tracking-wide	">
                        Loading...
                      </span>
                    ) : (
                      <div className="flex gap-4">
                        <span
                          onClick={() => generateCancelUrl()}
                          className="font-medium text-sm leading-5 text-themeforeground underline underline-offset-1 cursor-pointer tracking-wide"
                        >
                          Cancel
                        </span>
                        <span
                          onClick={() => openPortalSessionUrl()}
                          className="font-medium text-sm leading-5 text-themeforeground underline underline-offset-1 cursor-pointer tracking-wide"
                        >
                          Manage Plan
                        </span>
                      </div>
                    )}
                  </div>
                )}

                {[STANDARD_PLAN, PRO_PLAN].map((plan, index) => (
                  <UpgradeBox
                    boxData={plan}
                    key={index}
                    userPlans={permissionData?.usersPlans ?? []}
                    descriptionDetailsToShow={"features"}
                    setUpgradeOpen={setUpgradeOpen}
                    setSelected={setSelected}
                  />
                ))}
                <div className={"flex gap-2 mb-4"}>
                  <Button
                    variant={"outline"}
                    className="h-8"
                    onClick={() =>
                      onResetPassword({ email: userData?.email ?? "" })
                    }
                    disabled={sendingPasswordResetEmail || !userData?.email}
                  >
                    {sendingPasswordResetEmail
                      ? "Resetting Password..."
                      : "Reset Password"}
                  </Button>
                  <Button
                    className="h-8"
                    onClick={() => {
                      logoutUser().then(() => {
                        if (typeof localStorage !== "undefined") {
                          localStorage.clear();
                        }
                        navigate({ to: "/login" });
                      });
                    }}
                    disabled={loggingOut}
                  >
                    {loggingOut ? "Logging Out..." : "Logout"}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default function UpgradeBox({
  boxData,
  userPlans,
  descriptionDetailsToShow,
  setSelected,
  setUpgradeOpen,
}: {
  boxData: PlanDetail;
  userPlans: string[];
  descriptionDetailsToShow: "description" | "features";
  setUpgradeOpen: (opened: boolean) => void;
  setSelected: (plan: PlanDetail) => void;
}) {
  const userIsOnProPlan =
    userPlans.includes(PRO_PLAN.plan_id) ||
    userPlans.includes(PRO_PLAN.no_trial_plan_id);

  const isProPlanBox =
    boxData.plan_id === PRO_PLAN.plan_id ||
    boxData.plan_id === PRO_PLAN.no_trial_plan_id;

  const isStandardPlanBox =
    boxData.plan_id === STANDARD_PLAN.plan_id ||
    boxData.plan_id === STANDARD_PLAN.no_trial_plan_id;

  const userPlansContainCurrentBoxDataPlans =
    userPlans.includes(boxData.plan_id) ||
    userPlans.includes(boxData.no_trial_plan_id);

  return userIsOnProPlan && !isProPlanBox ? (
    <></>
  ) : (
    <div
      className={`p-4 border-2	rounded-lg ${userPlansContainCurrentBoxDataPlans ? "border-themeborder" : "border-themedestructive"} gap-1`}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className="text-themeforeground text-base leading-6 font-medium">
            {boxData.name}
          </span>
          {isProPlanBox && !userPlansContainCurrentBoxDataPlans && (
            <Badge className="text-themedestructive text-xs leading-4 font-medium bg-purple-100 hover:bg-purple-100">
              {userPlans.length == 0 ? "Recommended" : "Premium"}
            </Badge>
          )}
          {userPlansContainCurrentBoxDataPlans && (
            <Badge className="text-themeforeground hover:text-themeforeground text-xs leading-4 font-medium bg-thememuted hover:bg-thememuted">
              Current Plan
            </Badge>
          )}
        </div>
        <div className="flex items-center gap-2">
          <span className="text-themeforeground text-base leading-6 font-bold">
            {boxData.price} / mo
          </span>
          {!userPlansContainCurrentBoxDataPlans && (
            <Button
              variant={"destructivePurple"}
              size={"sm"}
              onClick={() => {
                setSelected(boxData);
                setUpgradeOpen(true);
              }}
            >
              {isProPlanBox && userPlans.length == 0
                ? "Get Pro"
                : isStandardPlanBox && userPlans.length == 0
                  ? "Get Started"
                  : "Upgrade"}
            </Button>
          )}
        </div>
      </div>
      {!userPlansContainCurrentBoxDataPlans && (
        <div>
          {descriptionDetailsToShow === "features" ? (
            <div className="flex flex-col gap-2">
              <span className="text-sm font-normal leading-5 text-themeforeground">
                {boxData.price} per month, cancel anytime{" "}
              </span>
              <>
                {boxData.features.map((feature, index) => (
                  <div
                    key={index}
                    className="flex gap-1 justify-start items-center text-thememutedforeground"
                  >
                    <CheckCircle className="w-4 h-4" />
                    <span className="text-sm font-normal leading-5 ">
                      {feature}
                    </span>
                  </div>
                ))}
              </>
            </div>
          ) : (
            <p className={"text-sm font-light"}>{boxData.description}</p>
          )}
        </div>
      )}
    </div>
  );
}

function EditProfile({
  userData,
  setEdit,
}: {
  userData: WorkOSUser;
  setEdit: (status: boolean) => void;
}) {
  const utils = trpc.useUtils();

  const { mutate: editUserProfile, isPending: editIsPending } =
    trpc.updateUserProfile.useMutation({
      onSuccess: async () => {
        showToastNotification("success", {
          message: "Profile details successfully updated!",
        });

        await utils.me
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            setEdit(false);
          });

        if (typeof localStorage !== "undefined") {
          const userDataStringified = localStorage.getItem("_wos_user");
          const userData: {
            object: "user";
            email: string;
            id: string;
            createdAt: string;
            updatedAt: string;
            firstName: string | null;
            lastName: string | null;
            emailVerified: boolean;
            profilePictureUrl: string | null;
          } = userDataStringified ? JSON.parse(userDataStringified) : {};
          localStorage.setItem(
            "_wos_user",
            JSON.stringify({
              ...userData,
              firstName: form.getValues("firstName"),
              lastName: form.getValues("lastName"),
            }),
          );
        }
      },
    });
  const formSchema = z.object({
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    email: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  const handleSubmitEditProfile = async (
    values: z.infer<typeof formSchema>,
  ) => {
    try {
      if (userData) {
        const { firstName, lastName } = values;
        editUserProfile({ data: { firstName, lastName }, userId: userData.id });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (userData) {
      form.reset({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
      });
    }
  }, [userData, form]);

  return (
    <div className={`flex flex-col gap-6`}>
      <DialogHeader>
        <DialogTitle className="">
          <div className="flex justify-between mt-10">
            <div className={"flex justify-start items-center gap-1.5  w-fit "}>
              <div className={"flex w-9 h-9"}>
                <img
                  alt={""}
                  src={userData?.avatar ?? "/bg_gradient.jpg"}
                  className={
                    "rounded-full cursor-pointer shrink-0 bg-[#A259FF] w-9 h-9"
                  }
                />
              </div>
              <span
                className={
                  "max-w-[15rem] text-themeforeground h-full font-semibold text-2xl leading-8 truncate overflow-ellipsis overflow-hidden"
                }
              >
                {`${
                  userData?.firstName || userData?.lastName
                    ? `${userData.firstName ?? ""} ${userData.lastName ?? ""}`
                    : ""
                }`.trim()}
              </span>
            </div>
          </div>
        </DialogTitle>
      </DialogHeader>
      <div className="gap-3 flex flex-col max-w-lg">
        <div className={"flex justify-between items-center gap-1.5 h-9"}>
          <span className="font-semibold text-base leading-6 text-themeforeground">
            Account
          </span>
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmitEditProfile)}
            className={""}
          >
            <div className={"flex flex-col gap-3"}>
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder={"First Name"} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder={"Last Name"} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                disabled
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        className="shadow-none"
                        placeholder={"Email"}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className={"flex justify-start gap-2"}>
                <Button
                  type={"submit"}
                  className={"w-15 px-3 h-8 text-sm"}
                  variant={"default"}
                  disabled={editIsPending}
                >
                  {editIsPending ? "Saving..." : "Save Changes"}
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
