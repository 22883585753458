import { pgTable, serial, timestamp, varchar } from "drizzle-orm/pg-core";

export const requestedBrands = pgTable("requested_brands", {
    id: serial("id").primaryKey().notNull(),
    userId: varchar("user_id").notNull(),
    brandName: varchar("brand_name", { length: 255 }).notNull(),
    brandDomain: varchar("brand_domain", { length: 255 }).notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
        .defaultNow()
        .notNull(),
});