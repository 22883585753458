import "./App.css";
import type { AppRouter } from "../../server";
import { createTRPCReact } from "@trpc/react-query";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpLink } from "@trpc/client";

import { RouterProvider, createRouter } from "@tanstack/react-router";
import "./lib/amplitude";
import memberstackDOM from "@memberstack/dom";

export const memberstack = memberstackDOM.init({
  publicKey: "pk_971c0de956b797603818",
});

// Import the generated route tree
import { routeTree } from "./routeTree.gen";

export const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export const trpc = createTRPCReact<AppRouter>();

export const fetchWithRefresh = async (
  url: RequestInfo | URL,
  options?: RequestInit,
) => {
  let response = await fetch(url, {
    ...options,
    credentials: "include",
  });

  if (response.status === 401) {
    const publicPages = ["/share/templates", "/share/inspiration"];

    const currentPath = window.location.pathname;

    // Check if the current path is a public page
    const isPublicPage = publicPages.some((page) =>
      currentPath.startsWith(page),
    );

    if (isPublicPage) {
      return new Response(null, { status: 200 }); // Return a 200 response for public pages
    }

    // Attempt to refresh the session if not a public page
    const refreshResponse = await fetch(
      `${import.meta.env.VITE_API_URL}refreshSession`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (refreshResponse.ok) {
      // Retry the original request after refreshing the token
      response = await fetch(url, {
        ...options,
        credentials: "include",
      });
    } else {
      // Handle failed refresh (e.g., redirect to login)
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);

      throw new Error("Failed to refresh token, please log in again");
    }
  }

  return response;
};

export function App() {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: import.meta.env.VITE_API_URL as string,
          fetch: fetchWithRefresh,
        }),
      ],
    }),
  );

  useEffect(() => {
    window.Chargebee.init({
      site: import.meta.env.VITE_CHARGEBEE_SITE,
      publishableKey: import.meta.env.VITE_CHARGEBEE_PUBLISHABLE_KEY,
    });

    // Refresh session every 4 minutes
    const interval = setInterval(
      async () => {
        try {
          await fetch(`${import.meta.env.VITE_API_URL}refreshSession`, {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          });
        } catch (error) {
          console.error("Failed to refresh session:", error);
        }
      },
      4 * 60 * 1000,
    );

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </trpc.Provider>
  );
}
