import {
  pgTable,
  unique,
  pgEnum,
  serial,
  timestamp,
  text,
  integer,
  varchar,
  index,
  bigint,
  boolean,
  uniqueIndex,
  primaryKey,
  pgMaterializedView,
  doublePrecision,
} from "drizzle-orm/pg-core";
import { relations, sql } from "drizzle-orm";
import { createInsertSchema } from "drizzle-zod";
import { boards } from "./boards";

export const adType = pgEnum("ad_type", [
  "none",
  "election",
  "social_issues",
  "political",
]);
export const ageRange = pgEnum("age_range", [
  "65+",
  "55-64",
  "45-54",
  "35-44",
  "25-34",
  "18-24",
  "13-17",
]);
export const gender = pgEnum("gender", ["f", "m", "u"]);
export const imageformat = pgEnum("imageformat", ["jpg", "png"]);

export const savedAdAdHeadline = pgTable(
  "saved_ad_ad_headline",
  {
    id: serial("id").primaryKey().notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    headline: text("headline").notNull(),
  },
  (table) => {
    return {
      savedAdAdHeadlineHeadlineKey: unique(
        "saved_ad_ad_headline_headline_key"
      ).on(table.headline),
    };
  }
);

export const adScrapeEvent = pgTable("ad_scrape_event", {
  id: serial("id").primaryKey().notNull(),
  createdAt: timestamp("created_at", { mode: "string" }).defaultNow().notNull(),
  scrapedUrl: text("scraped_url").notNull(),
  totalResults: integer("total_results").notNull(),
});

export const alembicVersion = pgTable("alembic_version", {
  versionNum: varchar("version_num", { length: 32 }).notNull(),
});

export const brand = pgTable(
  "brand",
  {
    brandName: varchar("brand_name").notNull(),
    brandImage: varchar("brand_image").notNull(),
    fbPageId: varchar("fb_page_id").primaryKey().notNull(),
    fbPageLink: varchar("fb_page_link").notNull(),
    createdAt: timestamp("created_at", { mode: "string" }),
  },
  (table) => {
    return {
      idxBrandFbPageId: index("idx_brand_fb_page_id").on(table.fbPageId),
    };
  }
);

export const lastActivityTime = pgTable("last_activity_time", {
  userId: text("user_id").primaryKey().notNull(),
  activityTime: timestamp("activity_time", { mode: "string" }).notNull(),
});

export const euAdDelivery = pgTable(
  "eu_ad_delivery",
  {
    id: serial("id").primaryKey().notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    fkSavedAd: integer("fk_saved_ad")
      .notNull()
      .references(() => savedAd.id)
      .references(() => savedAd.id),
    iso316612020CountryCode: text("iso_3166_1_2020_country_code").notNull(),
    ageRange: ageRange("age_range").notNull(),
    gender: gender("gender").notNull(),
    // You can use { mode: "bigint" } if numbers are exceeding js number limitations
    reach: bigint("reach", { mode: "number" }).notNull(),
  },
  (table) => {
    return {
      idxEuAdDeliveryFkSavedAd: index("idx_eu_ad_delivery_fk_saved_ad").on(
        table.fkSavedAd
      ),
    };
  }
);

export const savedAdAdText = pgTable(
  "saved_ad_ad_text",
  {
    id: serial("id").primaryKey().notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    text: text("text").notNull(),
  },
  (table) => {
    return {
      savedAdAdTextTextKey: unique("saved_ad_ad_text_text_key").on(table.text),
    };
  }
);

export const savedAd = pgTable(
  "saved_ad",
  {
    id: serial("id").primaryKey().notNull(),
    // You can use { mode: "bigint" } if numbers are exceeding js number limitations
    libraryId: bigint("library_id", { mode: "number" }).notNull(),
    fkAdScrapeEvent: integer("fk_ad_scrape_event").notNull(),
    startedRunningDate: timestamp("started_running_date", {
      mode: "string",
    }).notNull(),
    stoppedRunningDate: timestamp("stopped_running_date", { mode: "string" }),
    runningOnFacebook: boolean("running_on_facebook").notNull(),
    runningOnInstagram: boolean("running_on_instagram").notNull(),
    runningOnMessenger: boolean("running_on_messenger").notNull(),
    runningOnAudienceNetwork: boolean("running_on_audience_network").notNull(),
    fkFbPageId: varchar("fk_fb_page_id"),
  },
  (table) => {
    return {
      idxSavedAdId: index("idx_saved_ad_id").on(table.id),
      idxSavedAdFkFbPageId: index("idx_saved_ad_fk_fb_page_id").on(
        table.fkFbPageId
      ),
      savedAdLibraryIdKey: unique("saved_ad_library_id_key").on(
        table.libraryId
      ),
    };
  }
);

export const savedAdAdDescription = pgTable(
  "saved_ad_ad_description",
  {
    id: serial("id").primaryKey().notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    description: text("description").notNull(),
  },
  (table) => {
    return {
      savedAdAdDescriptionDescriptionKey: unique(
        "saved_ad_ad_description_description_key"
      ).on(table.description),
    };
  }
);

export const savedAdDisplayUrl = pgTable(
  "saved_ad_display_url",
  {
    id: serial("id").primaryKey().notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    displayUrl: text("display_url").notNull(),
  },
  (table) => {
    return {
      savedAdDisplayUrlDisplayUrlKey: unique(
        "saved_ad_display_url_display_url_key"
      ).on(table.displayUrl),
    };
  }
);

export const savedAdImageCreative = pgTable(
  "saved_ad_image_creative",
  {
    id: serial("id").primaryKey().notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    imageSha256Hash: text("image_sha256_hash").notNull(),
    imageUrl: text("image_url"),
    imageWidth: doublePrecision("image_width"),
    imageHeight: doublePrecision("image_height"),
  },
  (table) => {
    return {
      idxSaicId: index("idx_saic_id").on(table.id),
      savedAdImageCreativeImageSha256HashKey: unique(
        "saved_ad_image_creative_image_sha256_hash_key"
      ).on(table.imageSha256Hash),
      savedAdImageCreativeImageUrlKey: unique(
        "saved_ad_image_creative_image_url_key"
      ).on(table.imageUrl),
    };
  }
);

export const savedAdDestinationUrl = pgTable(
  "saved_ad_destination_url",
  {
    id: serial("id").primaryKey().notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    landingPageUrl: text("landing_page_url").notNull(),
  },
  (table) => {
    return {
      savedAdDestinationUrlLandingPageUrlKey: unique(
        "saved_ad_destination_url_landing_page_url_key"
      ).on(table.landingPageUrl),
    };
  }
);

export const adCreativeCombination = pgTable(
  "ad_creative_combination",
  {
    id: serial("id").primaryKey().notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    pageName: text("page_name").notNull(),
    fkImageCreativeId: integer("fk_image_creative_id")
      .references(() => savedAdImageCreative.id)
      .references(() => savedAdImageCreative.id),
    fkVideoCreativeId: integer("fk_video_creative_id")
      .references(() => savedAdVideoCreative.id)
      .references(() => savedAdVideoCreative.id),
    fkAdTextId: integer("fk_ad_text_id")
      .notNull()
      .references(() => savedAdAdText.id)
      .references(() => savedAdAdText.id),
    fkAdHeadlineId: integer("fk_ad_headline_id")
      .notNull()
      .references(() => savedAdAdHeadline.id)
      .references(() => savedAdAdHeadline.id),
    fkAdDescriptionId: integer("fk_ad_description_id")
      .notNull()
      .references(() => savedAdAdDescription.id)
      .references(() => savedAdAdDescription.id),
    fkDisplayUrlId: integer("fk_display_url_id")
      .notNull()
      .references(() => savedAdDisplayUrl.id)
      .references(() => savedAdDisplayUrl.id),
    fkDestinationUrlId: integer("fk_destination_url_id")
      .notNull()
      .references(() => savedAdDestinationUrl.id)
      .references(() => savedAdDestinationUrl.id),
  },
  (table) => {
    return {
      unique: uniqueIndex("ad_creative_combination_unique").on(
        table.pageName,
        table.fkImageCreativeId,
        table.fkVideoCreativeId,
        table.fkAdTextId,
        table.fkAdHeadlineId,
        table.fkAdDescriptionId,
        table.fkDisplayUrlId,
        table.fkDestinationUrlId
      ),
      idxAccId: index("idx_acc_id").on(table.id),
      idxAccFkImageCreativeId: index("idx_acc_fk_image_creative_id").on(
        table.fkImageCreativeId
      ),
      idxAccFkVideoCreativeId: index("idx_acc_fk_video_creative_id").on(
        table.fkVideoCreativeId
      ),
    };
  }
);

export const savedAdVideoCreative = pgTable(
  "saved_ad_video_creative",
  {
    id: serial("id").primaryKey().notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    videoSha256Hash: text("video_sha256_hash").notNull(),
    videoUrl: text("video_url").notNull(),
  },
  (table) => {
    return {
      idxSavcId: index("idx_savc_id").on(table.id),
      savedAdVideoCreativeVideoSha256HashKey: unique(
        "saved_ad_video_creative_video_sha256_hash_key"
      ).on(table.videoSha256Hash),
    };
  }
);

export const boardAds = pgTable(
  "board_ads",
  {
    id: serial("id").primaryKey().notNull(),
    adId: integer("ad_id").references(() => savedAd.id),
    boardId: varchar("board_id", { length: 255 }).references(() => boards.id, {
      onDelete: "cascade",
    }),
  },
  (table) => {
    return {
      boardAdsAdIdBoardIdUnique: unique("board_ads_ad_id_board_id_unique").on(
        table.adId,
        table.boardId
      ),
    };
  }
);

export const userSavedAds = pgTable(
  "user_saved_ads",
  {
    id: serial("id").primaryKey().notNull(),
    userId: text("user_id").notNull(),
    adId: integer("ad_id").references(() => savedAd.id),
  },
  (table) => {
    return {
      userSavedAdsUserIdAdIdUnique: unique(
        "user_saved_ads_user_id_ad_id_unique"
      ).on(table.userId, table.adId),
    };
  }
);

export const boardTemplates = pgTable(
  "board_templates",
  {
    id: serial("id").primaryKey().notNull(),
    boardId: varchar("board_id", { length: 255 }).references(() => boards.id, {
      onDelete: "cascade",
    }),
    templateId: varchar("template_id", { length: 255 }),
  },
  (table) => {
    return {
      boardTemplatesBoardIdTemplateIdUnique: unique(
        "board_templates_board_id_template_id_unique"
      ).on(table.boardId, table.templateId),
    };
  }
);

export const category = pgTable(
  "category",
  {
    name: varchar("name").notNull(),
    id: serial("id").primaryKey().notNull(),
  },
  (table) => {
    return {
      categoryNameKey: unique("category_name_key").on(table.name),
    };
  }
);

export const adCreativeCombinationSavedAdM2M = pgTable(
  "ad_creative_combination_saved_ad_m2m",
  {
    fkSavedAdId: integer("fk_saved_ad_id")
      .notNull()
      .references(() => savedAd.id)
      .references(() => savedAd.id),
    fkAdCreativeCombinationId: integer("fk_ad_creative_combination_id")
      .notNull()
      .references(() => adCreativeCombination.id)
      .references(() => adCreativeCombination.id),
  },
  (table) => {
    return {
      idxAccsFkSavedAdId: index("idx_accs_fk_saved_ad_id").on(
        table.fkSavedAdId
      ),
      idxAccsFkAdCreativeCombinationId: index(
        "idx_accs_fk_ad_creative_combination_id"
      ).on(table.fkAdCreativeCombinationId),
      adCreativeCombinationSavedAdM2MPkey: primaryKey({
        columns: [table.fkSavedAdId, table.fkAdCreativeCombinationId],
        name: "ad_creative_combination_saved_ad_m2m_pkey",
      }),
    };
  }
);

export const usersLikedAds = pgTable(
  "users_liked_ads",
  {
    userId: text("user_id").notNull(),
    likedPostId: text("liked_post_id").notNull(),
  },
  (table) => {
    return {
      userIdLikedPostIdIdx: uniqueIndex(
        "users_liked_ads_user_id_liked_post_id_idx"
      ).on(table.userId, table.likedPostId),
      usersLikedAdsPkey: primaryKey({
        columns: [table.userId, table.likedPostId],
        name: "users_liked_ads_pkey",
      }),
    };
  }
);

export const brandCategory = pgTable(
  "brand_category",
  {
    fkBrandId: varchar("fk_brand_id").notNull(),
    fkCategoryId: integer("fk_category_id").notNull(),
  },
  (table) => {
    return {
      brandCategoryPkey: primaryKey({
        columns: [table.fkBrandId, table.fkCategoryId],
        name: "brand_category_pkey",
      }),
    };
  }
);

export const adImpressions = pgTable(
  "ad_impressions",
  {
    userId: text("user_id").notNull(),
    adId: integer("ad_id").notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    // You can use { mode: "bigint" } if numbers are exceeding js number limitations
    impressions: bigint("impressions", { mode: "number" }).default(1).notNull(),
  },
  (table) => {
    return {
      idxAdImpressionsUserId: index("idx_ad_impressions_user_id").on(
        table.userId
      ),
      adImpressionsPkey: primaryKey({
        columns: [table.userId, table.adId],
        name: "ad_impressions_pkey",
      }),
    };
  }
);

export const requestedTemplates = pgTable("requested_templates", {
  id: serial("id").primaryKey().notNull(),
  adId: integer("ad_id").references(() => savedAd.id),
  memberId: varchar("member_id").notNull(),
  requestedAt: timestamp("created_at", { mode: "string" })
    .defaultNow()
    .notNull(),
});

export type InsertRequestTemplate = typeof requestedTemplates.$inferInsert;
export type RequestedTemplate = typeof requestedTemplates.$inferSelect;

export const rankedAdsMV = pgMaterializedView("mv_ranked_ads", {
  id: integer("id").notNull(),
  imageUrl: text("imageUrl"),
  videoUrl: text("videoUrl"),
  brandId: text("brandId"),
  brandName: varchar("brandName"),
  brandImage: varchar("brandImage"),
  earliestView: timestamp("earliestView", {
    withTimezone: false,
    mode: "string",
  }).notNull(),
  latestLaunch: timestamp("latestLaunch", {
    withTimezone: false,
    mode: "string",
  }).notNull(),
  isActiveSinceLastScrape: boolean("isActiveSinceLastScrape").notNull(),
  adRunningDays: integer("adRunningDays").notNull(),
  inactiveTime: integer("inactiveTime").notNull(),
  brandCount: integer("brandCount"),
  reach: bigint("reach", { mode: "number" }),
  paritionId: bigint("partitionId", { mode: "number" }),
  imageWidth: doublePrecision("imageWidth"),
  imageHeight: doublePrecision("imageHeight"),
  performanceRating: integer("performanceRating"),
  priority: integer("priority"),
}).existing();

export const onboardingBrief = pgEnum("brief", [
  "find-ad-inspiration",
  "find-ad-creative-templates",
  "craft-high-performance-copy",
  "create-new-assets",
]);

export const onboardingCompany = pgEnum("company", [
  "agency",
  "brand",
  "freelance",
  "other",
]);

export const onboardingRole = pgEnum("role", [
  "founder",
  "c-suite",
  "creative-strategist",
  "specialized-consultant",
]);

export const onboardingLead = pgEnum("lead", [
  "social-media",
  "email",
  "word-of-mouth",
  "paid-search",
  "youtube",
  "other",
]);

export const onboarding = pgTable("onboarding", {
  id: serial("id").primaryKey(),
  userId: text("user_id").notNull().unique(),
  hasCompleted: boolean("hasCompleted").default(false),
  brief: onboardingBrief("brief"),
  company: onboardingCompany("company"),
  role: onboardingRole("role"),
  lead: onboardingLead("lead"),
  companyName: varchar("company_name", { length: 255 }),
  createdAt: timestamp("created_at", { mode: "string" }).defaultNow().notNull(),
});

export const createOnboardingSchema = createInsertSchema(onboarding);

export const userCustomerMapping = pgTable("user_customer_mapping", {
  id: serial("id").notNull(),
  chargebeeSynced: boolean("chargebee_synced"),
  userId: text("user_id").primaryKey().unique().notNull(),
  email: text("email"),
  chargebeeSyncedAt: timestamp("chargebee_synced_at", { mode: "string" }),
  workosUserId: varchar("workos_user_id", { length: 255 }).unique(),
  createdAt: timestamp("created_at", { mode: "string" }).defaultNow().notNull(),
  avatar: text("avatar"),
  updateAt: timestamp("updated_at", { mode: "string" })
    .defaultNow()
    .notNull()
    .$onUpdate(() => new Date().toUTCString()),
});
export type UserCustomerMapping = typeof userCustomerMapping.$inferSelect;
export const userCustomerMappingRelations = relations(
  userCustomerMapping,
  ({ many }) => ({
    subscriptions: many(userSubscription),
    subscriptionInvoices: many(subscriptionInvoice),
  })
);

export const userSubscription = pgTable("user_subscription", {
  userId: text("user_id")
    .notNull()
    .references(() => userCustomerMapping.userId, {
      onDelete: "cascade",
    }),
  subscriptionId: text("subscription_id").notNull().unique(),
  startDate: text("start_date"),
  endDate: text("end_date"),
  status: text("status"),
  activatedAt: text("activated_at"),
  createdAt: text("created_at"),
  updatedAt: text("updated_at"),
  id: serial("id").primaryKey().notNull(),
  billingPeriod: integer("billing_period"),
  billingPeriodUnit: text("billing_period_unit"),
  currentTermStart: timestamp("current_term_start", { mode: "string" }),
  currentTermEnd: timestamp("current_term_end", { mode: "string" }),
  currencyCode: text("currency_code"),
  nextBillingAt: timestamp("next_billing_at", { mode: "string" }),
  deleted: boolean("deleted"),
  deletedAt: timestamp("deleted_at", { mode: "string" }),
  trialStart: timestamp("trial_start", { mode: "string" }),
  trialEnd: timestamp("trial_end", { mode: "string" }),
  cancelledAt: timestamp("cancelled_at", { mode: "string" }),
  cancelReason: text("cancel_reason"),
  subscriptionItems: text("subscription_items"),
  plans: text("plans")
    .array()
    .notNull()
    .default(sql`'{}'::text[]`),
});

export const userSubscriptionRelations = relations(
  userSubscription,
  ({ one, many }) => ({
    user: one(userCustomerMapping, {
      fields: [userSubscription.userId],
      references: [userCustomerMapping.userId],
    }),
    invoices: many(subscriptionInvoice),
  })
);

export const subscriptionInvoice = pgTable("subscription_invoice", {
  id: serial("id").notNull(),
  userId: text("user_id")
    .notNull()
    .references(() => userCustomerMapping.userId, {
      onDelete: "cascade",
    }),
  subscriptionId: text("subscription_id")
    .notNull()
    .references(() => userSubscription.subscriptionId, {
      onDelete: "cascade",
    }),
  invoiceId: text("invoice_id").unique().notNull().primaryKey(),
  status: text("status"),
  createdAt: timestamp("created_at", { mode: "string" }).defaultNow().notNull(),
  invoiceData: text("invoice_data"),
  updatedAt: timestamp("updated_at", { mode: "string" }).defaultNow().notNull(),
});

export const subscriptionInvoiceRelations = relations(
  subscriptionInvoice,
  ({ one }) => ({
    user: one(userCustomerMapping, {
      fields: [subscriptionInvoice.userId],
      references: [userCustomerMapping.userId],
    }),
    subscription: one(userSubscription, {
      fields: [subscriptionInvoice.subscriptionId],
      references: [userSubscription.subscriptionId],
    }),
  })
);

export const landers = pgTable(
  "landers",
  {
    id: serial("id").primaryKey().notNull(),
    imageUrl: varchar("image_url").notNull(),
    desktopScreenshot: varchar("desktop_screenshot").notNull(),
    mobileScreenshot: varchar("mobile_screenshot").notNull(),
    extension: imageformat("extension").notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
      .defaultNow()
      .notNull(),
    fk_desktop_screenshot: integer("fk_desktop_screenshot"),
    fk_mobile_screenshot: integer("fk_mobile_screenshot"),
  },
  (table) => {
    return {
      ix_landers_image_url: uniqueIndex("ix_landers_image_url").using(
        "btree",
        table.imageUrl
      ),
    };
  }
);

export const lander_mobile_screeenshot = pgTable(
  "lander_mobile_screeenshot",
  {
    id: serial("id").primaryKey().notNull(),
    image_url: varchar("image_url").notNull(),
    screenshot_url: varchar("screenshot_url").notNull(),
    image_sha256_hash: varchar("image_sha256_hash").notNull(),
  },
  (table) => {
    return {
      ix_lander_mobile_screeenshot_image_sha256_hash: uniqueIndex(
        "ix_lander_mobile_screeenshot_image_sha256_hash"
      ).using("btree", table.image_sha256_hash),
      lander_mobile_screeenshot_image_url_key: unique(
        "lander_mobile_screeenshot_image_url_key"
      ).on(table.image_url),
      lander_mobile_screeenshot_image_sha256_hash_key: unique(
        "lander_mobile_screeenshot_image_sha256_hash_key"
      ).on(table.image_sha256_hash),
    };
  }
);

export const lander_desktop_screenshot = pgTable(
  "lander_desktop_screenshot",
  {
    id: serial("id").primaryKey().notNull(),
    image_url: varchar("image_url").notNull(),
    screenshot_url: varchar("screenshot_url").notNull(),
    image_sha256_hash: varchar("image_sha256_hash").notNull(),
  },
  (table) => {
    return {
      ix_lander_desktop_screenshot_image_sha256_hash: uniqueIndex(
        "ix_lander_desktop_screenshot_image_sha256_hash"
      ).using("btree", table.image_sha256_hash),
      lander_desktop_screenshot_image_url_key: unique(
        "lander_desktop_screenshot_image_url_key"
      ).on(table.image_url),
      lander_desktop_screenshot_image_sha256_hash_key: unique(
        "lander_desktop_screenshot_image_sha256_hash_key"
      ).on(table.image_sha256_hash),
    };
  }
);

export const userSavedLanders = pgTable(
  "user_saved_landers",
  {
    id: serial("id").primaryKey().notNull(),
    userId: text("user_id").notNull(),
    landerId: integer("lander_id")
      .notNull()
      .references(() => landers.id),
  },
  (table) => {
    return {
      userSavedLandersUserIdLanderIdUnique: unique(
        "user_saved_landers_user_id_lander_id_unique"
      ).on(table.userId, table.landerId),
    };
  }
);

export const boardLanders = pgTable(
  "board_landers",
  {
    id: serial("id").primaryKey().notNull(),
    boardId: varchar("board_id", { length: 255 }).references(() => boards.id, {
      onDelete: "cascade",
    }),
    landerId: integer("lander_id").references(() => landers.id),
  },
  (table) => {
    return {
      boardLandersBoardIdLanderIdUnique: unique(
        "board_landers_board_id_lander_id_unique"
      ).on(table.boardId, table.landerId),
    };
  }
);

export const requestedLanders = pgTable("requested_landers", {
  id: serial("id").primaryKey().notNull(),
  landerId: integer("lander_id")
    .references(() => landers.id)
    .notNull(),
  memberId: varchar("member_id").notNull(),
  requestedAt: timestamp("created_at", { mode: "string" })
    .defaultNow()
    .notNull(),
});

export const adTemplateActivities = pgTable("ad_template_activities", {
  id: serial("id").primaryKey().notNull(),
  templateId: varchar("template_id", { length: 255 }).unique().notNull(),
  shares: integer("shares").default(0),
  likes: integer("likes").default(0),
  downloads: integer("downloads").default(0),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at")
    .defaultNow()
    .$onUpdate(() => new Date())
    .notNull(),
});

export const adTemplateDownloads = pgTable("ad_template_downloads", {
  id: serial("id").primaryKey().notNull(),
  templateId: varchar("template_id", { length: 255 }),
  userId: text("user_id").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
});

export const adTemplateLikes = pgTable(
  "ad_template_likes",
  {
    id: serial("id").primaryKey().notNull(),
    templateId: varchar("template_id", { length: 255 }),
    userId: text("user_id").notNull(),
    createdAt: timestamp("created_at").defaultNow().notNull(),
  },
  (table) => {
    return {
      adTemplateLikes_templatedId_userId_unique: unique(
        "ad_template_likes_templated_id_user_id_unique"
      ).on(table.templateId, table.userId),
    };
  }
);

export const adTemplateShares = pgTable("ad_template_shares", {
  id: serial("id").primaryKey().notNull(),
  templateId: varchar("template_id", { length: 255 }),
  userId: text("user_id").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
});

export const emailTemplateActivities = pgTable("email_template_activities", {
  id: serial("id").primaryKey().notNull(),
  templateId: varchar("template_id", { length: 255 }).unique().notNull(),
  downloads: integer("downloads").default(0),
  createdAt: timestamp("created_at").defaultNow().notNull(),

  updatedAt: timestamp("updated_at")
    .defaultNow()
    .$onUpdate(() => new Date())
    .notNull(),
});

export const emailTemplateDownloads = pgTable("email_template_downloads", {
  id: serial("id").primaryKey().notNull(),
  templateId: varchar("template_id", { length: 255 }),
  userId: text("user_id").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
});

export const landerTemplateActivities = pgTable("lander_template_activities", {
  id: serial("id").primaryKey().notNull(),
  templateId: varchar("template_id", { length: 255 }).unique().notNull(),
  downloads: integer("downloads").default(0),
  createdAt: timestamp("created_at").defaultNow().notNull(),

  updatedAt: timestamp("updated_at")
    .defaultNow()
    .$onUpdate(() => new Date())
    .notNull(),
});

export const landerTemplateDownloads = pgTable("lander_template_downloads", {
  id: serial("id").primaryKey().notNull(),
  templateId: varchar("template_id", { length: 255 }),
  userId: text("user_id").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
});

export const brandActvities = pgTable("brand_activities", {
  id: serial("id").primaryKey().notNull(),
  brandId: varchar("brand_id")
    .notNull()
    .unique()
    .references(() => brand.fbPageId, {
      onDelete: "cascade",
    }),
  views: integer("views").default(0),
  createdAt: timestamp("created_at").defaultNow().notNull(),

  updatedAt: timestamp("updated_at")
    .defaultNow()
    .$onUpdate(() => new Date())
    .notNull(),
});

export const brandViews = pgTable("brand_views", {
  id: serial("id").primaryKey().notNull(),
  brandId: varchar("brand_id")
    .notNull()
    .references(() => brand.fbPageId, {
      onDelete: "cascade",
    }),
  createdAt: timestamp("created_at").defaultNow().notNull(),

  userId: text("user_id").notNull(),
});

export const collectionsActivities = pgTable("collections_activities", {
  id: serial("id").primaryKey().notNull(),
  collectionId: varchar("collection_id").notNull().unique(),
  views: integer("views").default(0),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at")
    .defaultNow()
    .$onUpdate(() => new Date())
    .notNull(),
});

export const collectionViews = pgTable("collection_views", {
  id: serial("id").primaryKey().notNull(),
  collectionId: varchar("collection_id").notNull(),
  userId: text("user_id").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
});

export const mvLanders = pgMaterializedView("mv_landers", {
  landerId: integer("lander_id").notNull(),
  landerUrl: text("lander_url").notNull(),
  extension: text("extension").notNull(),
  brandName: text("brand_name"),
  brandImage: text("brand_image"),
  brandId: text("brand_id").notNull(),
  desktopScreenshot: text("desktop_screenshot"),
  mobileScreenshot: text("mobile_screenshot"),
  requestId: integer("request_id"),
  createdAt: timestamp("created_at", {
    mode: "string",
  }).notNull(),
}).existing();
