export const handleDownloadImage = async ({
  imageUrl,
  fileName,
}: {
  imageUrl: string;
  fileName: string;
}) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
};
