export const CustomTestTubeIcon = ({height, width, color}: {height?: string, width?: string, color?: string}) => {
  return (
    <>
      <svg
        width={width ??"24"}
        height={height ?? "24"}
        viewBox={`0 0 24 24`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.80345 11.257C8.92027 11.0897 8.98312 10.8906 8.98355 10.6866L8.99787 3.99786C8.99904 3.44641 9.44641 3 9.99786 3H14.0171C14.5694 3 15.0171 3.44772 15.0171 4V10.6854C15.0171 10.8902 15.08 11.09 15.1972 11.2579L20.9019 19.4275C21.3648 20.0904 20.8905 21 20.082 21H3.91796C3.10946 21 2.63519 20.0904 3.09807 19.4275L8.80345 11.257Z"
          stroke={color ??"#020617"}
          stroke-width="1.5"
        />
        <path
          d="M7 3H17"
          stroke={color ??"#020617"}
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M5 17.25L4.25 17.25L4.25 18.75L5 18.75L5 17.25ZM9 18.75C9.41421 18.75 9.75 18.4142 9.75 18C9.75 17.5858 9.41421 17.25 9 17.25L9 18.75ZM5 18.75L9 18.75L9 17.25L5 17.25L5 18.75Z"
          stroke={color ??"#020617"}
        />
        <path
          d="M7 14.25L6.25 14.25L6.25 15.75L7 15.75L7 14.25ZM10 15.75C10.4142 15.75 10.75 15.4142 10.75 15C10.75 14.5858 10.4142 14.25 10 14.25L10 15.75ZM7 15.75L10 15.75L10 14.25L7 14.25L7 15.75Z"
          stroke={color ??"#020617"}
        />
        <path
          d="M9 11.25L8.25 11.25L8.25 12.75L9 12.75L9 11.25ZM11 12.75C11.4142 12.75 11.75 12.4142 11.75 12C11.75 11.5858 11.4142 11.25 11 11.25L11 12.75ZM9 12.75L11 12.75L11 11.25L9 11.25L9 12.75Z"
          stroke={color ??"#020617"}
        />
      </svg>
    </>
  );
};
