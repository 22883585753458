import { pgTable, serial, integer, varchar, timestamp, unique } from "drizzle-orm/pg-core";
import { boards } from "./boards";

export const boardLandingPageTemplates = pgTable(
    "board_landing_page_templates",
    {
        id: serial("id").primaryKey().notNull(),
        boardId: varchar("board_id", { length: 255 }).references(() => boards.id, {
            onDelete: "cascade",
        }),
        templateId: integer("template_id"),
        createdAt: timestamp("created_at", { mode: "string" }).defaultNow().notNull(),
    },
    (table) => {
        return {
            boardLandingPageTemplateBoardIdUnique: unique(
                "board_landing_page_templates_board_id_unique"
            ).on(table.boardId, table.templateId),
        }
    }
)