import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Button } from "@/components/shadcn/button.tsx";
import { plans } from "@/utils/data/plans.ts";
import { ProtectedArea } from "@/components/protectedArea.tsx";
import { Form } from "@/components/shadcn/form.tsx";
import { RadioGroup, RadioGroupItem } from "@/components/shadcn/radio";
import { FormControl, FormField, FormItem } from "@/components/ui/form.tsx";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import OnboardingSteppers from "@/components/onboarding/OnboardingSteppers.tsx";
import OnboardingHeader from "@/components/onboarding/OnboardingHeader.tsx";

export const Route = createFileRoute("/plans")({
  component: Plans,
});

function Plans() {
  const navigate = useNavigate();

  const selectPlanFormSchema = z.object({
    plan: z.string({ required_error: "Select a plan" }),
  });

  type SelectPlanFormValues = z.infer<typeof selectPlanFormSchema>;

  const defaultValues: Partial<SelectPlanFormValues> = {};

  const form = useForm<SelectPlanFormValues>({
    resolver: zodResolver(selectPlanFormSchema),
    defaultValues,
  });

  async function onSubmit() {
    const selectedPlan = form.getValues("plan");
    if (selectedPlan) {
      localStorage.setItem("cos_plan", selectedPlan); // Store selected plan in localStorage
      setTimeout(() => {
        navigate({ to: "/checkout" });
      }, 500);
    }
  }

  return (
    <ProtectedArea>
      <div
        className={"bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen"}
      >
        <div>
          <OnboardingHeader />
        </div>
        <div
          className={
            "flex-1 flex flex-col justify-center items-center py-[7.75rem]"
          }
        >
          <div
            className={
              "rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border"
            }
          >
            <div className={"flex flex-col items-start gap-6"}>
              <OnboardingSteppers stepIndex={0} />
              <h4
                className={
                  "text-2xl text-center text-themeforeground font-semibold"
                }
              >
                Select your plan
              </h4>
            </div>
            <div className={"grid gap-5"}>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-6"
                >
                  <FormField
                    control={form.control}
                    name="plan"
                    render={({ field }) => (
                      <RadioGroup
                        onValueChange={field.onChange}
                        value={field.value}
                        className="flex flex-col space-y-1"
                      >
                        {plans &&
                          plans.map((item) => (
                            <FormControl key={item.plan_id}>
                              <FormItem>
                                <RadioGroupItem
                                  checked={field.value === item.plan_id}
                                  value={item.plan_id}
                                  onChange={() => field.onChange(item.plan_id)}
                                  id={item.name}
                                  plan={item}
                                >
                                  {item.name}
                                </RadioGroupItem>
                              </FormItem>
                            </FormControl>
                          ))}
                      </RadioGroup>
                    )}
                  />
                  <div className={"flex justify-end items-center space-x-2"}>
                    <Button
                      type={"submit"}
                      className={"px-8"}
                      variant={"default"}
                      size={"lg"}
                    >
                      {"Next"}
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </ProtectedArea>
  );
}
