import { createFileRoute } from "@tanstack/react-router";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs";
import { LandingAdCard } from "@/components/templates/LandingAdCard";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/saved/emails")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const {
    data: savedEmailTemplates,
    isLoading: loadingEmailTemplates,
    isError: emailError,
    isRefetching: fetchingEmailTemplates,
  } = trpc.getAllSavedEmailTemplates.useQuery(undefined, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  if (emailError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (
    loadingEmailTemplates ||
    fetchingEmailTemplates
  ) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-8">
      <Stack className="gap-3 lg:gap-8">
        <Text weight="semibold" size={"xxl"}>
          All Saved Ad Inspiration
        </Text>
        <FeatureTabs
          showPro={false}
          tabItems={[
            {
              name: "Ads",
              link: "/feeds/saved/ads",
            },
            {
              name: "Landers",
              link: "/feeds/saved/ad-landers",
            },
            {
              name: "Emails",
              link: "/feeds/saved/emails",
            },
          ]}
        />
      </Stack>

      <div className={""}>
        {(savedEmailTemplates ?? []).length == 0 ? (
          <div className={"h-[80vh] flex justify-center items-center"}>
            <p className={"text-center"}>
              You do not have any email templates saved
            </p>
          </div>
        ) : (
          <div
            className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2.5"}
          >
            {savedEmailTemplates &&
              savedEmailTemplates.map((card) => (
                <LandingAdCard
                  key={card["Email ID"]}
                  adData={{
                    Screenshot: card["Email Screenshot"],
                    ID: `${card["Email ID"]}`,
                    created: card.Created,
                    category: card.Category,
                    isSaved: card.isSaved,
                  }}
                  showTemplateButton
                  type={"email"}
                />
              ))}
          </div>
        )}
      </div>
    </Stack>
  );
}
