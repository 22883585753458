import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { z } from "zod";
import {
  orderFilter as OrderFilterType,
  Tags,
} from "../../../shared/airtableGet.ts";

import { trpc } from "@/utils/trpc.ts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorDisplay } from "@/components/error.tsx";
import {
  GenericCardProps,
  LandingAdGridView,
} from "@/components/templates/LandingAdGridView";
import { Loader } from "@/components/custom-components/Loader";
import { Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { FilterOption } from "./feeds.templates.index.tsx";
import TemplatesFilterPopover, {
  AccordionData,
} from "@/components/custom-components/TemplateFilterPopover/index.tsx";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import { TrendingUp } from "@mynaui/icons-react";

type SearchParams = {
  Tags?: z.infer<typeof Tags>;
  cacheBuster: number;
  sideBarOpen?: boolean;
  categories?: string;
  orderFilter?: z.infer<typeof OrderFilterType>;
};
export type SelectedTemplateFilters = {
  categories?: string[];
};
export const Route = createFileRoute("/feeds/templates/emails/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const cacheBuster = (search?.cacheBuster as number) ?? Math.random();
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      cacheBuster: cacheBuster,
      sideBarOpen,
      orderFilter,
    };
  },
});

function All() {
  const {
    cacheBuster,
    orderFilter,
    categories: queryCategories,
  } = Route.useSearch();
  const navigate = useNavigate();

  const { data: newlyAddedTemplateCount } =
    trpc.getNewlyAddedTemplatesCount.useQuery(
      { templatesType: "email", daysToCheck: 30 },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    );
  // Get the template collections to be passed to the filter
  const { data: templateCategories } =
    trpc.getEmailTemplateCategories.useQuery();
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedTemplateFilters>({
      categories: queryCategories ? queryCategories.split(",") : undefined,
    });
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const updateQueryString = useCallback(
    (params: { categories?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.categories) searchParams.set("categories", params.categories);

      navigate({
        to: "/feeds/templates/emails",
        replace: true,
        search: (old) => {
          return {
            ...old,
            ...params,
            orderFilter,
            cacheBuster: Math.random(),
          };
        },
      });
    },
    [navigate],
  );

  const handleOptionsChange = useCallback(
    (options: AccordionData[]) => {
      const selectedCategories: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Category") selectedCategories.push(item.label);
          }
        });
      });

      setSelectedFilters({
        categories:
          selectedCategories.length > 0 ? selectedCategories : undefined,
      });

      updateQueryString({
        categories:
          selectedCategories.length > 0
            ? selectedCategories.join(",")
            : undefined,
      });
    },
    [updateQueryString],
  );

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => {
      const updatedOptionItems = option.optionItems.map((item) => ({
        ...item,
        value:
          option.title === "Category" &&
          selectedFilters.categories?.includes(item.label),
      }));

      return {
        ...option,
        optionItems: updatedOptionItems,
      };
    });

    setFilterOptions(updatedOptions as FilterOption[]);
  }, [filterOptions, selectedFilters]);

  useEffect(() => {
    if (templateCategories) {
      setFilterOptions([
        {
          title: "Category",
          counter: 0,
          optionItems: templateCategories.map((i) => ({
            label: i.category,
            value: false,
          })),
        },
      ]);
    }
  }, [templateCategories]);

  const {
    data: emailTemplates,
    isLoading,
    error,
  } = trpc.emails.useQuery(
    {
      cacheBuster: cacheBuster,
      sortingOptions: orderFilter,
      categories: selectedFilters.categories,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const formatData = useMemo((): GenericCardProps[] => {
    if (!emailTemplates) return [];
    const returnData = [] as GenericCardProps[];
    emailTemplates.Emails?.map((datum) => {
      returnData.push({
        Screenshot: datum["Email Screenshot"],
        ID: datum["Email ID"].toString(),
        created: datum.Created,
        type: "email",
        category: datum.Category,
        isSaved: datum.isSaved,
      });
    });
    return returnData;
  }, [emailTemplates]);

  useEffect(() => {
    if (!isLoadingPermission && !permissionData?.userCanAccessEverything) {
      setUpgradeDialogOpen(true);
    }
  }, [permissionData?.userCanAccessEverything, isLoadingPermission]);

  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <FeatureTabs
            tabItems={[
              {
                name: "Ads",
                link: "/feeds/templates",
              },
              {
                name: "Emails",
                link: "/feeds/templates/emails",
                isPremiumFeature: true,
              },
              {
                name: "Landers",
                link: "/feeds/templates/landing-pages",
                isPremiumFeature: true,
              },
            ]}
          />
          <Stack className="gap-3 lg:gap-6">
            <div className={"flex justify-between gap-5 items-center"}>
              <Text weight="semibold" size={"xxl"} className="w-fit">
                Email Templates
              </Text>

              <div className={"flex gap-2 items-center flex-wrap justify-end"}>
                {(newlyAddedTemplateCount ?? 0) > 0 && (
                  <div className="flex gap-2 w-fit items-center">
                    <TrendingUp className="text-themedestructive" />
                    <Text
                      size={"sm"}
                      weight={"medium"}
                      className="text-thememutedforeground "
                    >
                      {newlyAddedTemplateCount} New{" "}
                      {newlyAddedTemplateCount === 1 ? "Template" : "Templates"}
                    </Text>
                  </div>
                )}
                <div className="lg:hidden">
                  <OrderFilterSelect
                    defaultFilter={orderFilter || "Random"}
                    options={["Random", "Recent", "Popular", "Oldest"]}
                  />
                </div>
              </div>
            </div>
            <div
              className={`flex ${filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center`}
            >
              <div>
                {filterOptions && filterOptions.length > 0 && (
                  <TemplatesFilterPopover
                    initialOptions={filterOptions}
                    onOptionsChange={handleOptionsChange}
                    placeholder={"Filter Ad Templates"}
                  />
                )}
              </div>

              <div className={"hidden lg:flex"}>
                <OrderFilterSelect
                  defaultFilter={orderFilter || "Random"}
                  options={["Random", "Recent", "Popular", "Oldest"]}
                />
              </div>
            </div>
          </Stack>
        </Stack>

        {isLoading ? (
          <div
            className={
              "flex justify-center items-center w-full h-screen m-auto"
            }
          >
            <Loader />
          </div>
        ) : error ? (
          <ErrorDisplay />
        ) : (
          <LandingAdGridView
            Cards={formatData}
            HasAccess={emailTemplates ? emailTemplates.HasAccess : false}
            type={"email"}
          />
        )}
      </Stack>
    </>
  );
}
