import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App.tsx";
import "./index.css";

import * as Sentry from "@sentry/react";

if (import.meta.env.PROD || import.meta.env.MODE === "staging") {
  Sentry.init({
    dsn: "https://76b8f4994a4cf36b4ce3b5edc8797646@o4506757010227200.ingest.sentry.io/4506796685983744",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: import.meta.env.MODE,
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /.*api\.creativeos.*/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const user = localStorage && localStorage.getItem("_wos_user");

  if (user) {
    Sentry.setUser({ email: JSON.parse(user).email });
  }
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
