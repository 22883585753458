import { createFileRoute } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form";
import { Button } from "@/components/shadcn/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Label } from "@/components/shadcn/label.tsx";
import { ArrowLeftIcon } from "lucide-react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/shadcn/input-otp";
import { REGEXP_ONLY_DIGITS } from "input-otp";
import OnboardingHeader from "@/components/onboarding/OnboardingHeader.tsx";
import { trpc } from "@/utils/trpc.ts";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import showToastNotification from "@/hooks/useShowToast";

export const Route = createFileRoute("/verify-email")({
  component: VerifyEmail,
});

function VerifyEmail() {
  const [timer, setTimer] = useState<number>(0); // Timer state (in seconds)

  // Function to start the timer when the resend code action is triggered
  const startTimer = () => {
    setTimer(180); // 3 minutes (180 seconds)
  };

  // Countdown effect to decrease timer every second
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown); // Cleanup on unmount
    }
  }, [timer]);

  const navigate = useNavigate();

  const { mutate, isPending } = trpc.verifyUserCode.useMutation();
  const cosPlan = localStorage.getItem("cos_plan");

  const { mutate: resendCode, isPending: isResendCodePending } =
    trpc.sendUserVerificationCode.useMutation();

  const {
    isLoading: authIsLoading,
    isRefetching,
    refetch: refetchUserDetails,
  } = trpc.me.useQuery(null, { enabled: false });

  const storedUser = localStorage.getItem("_wos_user");

  const handleResendCode = () => {
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        resendCode({ userId: parsedUser?.id });

        // Start the 3-minute timer after sending the code
        startTimer();
      } catch (error) {
        console.error("No user ID found:", error);
      }
    } else {
      console.error("No user ID found");
    }
  };

  const verifyEmailFormSchema = z.object({
    otp: z.string({ required_error: "OTP is required" }),
  });

  type VerifyEmailFormValues = z.infer<typeof verifyEmailFormSchema>;

  const defaultValues: Partial<VerifyEmailFormValues> = {};

  const form = useForm<VerifyEmailFormValues>({
    resolver: zodResolver(verifyEmailFormSchema),
    defaultValues,
  });

  async function onSubmit({ otp }: VerifyEmailFormValues) {
    mutate(
      { code: otp },
      {
        onSuccess: (data) => {
          if (data && data.emailVerified) {
            refetchUserDetails().then((authUser) => {
              if (
                typeof localStorage !== "undefined" &&
                !authIsLoading &&
                !isRefetching
              ) {
                localStorage.setItem(
                  "_wos_user",
                  JSON.stringify(authUser.data),
                );
              }

              showToastNotification("success", {
                message: "Verification successful",
              });
              if (cosPlan) {
                navigate({ to: "/checkout" });
              } else navigate({ to: "/plans" });
            });
          }
        },
        onError: (error) => {
          showToastNotification("error", {
            message: error.message,
            description:
              "Please check the 6-digit code and try again. If you didn’t receive the code, request a new one.",
          });
        },
      },
    );
  }

  return (
    <div
      className={"bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen"}
    >
      <div>
        <OnboardingHeader />
      </div>
      <div
        className={
          "flex-1 flex flex-col justify-center items-center py-[7.75rem]"
        }
      >
        <div
          className={
            "rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border"
          }
        >
          <div className={"flex flex-col items-start gap-2"}>
            <Button
              onClick={() => navigate({ to: "/login" })}
              variant={"ghost"}
              className={"flex gap-1.5 items-center"}
            >
              <ArrowLeftIcon size={"1rem"} />
              <span>Back</span>
            </Button>
            <h4
              className={
                "text-2xl text-center text-themeforeground font-semibold"
              }
            >
              Verify your email address
            </h4>
            <p>
              We sent a verification code to{" "}
              <span className={"font-medium"}>
                {storedUser && JSON.parse(storedUser).email}
              </span>
              . This code is valid for 10 minutes.
            </p>
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className={"flex flex-col gap-6"}>
                <FormField
                  control={form.control}
                  name="otp"
                  render={({ field }) => (
                    <FormItem>
                      <Label>6-Digit Code</Label>
                      <FormControl>
                        <InputOTP
                          {...field}
                          maxLength={6}
                          pattern={REGEXP_ONLY_DIGITS}
                        >
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <Button disabled={isPending} type={"submit"} className="w-full">
                  {isPending ? "Submitting..." : "Verify Email"}
                </Button>
                <div>
                  <div className={"block text-center mt-1.5 text-sm"}>
                    <span>Didn’t receive an email?</span>{" "}
                    <Button
                      variant={"link"}
                      disabled={isResendCodePending || timer > 0}
                      onClick={timer === 0 ? handleResendCode : undefined}
                      className={"underline font-medium p-0"}
                    >
                      {timer > 0
                        ? `Resend in ${Math.floor(timer / 60)}:${("0" + (timer % 60)).slice(-2)}`
                        : "Resend Code"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
