import { InferSelectModel } from "drizzle-orm";
import { integer, pgTable, serial, timestamp, varchar } from "drizzle-orm/pg-core";

export const userSavedEmailTemplates = pgTable("user_saved_email_templates", {
    id: serial("id").primaryKey().notNull(),
    userId: varchar("user_id", { length: 255 }).notNull(),
    emailTemplateId: integer("email_template_id").notNull(),
    createdAt: timestamp("created_at", { mode: "string" })
        .defaultNow()
        .notNull(),
});

export type SelectUserSavedEmailTemplate = InferSelectModel<typeof userSavedEmailTemplates>