import { createFileRoute, Link } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { Stack, Text } from "@/components/custom-components";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { BoardDialog } from "@/components/templates/Sidebar";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs";
import { Badge } from "@/components/shadcn/badge";
import { UserWaves, Plus } from "@mynaui/icons-react";
import { Button } from "@/components/shadcn/button";
import { LockTagSmall } from "@/components/custom-components/LockTag";
import CollectionCard from "@/components/custom-components/CollectionCard";

type BoardCollectionDataProps = {
  collections: CollectionDataProps[];
  scrollRef?: (node?: Element | null | undefined) => void;
};

type CollectionDataProps = {
  atID: string;
  Title: string;
  "Ad Templates": string[];
  adCount: number;
  images?: {
    id: string;
    width: number;
    height: number;
    url: string;
    filename: string;
    size: number;
    type: string;
    thumbnails: {
      small: {
        url: string;
        width: number;
        height: number;
      };
      large: {
        url: string;
        width: number;
        height: number;
      };
      full: {
        url: string;
        width: number;
        height: number;
      };
    };
  }[];
};

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/collections/creativeos/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);
  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const [allData, setAllData] = useState<CollectionDataProps[] | undefined>(
    undefined
  );

  const { data, fetchNextPage, isLoading, isError, isRefetching } =
    trpc.getCollectionsWithImages.useInfiniteQuery(
      { limit: 10 },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (!inView || !data || !allData || !allData.length) return;
    if (data.pages[data.pages.length - 1].collections.length === 0) {
      return;
    }
    fetchNextPage();
  }, [inView, data, allData, fetchNextPage]);

  useEffect(() => {
    if (!data) return;

    setAllData(() => undefined);
    const records = [] as CollectionDataProps[];
    for (const page of data.pages) {
      records.push(...page.collections);
    }
    setAllData(() => records);
  }, [data]);

  if (isError) {
    return <ErrorDisplay />;
  }

  if (isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
        />
      )}
      <Stack className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <FeatureTabs
            defaultOrderValue={"Random"}
            useFirst2PathnamesOnly
            tabItems={[
              {
                name: "Curated",
                link: !(permissionData?.userCanAccessEverything ?? false)
                  ? "/feeds/collections/creativeos"
                  : "/feeds/collections",
              },
              {
                name: "Live Trends",
                link: "/feeds/live-trends",
              },
            ]}
          />
          <Stack className="gap-3 lg:gap-6">
            <Stack className="gap-3 lg:gap-2">
              <div className="gap-2 flex items-center justify-between">
                <Text weight="semibold" size={"xxl"}>
                  Collections
                </Text>

                <Text
                  weight="normal"
                  size={"base"}
                  className="text-thememutedforeground md:hidden max-w-fit"
                >
                  {(allData ?? []).length}{" "}
                  {(allData ?? []).length > 1 ? "Collections" : "Collection"}
                </Text>
              </div>
              <Text
                weight="normal"
                size={"lg"}
                className="text-thememutedforeground hidden lg:flex"
              >
                Carefully crafted collections by the Creative OS team and our
                network of industry leaders.
              </Text>
            </Stack>

            <div className={"flex justify-between gap-6 items-center"}>
              <div className="flex gap-2 items-center">
                {[
                  ...(permissionData?.userCanAccessEverything
                    ? [
                        {
                          name: "All",
                          link: "/feeds/collections",
                          selected: false,
                        },
                      ]
                    : []),
                  {
                    name: "by CreativeOS",
                    link: "/feeds/collections/creativeos",
                    icon: (
                      <img
                        alt={""}
                        src={"/bg_gradient.jpg"}
                        className={"rounded-full shrink-0 bg-[#A259FF] w-4 h-4"}
                      />
                    ),
                    selected: true,
                  },
                  {
                    name: "Experts",
                    link: "/feeds/collections/experts",
                    icon: <UserWaves className="w-4 h-4" />,
                    isPremium: true,
                  },
                ].map((item) => (
                  <Link
                    to={item.link}
                    search={(d) => ({
                      ...d,
                      cacheBuster: Math.random(),
                      orderFilter: "Random",
                      sideBarOpen: false,
                    })}
                    activeOptions={{
                      exact: true,
                      includeSearch: false,
                    }}
                    key={item.name}
                  >
                    <Badge
                      variant={item.selected ? "default" : "outline"}
                      className="gap-1 cursor-pointer items-center"
                    >
                      {item.icon}
                      {item.name}
                      {item.isPremium && (
                        <>
                          {!isLoadingPermission &&
                            !permissionData?.userCanAccessEverything && (
                              <div className="opacity-50">
                                <LockTagSmall />
                              </div>
                            )}
                        </>
                      )}
                    </Badge>
                  </Link>
                ))}
              </div>

              <div className="gap-2 items-center hidden md:flex">
                <Button
                  onClick={() => setCreateBoardDialogOpen(true)}
                  size={"sm"}
                  variant={"ghost"}
                >
                  Create Your Own <Plus className="w-5 h-5" />
                </Button>
                <Text
                  weight="normal"
                  size={"base"}
                  className="text-thememutedforeground"
                >
                  {(allData ?? []).length}
                  {(allData ?? []).length > 1 ? " Collections" : " Collection"}
                </Text>
              </div>
            </div>
          </Stack>
        </Stack>
        {data && allData && allData.length > 0 && (
          <CollectionGridView collections={allData} scrollRef={scrollRef} />
        )}
      </Stack>
    </>
  );
}

const CollectionGridView = (props: BoardCollectionDataProps) => {
  return (
    <div className={"grid lg:grid-cols-2 gap-6 mb-10"}>
      {props.collections && props.collections.length > 0
        ? props.collections.map((item) => (
            <CollectionItem key={item.atID} data={item} />
          ))
        : props.collections.length === 0 && (
            <div
              className={
                "h-full w-full lg:col-span-2 flex justify-center items-center"
              }
            >
              <p>No collections created</p>
            </div>
          )}
      <div className={"relative"}>
        <div
          className={
            "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
          }
          ref={props.scrollRef}
        ></div>
      </div>{" "}
      <div className={"relative"}>
        <div
          className={
            "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
          }
          ref={props.scrollRef}
        ></div>
      </div>
    </div>
  );
};

const CollectionItem = ({ data }: { data: CollectionDataProps }) => {
  return (
    <Link
      to={"/feeds/collections/$collectionID"}
      params={{ collectionID: data.atID }}
    >
      <Stack className="gap-3">
        <CollectionCard
          videoUrls={[]}
          imageUrls={
            data?.images?.map((image) => image.thumbnails.large.url) ?? []
          }
        />

        <div className="flex justify-between">
          <Stack className="gap-1 w-full">
            <h4
              className={`truncate font-semibold text-sm leading-5 text-themeforeground`}
            >
              {data.Title}
            </h4>

            <div className={`flex gap-1 items-center`}>
              <img
                src={"/images/emblam.png"}
                alt={"CreativeOs"}
                className={`bg-[#BCBCBC] rounded-full w-6 h-6 object-contain`}
              />

              <Text
                weight={"normal"}
                size={"xs"}
                className={`text-thememutedforeground`}
              >
                by Creative OS
              </Text>
            </div>
          </Stack>
          <Text
            className="text-thememutedforeground w-fit text-nowrap"
            size={"xs"}
            weight={"normal"}
          >
            {data.adCount} {data.adCount > 1 ? " Items" : " Item"}
          </Text>
        </div>
      </Stack>
    </Link>
  );
};
