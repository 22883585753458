import { forwardRef, useState, FormEvent, useEffect } from "react";
import { X } from "@mynaui/icons-react";
import { SelectedTemplateFilters } from "@/routes/feeds.templates.index.tsx";
import { ChevronUpDown } from "@mynaui/icons-react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/shadcn/dropdown-menu";
import { Check } from "@mynaui/icons-react";
import { Badge } from "@/components/shadcn/badge";
import { Button } from "@/components/shadcn/button";
import { ScrollArea, ScrollBar } from "@/components/shadcn/scroll-area";
import { AdSelectedFilters } from "@/hooks/useFilterFeed.tsx";

type OptionItem = {
  label: string;
  value: boolean;
};

export type AccordionData = {
  title: string;
  counter: number;
  optionItems: OptionItem[];
};

type TemplatesFilterPopoverProps = {
  className?: string;
  initialOptions: AccordionData[];
  onOptionsChange?: (options: AccordionData[]) => void;
  placeholder: string;
};

export const TemplatesFilterPopover = forwardRef<
  HTMLButtonElement,
  TemplatesFilterPopoverProps
>(({ initialOptions, onOptionsChange }, ref) => {
  const [options, setOptions] = useState<AccordionData[]>(initialOptions);

  useEffect(() => {
    if (onOptionsChange) onOptionsChange(options);
  }, [options, onOptionsChange]);

  const handleCheckboxChange = (
    accordionIndex: number,
    optionIndex: number,
    checked: boolean | FormEvent<HTMLButtonElement>
  ) => {
    let isChecked: boolean;

    if (typeof checked === "boolean") {
      isChecked = checked;
    } else {
      isChecked = checked.currentTarget.getAttribute("aria-checked") === "true";
      isChecked = !isChecked;
    }

    setOptions((prevOptions) =>
      prevOptions.map((group, index) => {
        if (index === accordionIndex) {
          const newOptionItems = group.optionItems.map((item, idx) => {
            if (idx === optionIndex) {
              return { ...item, value: isChecked };
            }
            return item;
          });
          return { ...group, optionItems: newOptionItems };
        }
        return group;
      })
    );
  };

  const handleRemoveFilter = (filterType: string, filterValue: string) => {
    setOptions((prevOptions) =>
      prevOptions.map((group) => {
        if (group.title.toLowerCase() === filterType) {
          const newOptionItems = group.optionItems.map((item) => {
            if (item.label === filterValue) {
              return { ...item, value: false };
            }
            return item;
          });
          return { ...group, optionItems: newOptionItems };
        }
        return group;
      })
    );
  };

  const handleClearFilters = () => {
    setOptions((prevOptions) =>
      prevOptions.map((group) => ({
        ...group,
        optionItems: group.optionItems.map((item) => ({
          ...item,
          value: false,
        })),
      }))
    );
  };

  const selectedFilters = options.reduce(
    (acc, group) => {
      const selectedItems = group.optionItems.filter((item) => item.value);
      if (selectedItems.length > 0) {
        acc[group.title.toLowerCase()] = selectedItems.map(
          (item) => item.label
        );
      }
      return acc;
    },
    {} as Record<string, string[]>
  );

  const top5Collections =
    options.length > 0 ? options[0].optionItems.slice(0, 5) : [];

  return (
    <>
      <div className="hidden lg:flex gap-2 items-center flex-wrap">
        {...SelectedFiltersBadges({
          selectedFilters,
          onRemoveFilter: handleRemoveFilter,
          onClearFilter: handleClearFilters,
          top5Filters: top5Collections.map((item) => item.label),
        })}
        {top5Collections.map((option, idx) =>
          option.value ? (
            <Badge
              key={idx}
              className="gap-2 bg-purple-100 items-center hover:bg-purple-100"
            >
              <span
                className={
                  "text-sm text-nowrap text-themedestructive font-medium leading-5"
                }
              >
                {option.label
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </span>
              <button
                onClick={() => handleCheckboxChange(0, idx, !option.value)}
              >
                <X className="text-themedestructive w-3 h-3" />
              </button>
            </Badge>
          ) : (
            <Button
              key={idx}
              variant={"outline"}
              className={`flex items-center gap-1 rounded-full px-3.5 !h-7`}
              onClick={() => handleCheckboxChange(0, idx, !option.value)}
            >
              {option.label
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </Button>
          )
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            {options.some((item) => item.optionItems.slice(5).length > 0) && (
              <Button
                ref={ref}
                variant={"outline"}
                className={`flex items-center gap-1 rounded-full px-3.5 !h-7`}
              >
                <ChevronUpDown className="h-5 w-4" />
                More
              </Button>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className={` border-none grid grid-cols-${options.length} max-h-96 overflow-y-auto`}
          >
            {options.map((item, index) => (
              <DropdownMenuGroup key={item.title} className=" grid grid-cols-2">
                {(index == 0
                  ? item.optionItems.slice(5)
                  : item.optionItems
                ).map((option, idx) => (
                  <DropdownMenuItem
                    key={idx}
                    onClick={() =>
                      handleCheckboxChange(
                        index,
                        idx + top5Collections.length,
                        !option.value
                      )
                    }
                  >
                    <div className="w-4 h-4">
                      {option.value && <Check className="w-4 h-4" />}
                    </div>
                    <div className="max-w-52 w-52">
                      {option.label
                        .split("_")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </div>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuGroup>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <ScrollArea
        type="always"
        className="lg:hidden flex  w-[calc(100vw-24px)] flex-1"
      >
        <div className="flex gap-2 items-center w-full">
          {...SelectedFiltersBadges({
            selectedFilters,
            onRemoveFilter: handleRemoveFilter,
            onClearFilter: handleClearFilters,
            top5Filters: top5Collections.map((item) => item.label),
          })}
          {top5Collections.map((option, idx) =>
            option.value ? (
              <Badge
                key={idx}
                className="gap-2 bg-purple-100 items-center hover:bg-purple-100"
              >
                <span
                  className={
                    "text-sm text-nowrap text-themedestructive font-medium leading-5"
                  }
                >
                  {option.label
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </span>
                <button
                  onClick={() => handleCheckboxChange(0, idx, !option.value)}
                >
                  <X className="text-themedestructive w-3 h-3" />
                </button>
              </Badge>
            ) : (
              <Button
                key={idx}
                variant={"outline"}
                className={`flex items-center gap-1 rounded-full px-3.5 !h-7`}
                onClick={() => handleCheckboxChange(0, idx, !option.value)}
              >
                {option.label
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </Button>
            )
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              {options.some((item) => item.optionItems.slice(5).length > 0) && (
                <Button
                  ref={ref}
                  variant={"outline"}
                  className={`flex items-center gap-1 rounded-full px-3.5 !h-7`}
                >
                  <ChevronUpDown className="h-5 w-4" />
                  More
                </Button>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className={` border-none grid grid-cols-${options.length} max-h-96 overflow-y-auto`}
            >
              {options.map((item, index) => (
                <DropdownMenuGroup
                  key={item.title}
                  className=" grid grid-cols-2"
                >
                  {(index == 0
                    ? item.optionItems.slice(5)
                    : item.optionItems
                  ).map((option, idx) => (
                    <DropdownMenuItem
                      key={idx}
                      onClick={() =>
                        handleCheckboxChange(
                          index,
                          idx + top5Collections.length,
                          !option.value
                        )
                      }
                    >
                      <div className="w-4 h-4">
                        {option.value && <Check className="w-4 h-4" />}
                      </div>
                      <div className="max-w-52 w-52">
                        {option.label
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </div>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuGroup>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <ScrollBar orientation="horizontal" className="w-full hidden" />
      </ScrollArea>
    </>
  );
});

export default TemplatesFilterPopover;

type SelectedFiltersBadgesProps = {
  selectedFilters: AdSelectedFilters | SelectedTemplateFilters;
  onRemoveFilter: (filterType: string, filterValue: string) => void;
  onClearFilter: () => void;
  top5Filters: string[];
};

const SelectedFiltersBadges = ({
  selectedFilters,
  onRemoveFilter,
  top5Filters,
}: SelectedFiltersBadgesProps) => {
  const renderBadges = (filters: string[], filterType: string) => {
    return filters
      .filter((filterItem) => !top5Filters.includes(filterItem))
      .map((filter, index) => (
        <Badge
          key={index}
          className="gap-2 bg-purple-100 items-center hover:bg-purple-100 "
        >
          <span
            className={
              "text-sm text-nowrap text-themedestructive font-medium leading-5"
            }
          >
            {filter
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </span>
          <button onClick={() => onRemoveFilter(filterType, filter)}>
            <X className="text-themedestructive w-3 h-3" />
          </button>
        </Badge>
      ));
  };
  return Object.entries(selectedFilters).map(([filterType, filters]) =>
    renderBadges(filters, filterType)
  );
};

