import { createFileRoute } from "@tanstack/react-router";

import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import {
  BrandDataProps,
  BrandsGridView,
} from "@/components/templates/BrandsGridView";
import { Loader } from "@/components/custom-components/Loader";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "@/components/shadcn/form.tsx";
import { Search } from "@mynaui/icons-react";
import { Input } from "@/components/shadcn/input.tsx";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Stack, Text } from "@/components/custom-components";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/brands/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const { data: brands, isLoading, error } = trpc.getBrands.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (brands) setFilteredBrands(brands);
  }, [brands]);

  const [filteredBrands, setFilteredBrands] = useState<BrandDataProps[]>([]);

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-6">
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-center"
            }
          >
            <Text weight="semibold" size={"xxl"}>
              Brands
            </Text>
            <div className={"lg:flex hidden"}>
              {brands && (
                <BrandSearchForm
                  data={brands}
                  setSearchResults={setFilteredBrands}
                />
              )}
            </div>
          </div>
          <div className={"flex lg:hidden w-full"}>
              {brands && (
                <BrandSearchForm
                  data={brands}
                  setSearchResults={setFilteredBrands}
                />
              )}
            </div>
        </Stack>
      </Stack>
      {brands && filteredBrands && <BrandsGridView data={filteredBrands} />}
    </Stack>
  );
}

export default function BrandSearchForm({
  data,
  setSearchResults,
}: {
  data: BrandDataProps[];
  setSearchResults: (data: BrandDataProps[]) => void;
}) {
  const searchFormSchema = z.object({
    brand: z.string({ required_error: "Brand is required" }),
  });

  type SearchFormValues = z.infer<typeof searchFormSchema>;

  const defaultValues: Partial<SearchFormValues> = {};

  const form = useForm<SearchFormValues>({
    resolver: zodResolver(searchFormSchema),
    defaultValues,
  });

  const handleSearch = (e: FormEvent) => e.preventDefault();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return setSearchResults(data);
    const results = data.filter((item) =>
      item.brandName?.toLowerCase().includes(e.target?.value)
    );
    setSearchResults(results);
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSearch}>
        <FormField
          name="search"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative">
                  {/* Search Icon positioned on the left */}
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <Search
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                  <Input
                    type={"text"}
                    {...field}
                    onChange={handleSearchChange}
                    placeholder="Brand Name"
                    className="pl-10 lg:w-80 w-[calc(100vw-24px)]" // Padding left to make space for the icon
                  />
                </div>
              </FormControl>
            </FormItem>
          )}
        ></FormField>
      </form>
    </Form>
  );
}
