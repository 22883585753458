import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/feeds/saved/")({
  component: All,
});

function All() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate({ to: "/feeds/saved/ads" });
  }, []);
  return <div />;
}
