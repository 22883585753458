import React from "react";

type props = {
  children: React.ReactNode;
  textToCopy: string;
  onCopy?: () => void;
};

export const CopyToClipboardHTML = ({
  children,
  textToCopy,
  // onCopy,
}: props) => {
  const copyToClipboard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    document.addEventListener("copy", (event: ClipboardEvent) => {
      event.clipboardData?.setData("text/html", textToCopy);
      event.preventDefault();
    });
    if (!document.execCommand("copy")) {
      // Fallback for Safari
      const textarea = document.createElement("textarea");
      textarea.textContent = textToCopy;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        window.alert("Copy to clipboard failed, please contact support");
        console.warn("Copy to clipboard failed.", ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
    // onCopy();
  };

  return <div onClick={copyToClipboard}>{children}</div>;
};

export const copyToClipboard = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch: () => Promise<any>,
): Promise<boolean> => {
  // Check if the browser is Safari
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (
    isSafari &&
    typeof ClipboardItem !== "undefined" &&
    navigator.clipboard &&
    navigator.clipboard.write
  ) {
    // Safari-specific workaround using ClipboardItem
    try {
      const makeBlobPromise = async () => {
        const response = await refetch();
        const blob = response.data;
        return blob;
      };

      await navigator.clipboard
        .write([
          new ClipboardItem({
            "text/html": makeBlobPromise(),
          }),
        ])
        .then(() => {
          console.log("Copied to clipboard using ClipboardItem (Safari)");
        })
        .catch((error) => {
          console.error("Error in Safari clipboard handling (write)", error);
          return Promise.resolve(false);
        });
      // Immediately return true since the command was attempted
      return Promise.resolve(true);
    } catch (error) {
      console.error("Error in Safari clipboard handling", error);
      return Promise.resolve(false);
    }
  } else {
    const textToCopy = await refetch().then((res) => res.data);
    // Function to handle the copy event for non-Safari browsers
    const copyEvent = async (event: ClipboardEvent) => {
      event.clipboardData?.setData("text/html", textToCopy);
      event.preventDefault();
    };

    // Adding copy event listener
    document.addEventListener("copy", copyEvent);

    try {
      // Try executing the copy command
      const success = document.execCommand("copy");

      // Clean up the event listener
      document.removeEventListener("copy", copyEvent);

      if (success) {
        return Promise.resolve(true);
      } else {
        // If execCommand fails, fallback for older browsers
        throw new Error("ExecCommand failed");
      }
    } catch (error) {
      console.warn("ExecCommand failed, falling back", error);

      // Create a temporary textarea for copying
      const textarea = document.createElement("textarea");
      textarea.textContent = textToCopy;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge
      document.body.appendChild(textarea);
      textarea.select();

      try {
        // Attempt to copy the content using execCommand
        const copySuccess = document.execCommand("copy");
        return Promise.resolve(copySuccess);
      } catch (ex) {
        window.alert("Copy to clipboard failed, please contact support");
        console.warn("Copy to clipboard failed.", ex);
        return Promise.resolve(false);
      } finally {
        // Clean up
        document.body.removeChild(textarea);
      }
    }
  }
};
