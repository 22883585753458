import { Button } from "@/components/shadcn/button";
import { LandingAdCard } from "@/components/templates/LandingAdCard";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { showTemplateRecentButton } from "./feeds.templates.index";
import { PRO_PLAN } from "@/utils/data/plans";
import FreeTrialEnding from "@/components/FreeTrialEnding";
import { trpc } from "@/App";
import { Stack, Text } from "@/components/custom-components";
import { Loader } from "@/components/custom-components/Loader";
import { ErrorDisplay } from "@/components/error";

export const Route = createFileRoute("/share/templates/landing-pages/$adID")({
  component: SharedLandingPageTemplate,
});

function SharedLandingPageTemplate() {
  const navigate = useNavigate({
    from: "/share/templates/landing-pages/$adID",
  });
  const { adID } = Route.useParams();
  const { cacheBuster } = Route.useSearch();

  if (!cacheBuster) {
    navigate({
      search: (old) => {
        return {
          ...old,
          cacheBuster: Math.random(),
        };
      },
      params: (old) => {
        return {
          ...old,
        };
      },
    });
  }

  const { data: authUser } = trpc.me.useQuery(null);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const {
    data: landerTemplate,
    isLoading: isLoadingLanderTemplate,
    error: landerTemplatError,
  } = trpc.getLandingPageTemplateById.useQuery({
    landingPageTemplateId: parseInt(adID),
  });

  const { data: permissionData } = trpc.permissions.useQuery(null, {});

  return (
    <div className={"relative h-[97vh]"}>
      {authUser && upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="!mb-6 gap-8">
        <Stack className="gap-3">
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-center"
            }
          >
            <Text weight="semibold" size={"xxl"}>
              Lander Templates
            </Text>
            {!authUser ? (
              <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
                <Button
                  size={"sm"}
                  variant={"destructivePurple"}
                  className="flex"
                >
                  Get Started
                </Button>
              </a>
            ) : authUser &&
              !(
                permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
              ) ? (
              <Button
                onClick={() => setUpgradeDialogOpen(true)}
                variant={"destructivePurple"}
                size={"sm"}
              >
                Get Pro
              </Button>
            ) : (
              <></>
            )}
          </div>
          <Text
            className="text-thememutedforeground w-fit"
            size={"base"}
            weight={"normal"}
          >
            Somebody shared a landing page template with you!
            {!authUser ||
            !(
              permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
              permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
            )
              ? ` Sign up for Creative OS Pro Plan to access this and our full library of Ad, Email, and Landing Page Templates, and so much more `
              : ""}
            {!authUser ||
              (!(
                permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
              ) && (
                <a
                  className="text-black underline"
                  target={"_blank"}
                  href="https://www.creativeos.io/"
                >
                  Learn more about Creative OS.
                </a>
              ))}
          </Text>
        </Stack>

        {isLoadingLanderTemplate ? (
          <div
            className={
              "flex justify-center items-center w-full h-screen m-auto"
            }
          >
            <Loader />
          </div>
        ) : landerTemplatError ? (
          <ErrorDisplay />
        ) : (
          <div className={"grid grid-cols-1 lg:grid-cols-3 gap-6"}>
            {landerTemplate && (
              <LandingAdCard
                adData={{
                  Screenshot: landerTemplate["Landing Page Screenshot"],
                  ID: landerTemplate["LP ID"],
                  created: landerTemplate.Created,
                  category: landerTemplate.Category,
                  isSaved: landerTemplate.isSaved,
                }}
                showRecentButton={showTemplateRecentButton(
                  landerTemplate.Created
                )}
                IsLocked={
                  !(
                    permissionData &&
                    authUser &&
                    (permissionData.usersPlans.includes(PRO_PLAN.plan_id) ||
                      permissionData.usersPlans.includes(
                        PRO_PLAN.no_trial_plan_id
                      ))
                  )
                }
                type={"landing-page"}
              />
            )}
          </div>
        )}
      </Stack>
    </div>
  );
}
