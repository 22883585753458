import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Tags } from "../../../shared/airtableGet.ts";
import { z } from "zod";
import { Stack, Text } from "@/components/custom-components";
import { LandingFeedGridViewPublic } from "@/components/templates/LandingAdGridView";
import { Button } from "@/components/shadcn/button.tsx";

type SearchParams = {
  Tags?: z.infer<typeof Tags>;
};

export const Route = createFileRoute("/share/inspiration/landing-pages/$adID")({
  component: SharedAd,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as z.infer<typeof Tags> | undefined;
    return {
      Tags: tag,
    };
  },
});

function SharedAd() {
  const navigate = useNavigate({
    from: "/share/inspiration/landing-pages/$adID",
  });
  const { adID } = Route.useParams();
  const { cacheBuster } = Route.useSearch();
  if (!cacheBuster) {
    navigate({
      search: (old) => {
        return {
          ...old,
          cacheBuster: Math.random(),
        };
      },
      params: (old) => {
        return {
          ...old,
        };
      },
    });
  }
  return (
    <div className={"relative h-[97vh]"}>
      <Stack className="!mb-6 gap-3">
        <div
          className={"flex justify-between lg:justify-start gap-5 items-center"}
        >
          <Text weight="semibold" size={"xxl"}>
            Lander Inspiration
          </Text>
          <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
            <Button size={"sm"} variant={"destructivePurple"} className="flex">
              Get Started
            </Button>
          </a>
        </div>
        <Text
          className="text-thememutedforeground w-fit"
          size={"base"}
          weight={"normal"}
        >
          Somebody shared an Inspiration with you! Sign up for Creative OS to
          access our full library of Ad and Landing Page Inspirations, and so
          much more.{" "}
          <a
            className="text-black underline"
            target={"_blank"}
            href="https://www.creativeos.io/"
          >
            Learn more about Creative OS.
          </a>
        </Text>
      </Stack>
      <LandingFeedGridViewPublic adId={parseInt(adID)} />
    </div>
  );
}
