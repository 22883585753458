import { createFileRoute } from "@tanstack/react-router";
import { Stack, Text } from "@/components/custom-components";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { LandingFeedAdCard } from "@/components/templates/LandingAdCard";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/inspiration/landing-pages/$adID")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const { adID } = Route.useParams();

  const {
    data: lander,
    isError,
    isLoading,
  } = trpc.getLander.useQuery(
    {
      landerId: parseInt(adID),
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!lander || isLoading) {
    return (
      <div
        className={"flex justify-center items-center w-full h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div className={"relative h-[97vh]"}>
      <Stack className="!mb-6 gap-3">
        <div
          className={"flex justify-between lg:justify-start gap-5 items-center"}
        >
          <Text weight="semibold" size={"xxl"}>
            Lander Inspiration
          </Text>
        </div>
        <Text
          className="text-thememutedforeground w-fit"
          size={"base"}
          weight={"normal"}
        >
          Somebody shared an Lander Inspiration with you!
        </Text>
      </Stack>
      <div
        className={
          "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[1.5rem]"
        }
      >
        {lander && <LandingFeedAdCard adData={lander} />}
      </div>
    </div>
  );
}
