import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { AdCard } from "@/components/templates/AdCard";
import { z } from "zod";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { useNavigate } from "@tanstack/react-router";
import { Loader } from "@/components/custom-components/Loader";
import {
  AirTableAdRecord,
  AirtableGetArgs,
  AirtablePublicGetArgs,
} from "../../../../../shared/airtableGet.ts";
import { Button } from "@/components/shadcn/button.tsx";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import { showTemplateRecentButton } from "@/routes/feeds.templates.index.tsx";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions.tsx";
import { Stack, Text } from "@/components/custom-components/index.tsx";

type props = {
  Filter: z.infer<typeof AirtableGetArgs>;
};

export const AdGridView = (props: props) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined
  );

  const { squareRef, desiredCardWidth, columns, gutterWidth } =
    useCardLayoutWithImpressions();

  // There's some shenanigans going on with the query here.
  // We need to invalidate this query when the posts get liked, but if we do that when the filter is random,
  // The page will refresh every time a post is liked.
  //
  // So the solution is to have a different rpc for liked posts vs regular filters
  const queryToCall = props.Filter.OnlyLiked ? trpc.likedPosts : trpc.posts;
  const { data, fetchNextPage, isError, isLoading, isRefetching } =
    queryToCall.useInfiniteQuery(props.Filter, {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false, // Having these false means random queries don't reset on window focus
      refetchOnWindowFocus: false,
    });

  const { data: permissionData } = trpc.permissions.useQuery(null, {
    refetchOnWindowFocus: false,
  });

  // Ref for trigger to fetch next page
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(
    function fetchNextPageWhenElemInView() {
      if (!inView || !data) {
        return;
      }
      if (!allData) {
        return;
      }
      if (!allData.length) {
        return;
      }
      if (allData.length == 1 && props.Filter.adID) {
        return;
      }
      if (isRefetching || isLoading) {
        return;
      }
      // We've fetched all the data if these are equal
      if (data.pages[0].TotalRecords <= allData?.length) {
        return;
      }
      fetchNextPage();
    },
    [inView, data, fetchNextPage]
  );

  // Syncs react state with trpc state
  useEffect(
    function addFetchedAtaToReactState() {
      if (!data) return;
      setAllData(() => undefined);
      const records = [] as AirTableAdRecord[];
      for (const page of data.pages) {
        records.push(...page.ATRecords);
      }
      setAllData(() => records.filter(
        (item, index) =>
          index === records.findIndex((obj) => obj.atID === item.atID)
      ));
    },
    [data]
  );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!allData || isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}

      <div ref={squareRef} className={"relative w-full lg:px-0 pb-10"}>
        {allData && (
          <div className="flex flex-col gap-3">
            <ResponsiveMasonry
              columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
            >
              <Masonry gutter={gutterWidth / 16 + "rem"}>
                {allData.map((ad) => {
                  return (
                    <AdCard
                      key={ad.atID}
                      adData={{
                        Ad: ad,
                        IsLocked: false,
                        IsPublic: data?.pages[0].hasAccess === false,
                        brandName: undefined,
                        shouldInvalidateCache: !props.Filter.OnlyLiked,
                        DesiredWidth: desiredCardWidth,
                      }}
                      showRecentButton={showTemplateRecentButton(ad.Created)}
                    />
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        )}
        <div className={"relative"}>
          <div
            className={
              "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          ></div>
        </div>
        <div className={"relative"}>
          <div
            className={
              "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          ></div>
        </div>
      </div>
    </div>
  );
};

type publicProps = z.infer<typeof AirtablePublicGetArgs>;

export const AdsGridViewPublic = (props: publicProps) => {
  // If a user is logged in, they don't need to see this screen
  const { data: authUser, isLoading: authIsLoading } = trpc.me.useQuery(null);

  const navigate = useNavigate();

  if (authUser) {
    navigate({
      to: "/feeds/templates/$adID",
      params: {
        adID: props.adID,
      },
    });
  }
  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined
  );

  const { squareRef, desiredCardWidth, columns, gutterWidth } =
    useCardLayoutWithImpressions();

  // There's some shenanigans going on with the query here.
  // We need to invalidate this query when the posts get liked, but if we do that when the filter is random,
  // The page will refresh every time a post is liked.
  //
  // So the solution is to have a different rpc for liked posts vs regular filters
  const { data, isError, isLoading, isRefetching } =
    trpc.publicPostByID.useQuery({
      adID: props.adID,
      cacheBuster: props.cacheBuster,
    });

  // Syncs react state with trpc state
  useEffect(
    function addFetchedAtaToReactState() {
      if (!data) return;
      setAllData(() => undefined);
      const records = [] as AirTableAdRecord[];
      for (const page of data) {
        records.push(page);
      }
      setAllData(() => records);
    },
    [data]
  );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!allData || isLoading || isRefetching || authIsLoading) {
    return (
      <div
        className={"flex justify-center items-center w-full h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div
      ref={squareRef}
      className={"relative w-full px-5 md:px-5 md:pl-0 pb-5"}
    >
      {allData && (
        <div>
          <ResponsiveMasonry
            columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
          >
            <Masonry gutter={gutterWidth / 16 + "rem"}>
              {[
                allData[0] && (
                  <AdCard
                    adData={{
                      IsPublic: true,
                      IsLocked: false,
                      DesiredWidth: desiredCardWidth,
                      shouldInvalidateCache: false,
                      brandName: undefined,
                      avatar: undefined,
                      Ad: allData[0],
                    }}
                    key={allData[0].atID}
                  />
                ),
                <StartFreeTrialAdCard key="startFreeTrial" />,
                ...allData.slice(1, 3).map((ad) => (
                  <AdCard
                    adData={{
                      IsPublic: true,
                      IsLocked: true,
                      DesiredWidth: desiredCardWidth,
                      shouldInvalidateCache: false,
                      brandName: undefined,
                      avatar: undefined,
                      Ad: ad,
                    }}
                    key={ad.atID}
                  />
                )),
              ]}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      )}
    </div>
  );
};

export const StartFreeTrialAdCard = () => {
  return (
    <div className="h-[28.125rem] bg-brandgrad bg-no-repeat bg-center bg-cover rounded px-4 py-6 flex flex-col justify-between">
      <img
        alt={"Creative OS"}
        src={"/images/cos-logo-freetrial.png"}
        className={"h-8 w-36"}
      />
      <Stack>
        <Text weight={"bold"} size={"4xl"} className="text-themebackground">
          Start Your 3 Day Free Trial & Launch In Minutes
        </Text>
        <Text weight={"medium"} size={"xxl"} className="text-themebackground">
          Fuel Your Brand with Ready-to-Launch Creative Templates
        </Text>
      </Stack>

      <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
        <Button variant={"destructivePurple"}>Continue</Button>
      </a>
    </div>
  );
};
