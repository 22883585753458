import { useState } from "react";
import zxcvbn from "zxcvbn";

export const PasswordStrengthColors = [
  "#FF4C4C", // Very Weak (red)
  "#FF8C4C", // Weak (orange)
  "#FFD24C", // Fair (yellow)
  "#4CFF8C", // Good (light green)
  "#4CFF4C", // Strong (green)
];

// Password strength labels based on score
export const getPasswordStrengthLabel = (score: number) => {
  switch (score) {
    case 0:
      return "Very Weak";
    case 1:
      return "Weak";
    case 2:
      return "Fair";
    case 3:
      return "Good";
    case 4:
      return "Strong";
    default:
      return "";
  }
};

// Custom hook to handle password strength
const usePasswordStrengthCheck = () => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordColor, setPasswordColor] = useState(PasswordStrengthColors[0]);
  const [passwordStrengthLabel, setPasswordStrengthLabel] =
    useState("Very Weak");

  // Update password strength when password changes
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strength = zxcvbn(e.target.value).score;
    setPasswordStrength(strength);
    setPasswordColor(PasswordStrengthColors[strength]);
    setPasswordStrengthLabel(getPasswordStrengthLabel(strength));
  };

  return {
    passwordStrength,
    passwordColor,
    passwordStrengthLabel,
    handlePasswordChange,
  };
};

export default usePasswordStrengthCheck;
