export const CustomDiamondIcon = ({height, width, color}: {height?: string, width?: string, color?: string}) => {
  return (
    <>
      <svg
        width={width ??"24"}
        height={height ?? "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.4793 3H6.52066C6.19434 3 5.88855 3.15921 5.70142 3.42654L2.91018 7.41403C2.66487 7.76447 2.66963 8.23213 2.92202 8.5775L11.1926 19.8951C11.5921 20.4418 12.4079 20.4418 12.8074 19.8951L21.078 8.5775C21.3304 8.23213 21.3351 7.76447 21.0898 7.41403L18.2986 3.42654C18.1114 3.15921 17.8057 3 17.4793 3Z"
          stroke={color ??"black"}
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M2.5 8H21"
          stroke={color ??"black"}
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M8 8L12 20"
          stroke={color ??"black"}
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M16 8L12 20"
          stroke={color ??"black"}
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};
